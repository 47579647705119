import React, { useState, useEffect } from 'react';
import './ChatBot.css';



import ChatBox from './ChatBox';
import Sources from './Sources';


function Chatbot({
    assistant,
    accroche,
    resume,
    ComposantInputBar = <></>,
    prePrompt = { avant: "", apres: "" },
    showModules = false,
    ModulesComponent = null,
    modulesComponentProps,
    setShowModules = () => {},
    showSources,
    setShowSources,
    suggestions = [],
    firstMessage = "",
    isProf=false,
    setJobsInProgress = () => {}
}) {
    const [currentSources, setCurrentSources] = useState({ thread_id: null, run_id: null }) // il faudra aussi en faire quelque chose
    const [currentNote, setCurrentNote] = useState(0);//numéro de note parmi les source
    const [questionsQCM, setQuestionsQCM] = useState([]);

    useEffect(() => {
        console.log("setJobsInProgress dans Chatbot",setJobsInProgress);

    }, [])

    return (
        <>
            <div className={`cadreChat ${(!assistant ? 'noAssistantId' : '')}`}>
                <div className={`containerChatBot ${!isProf ? 'containerChatBotELEVEONLY' : ''} ${showSources || showModules ? 'showVoletDroite' : ''}`}>
                    <ChatBox
                        assistant={assistant}
                        setShowSources={setShowSources}
                        currentSources={currentSources}
                        setCurrentSources={setCurrentSources}
                        setCurrentNote={setCurrentNote}
                        accroche={accroche}
                        resume={resume}
                        ComposantInputBar={ComposantInputBar}
                        prePrompt={prePrompt}
                        setShowModules={setShowModules}
                        suggestions={suggestions}
                        questionsQCM={questionsQCM}
                        firstMessage={firstMessage}
                        isProf={isProf}
                    />
                </div>


                <div className={`voletDroite ${showSources || showModules ? 'voletActif' : 'voletInactif'}`} >
                    {!showModules ?
                        <Sources
                            source={currentSources}
                            showSources={showSources}
                            setShowSources={setShowSources}
                            currentNote={currentNote}
                            setCurrentNote={setCurrentNote}
                        />
                        :
                        <>
                            
                            {ModulesComponent && (
                            <ModulesComponent 
                            {...modulesComponentProps}
                            setQuestionsQCM={setQuestionsQCM}
                            setJobsInProgress={setJobsInProgress}
                            />
                        )}

                        </>
                    }
                </div>
            </div>
            </>
            );
}

            export default Chatbot;


