import { useState, useEffect, useRef } from 'react';

import { formatFileSize } from "../../consts";
import { ReactComponent as PicsSmallSpeaker } from "../../pics/file_speaker.svg";
import { ReactComponent as PicsStopButton } from "../../pics/stop_button.svg";
import { ReactComponent as PicsPlayButton } from "../../pics/play_button.svg";
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";
import { ReactComponent as PicsDownload } from "../../pics/import_URL.svg";

import { serverPHP } from '../../consts/index.js';


import './Modules.css';

const AudioPlayer = ({ src, onClose }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(audio.currentTime);
        };

        const handleLoadedMetadata = () => {
            setDuration(audio.duration);
        };

        const handleEnded = () => {
            setIsPlaying(false);
            //onClose();
        };

        if (audio) {
            audio.addEventListener('timeupdate', handleTimeUpdate);
            audio.addEventListener('loadedmetadata', handleLoadedMetadata);
            audio.addEventListener('ended', handleEnded);
        }

        return () => {
            if (audio) {
                audio.removeEventListener('timeupdate', handleTimeUpdate);
                audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
                audio.removeEventListener('ended', handleEnded);
            }
        };
    }, [audioRef, onClose]);

    useEffect(() => {
        // When src changes, reset the audio and start playing
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setCurrentTime(0);
        }
        setIsPlaying(true);
    }, [src]);

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    const handlePlayPause = (e) => {
        e.stopPropagation();
        setIsPlaying(!isPlaying);
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setCurrentTime(0);
        }
        setIsPlaying(false);
    };

    const handleChange = (e) => {
        const newTime = parseFloat(e.target.value);
        if (audioRef.current) {
            audioRef.current.currentTime = newTime;
        }
        setCurrentTime(newTime);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = src; // 'src' est l'URL du fichier audio passé en prop
        const urlParts = src.split('/');
        const filename = urlParts[urlParts.length - 1]; // Extraction du nom de fichier à partir de l'URL
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Fonction pour formater le temps en mm:ss
    const formatTime = (time) => {
        if (isNaN(time)) return '00:00';
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="audio-controller" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
            <audio
                ref={audioRef}
                src={src}
            />
            <div className="divBoutons">

                <button onClick={handlePlayPause} className="buttonPlayPause">

                    {isPlaying ?
                        <PicsStopButton className="controller" /> :
                        <PicsPlayButton className="controller" />}
                </button>
            </div>
            <div className="progress-container">
                <input
                    type="range"
                    min="0"
                    max={duration}
                    value={currentTime}
                    onChange={handleChange}
                    step="0.01"
                    className="progress-bar"
                />
                <div className="time-display">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>
            </div>
            <button className="login-button hideMobile" onClick={handleDownload}>Télécharger</button>
            <div className="login-button dlAudioButton showMobile" onClick={handleDownload} style={{ display: 'none' }}><PicsDownload height={35} width={35} /></div>

        </div>
    );
};


const ModuleAudio_ListeAudios = ({ cours, currentAudioFile, setCurrentAudioFile, handleDelete = () => { }, isProf = true, opened = true }) => {



    return <>
        <div className="containerFichiersPartage">
            {cours.modules.audio.map((file) => (
                <div
                    key={file.filename} // Use filename as key if it's unique
                    className="partageItem partageHover"
                    style={{ justifyContent: "space-between", cursor: 'pointer', flexDirection: 'column', margin: 0, padding: 0 }}
                    onClick={(e) => {
                        if (currentAudioFile && currentAudioFile.filename === file.filename) {
                            // If the same file is clicked, stop the audio
                            setCurrentAudioFile(null);
                        } else {
                            // Set the current audio file
                            setCurrentAudioFile(file);
                        }
                    }}
                >
                    <div className="divAudio">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="smallButton" >
                                <PicsSmallSpeaker />
                            </div>
                            <span>{file.filename}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span className="partageTaille" style={{ marginRight: '10px' }}>
                                ({`${formatFileSize(file.filesize).size} ${formatFileSize(file.filesize).unit}`.trim()})
                            </span>
                            {isProf &&
                                <div
                                    className="deleteButtonSVG"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(file);
                                    }}
                                >
                                    <PicsDelete />
                                </div>
                            }

                        </div>
                    </div>
                    {currentAudioFile && currentAudioFile.filename === file.filename && opened &&
                        <AudioPlayer
                            src={`${serverPHP}/users/${cours.id}/modules/audio/${currentAudioFile.filename}`}
                            onClose={() => setCurrentAudioFile(null)}
                        />
                    }
                </div>
            ))}

            {cours.modules.audio.length === 0 &&
                <div style={{ width: '100%' }}>
                    <div className="containerFichiersPartage"></div>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                        Vous n'avez encore généré aucun audio.</p>
                </div>
            }
        </div>
    </>
}

export default ModuleAudio_ListeAudios;