
import React, { useState, useEffect } from 'react';


const ModifyPassword = ({ oldPassword, setOldPassword, newPassword, setNewPassword, confirmNewPassword, setConfirmNewPassword }) => {


    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);

    useEffect(() => {
        if (oldPassword && newPassword && confirmNewPassword) {
            if (newPassword > 40) {
                setNewPasswordError(true);
            } else {
                setNewPasswordError(false);
            }
            if (confirmNewPassword !== newPassword) {
                setConfirmNewPasswordError(true);
            } else {
                setConfirmNewPasswordError(false);
            }
            if (newPassword === oldPassword) {
                setNewPasswordError(true);
            } else {
                setNewPasswordError(false);
            }
        } else {
            setNewPasswordError(false);
            setConfirmNewPasswordError(false);
        }
    }, [oldPassword, newPassword, confirmNewPassword]);


    return (
        <>
            <div className="input-group motdepasse">
                <label htmlFor="old-password" className="login-label w300">Ancien mot de passe :</label>
                <input
                    type="password"
                    id="old-password"
                    className="login-input"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </div>
            <div className="input-group motdepasse">
                <label htmlFor="new-password" className="login-label w300">Nouveau mot de passe :</label>
                <input
                    type="password"
                    id="new-password"
                    className={`login-input ${newPasswordError ? 'input-error' : ''}`}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </div>
            <div className="input-group motdepasse">
                <label htmlFor="confirm-new-password" className="login-label w300">Confirmer :</label>
                <input
                    type="password"
                    id="confirm-new-password"
                    className={`login-input ${confirmNewPasswordError ? 'input-error' : ''}`}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
            </div>
        </>
    )
}

export default ModifyPassword;