import React, { useState, useEffect, useRef } from "react";
import "./MessageInput.css";
import { ReactComponent as SendButton } from "./images/sendButton.svg";

import { ReactComponent as StopButton } from "./images/stopButton.svg";
import Suggestions from "./Suggestions";


function MessageInput({
  onSendMessage,
  thinking,
  generating,
  stopAll,
  inputUser,
  setInputUser,
  ComposantInputBar,
  suggestions,
  questionsQCM,
  isProf
}) {
  const [envoyerActif, setEnvoyerActif] = useState(false);
  const textareaRef = useRef(null);

  // Gestion de l'envoi de message ou de l'arrêt du traitement en cours
  const handleSend = () => {
    if (thinking || generating) {
      if (stopAll) {
        stopAll(); // Arrêter le traitement du message en cours
      }
    } else {
      if (inputUser.trim()) {
        onSendMessage(inputUser);
        setInputUser("");
      }
    }
  };

  // Ajustement de la hauteur du textarea en fonction de son contenu
  const handleChange = (e) => {
    setInputUser(e.target.value);
    adjustTextareaHeight();
  };

  // Gestion de l'envoi par la touche "Enter"
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  // Ajustement automatique de la hauteur du textarea
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 10 * 20)}px`; // Limite à 10 lignes
  };

  // Mise à jour de l'état d'activation du bouton
  useEffect(() => {
    setEnvoyerActif(!(inputUser === "") || thinking || generating);
    adjustTextareaHeight(); // Ajuste la hauteur initiale
  }, [inputUser, thinking, generating]);

  const showSuggestions = (suggestions.length > 0 || questionsQCM.length > 0) && (inputUser.trim() === '');

  return (
    <div className={`inputzone ${!isProf ? 'inputzoneELEVEONLY' : ''}`}>
      {ComposantInputBar}
      <textarea
        className={`texteInput ${!isProf ? 'texteInputELEVEONLY' : ''}`}
        ref={textareaRef}
        placeholder="Posez votre question..."
        value={inputUser}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        rows={1} // Nombre de lignes initial
        style={{ overflow: "auto" }} // Limite de hauteur à 10 lignes (20px par ligne)
      />



      {!(thinking || generating) ? (
        <>
          {showSuggestions ?
            <Suggestions setInputUser={setInputUser} onSendMessage={onSendMessage} suggestions={suggestions} questionsQCM={questionsQCM} />
            :
            <div
              className={`fleche ${envoyerActif || showSuggestions ? "active" : "inactive"}`}
              onClick={handleSend}
            >
              <SendButton height={50} width={50} />
            </div>
          }
        </>
      ) : (
        <div
        className={`fleche ${envoyerActif || showSuggestions ? "active" : "inactive"}`}
        onClick={handleSend}
      >
        <StopButton height={50} width={50} className="icon-lg" />
        </div>
      )}
    </div>
  );
}

export default MessageInput;