import { useEffect } from 'react';
import Module_selectFile_1 from "./Module_selectFile_1";

const Module_selectFile = ({
  cours,
  reset,
  navigationSelectFile,
  setNavigationSelectFile,
  setReset,
  setTitreMenu,
  setContenuFichier,
  titreSelection = <><strong>Générer</strong> des questions</>,
  isFullFileNeeded = false,
  setFullFileData = () => {},
  showNotes = true,
  noPadding = false,
  setCours = ()=> {},
  setError = () => {},
  setIsWorking = () => {}
}) => {
  const sources = cours.files.filter((d) => d.statut === 2);

  return (
    <>
      {navigationSelectFile === null ? (
        <div
          className={`moduleContainer ${noPadding ? "moduleContainerNoPadding" : ""}`}
          style={{ justifyContent: "center", padding: noPadding ? 0 : "15px 15px 30px 15px" }}
        >
          <div className="moduleTitre">{titreSelection}</div>
          {sources.length > 0 ? (
            <button className="login-button ml20" onClick={() => setNavigationSelectFile(true)}>
              D'après une source existante
            </button>
          ) : (
            !noPadding && (
              <button className="disabled-button ml20" disabled>
                Aucune source ajoutée
              </button>
            )
          )}
          <button className="login-button ml20" onClick={() => setNavigationSelectFile(false)}>
            {noPadding ? "Importer votre cours" : "Importer un nouveau fichier"}
          </button>
        </div>
      ) : (
        <Module_selectFile_1
          cours={cours}
          sources={sources}
          setTitreMenu={setTitreMenu}
          setContenuFichier={setContenuFichier}
          reset={reset}
          fichierFromSources={navigationSelectFile}
          setFichierFromSources={setNavigationSelectFile}
          setReset={setReset}
          isFullFileNeeded={isFullFileNeeded}
          setFullFileData={setFullFileData}
          showNotes={showNotes}
          noPadding={noPadding}
          setCours={setCours}
          setError={setError}
          setIsWorking={setIsWorking}
        />
      )}
    </>
  );
};

export default Module_selectFile;
