import { useState, useEffect } from 'react';
import { ReactComponent as PicsTextfile } from "../../pics/textfile.svg";
import FileList from './FileList';

import AddSourceModal from '../AddSourceModal';
import { handleAddSource } from '../addSourceFunctions';
import LoadingCircle from '../LoadingCircle';
import { serverPHP } from '../../consts';

const Module_selectFile_1 = ({
  cours,
  setContenuFichier,
  setTitreMenu,
  reset,
  fichierFromSources,
  sources,
  setFichierFromSources,
  isFullFileNeeded = false,
  setFullFileData = () => {},
  showNotes = true,
  noPadding = false,
  setCours,
  setError,
  setIsWorking = () => {}
}) => {
  const [loading, setLoading] = useState(false);

  const processUploadResponse = (response, result, filename) => {
    setLoading(false);
    if (response.ok) {
      if (result.success && result.content) {
        setContenuFichier(result.content);
      } else {
        throw new Error("Le contenu du fichier apparaît vide.");
      }
    } else {
      throw new Error(result.error || "Le serveur a répondu d'une façon inappropriée.");
    }
  };

  const processUploadResponseAddSource = (response, result, filename) => {
    if (response.ok && result.db_id) {
      setCours((prevCours) => {
        const fileExists = prevCours.files.some((f) => f.filename === filename);
        const updatedFiles = fileExists
          ? prevCours.files.map((f) =>
              f.filename === filename
                ? {
                    ...f,
                    id: result.db_id,
                    openai_id: result.id,
                    statut: 2,
                    filesize: result.filesize,
                  }
                : f
            )
          : [
                ...prevCours.files,
                {
                  id: result.db_id,
                  filename: filename,
                  openai_id: result.id,
                  statut: 2,
                  filesize: result.filesize,
                },
              ];
        const updatedCours = { ...prevCours, files: updatedFiles };
        createVectorStoreFileBatch(updatedCours);
        return updatedCours;
      });
    } else {
      const errorMessage =
        result.error?.message || result.error || "Erreur lors de l'ajout du fichier";
      setError(errorMessage);
      // Supprimez le fichier de cours.files s'il a été ajouté
      setCours((prevCours) => ({
        ...prevCours,
        files: prevCours.files.filter((f) => f.filename !== filename),
      }));
      // Revenir à ModeAutomatique
      setContenuFichier(null);
    }
  };

  const createVectorStoreFileBatch = (newCours) => {
    if (newCours && newCours.files.length > 0) {
      const allFilesReady = newCours.files.every((file) => file.statut === 2 || file.statut === 0);
      if (allFilesReady) {
        const fileIds = newCours.files.filter((f) => f.statut === 2).map((file) => file.openai_id);
        fetch(serverPHP + "/createVectorStoreFileBatch.php", {
          method: "POST",
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ file_ids: fileIds, vector_store: newCours.vector_store }),
        }).catch((error) => console.error("Erreur lors de l'envoi du batch de fichiers :", error));
      }
    }
  };

  const handleSendSource = async (data) => {
    try {
      if (isFullFileNeeded) {
        setFullFileData({
          coursId: cours.id,
          filename: data.filename,
          fileIsSource: true,
        });
      } else {
        setLoading(true);
        const formData = new FormData();
        formData.append('coursId', cours.id);
        formData.append('filename', data.filename);
        formData.append('fileIsSource', true);

        // Ajouter le fichier avec statut 1 si nécessaire
        setCours((prevCours) => {
          const fileExists = prevCours.files.some((f) => f.filename === data.filename);
          if (!fileExists && noPadding) {
            return {
              ...prevCours,
              files: [
                ...prevCours.files,
                {
                  filename: data.filename,
                  statut: 1,
                  messageErreur: null,
                },
              ],
            };
          }
          return prevCours;
        });

        const response = await fetch(serverPHP + '/modules/handleTxtConversion.php', {
          credentials: 'include',
          method: 'POST',
          body: formData,
        });
        const result = await response.json();
        processUploadResponse(response, result, data.filename);

        // Ajouter ou mettre à jour la source dans les sources du cours si noPadding est vrai
        if (noPadding) {
          handleAddSource(
            data,
            cours,
            setCours,
            setError,
            processUploadResponseAddSource,
            '/handleFileUpload.php'
          );
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la source :", error);
      setLoading(false);
      setError(error.message || "Une erreur est survenue lors du traitement du fichier.");
      // Supprimer le fichier de cours.files s'il a été ajouté
      setCours((prevCours) => ({
        ...prevCours,
        files: prevCours.files.filter((f) => f.filename !== data.filename),
      }));
      // Revenir à ModeAutomatique
      setContenuFichier(null);
    }
  };

  useEffect(() => {
    setTitreMenu("1. Sélection du fichier");
  }, []);

  return (
    <>
      {loading && !isFullFileNeeded ? (
        <div
          className={`moduleContainer ${noPadding ? "moduleContainerNoPadding" : ""}`}
          style={{ position: 'relative' }}
        >
          <div style={{ height: noPadding ? '400px' : '495px' }}>
            <LoadingCircle taille={150} />
          </div>
          <div className="generationQCMEnCours">
            Extraction du texte en cours...
            <br />
            <span style={{ fontSize: '14px' }}>
              Cette opération peut prendre une vingtaine de secondes
            </span>
          </div>
        </div>
      ) : fichierFromSources === true ? (
        <div
          className={`moduleContainer ${noPadding ? "moduleContainerNoPadding" : ""}`}
          style={{ padding: noPadding ? 0 : "15px 15px 30px 15px" }}
        >
          <div className="moduleTitre">
            Choisissez le <strong>fichier source</strong>
          </div>
          <FileList
            listeFichiers={sources}
            isSource={true}
            selectFile={true}
            image={<PicsTextfile />}
            onClickButton={(data) => handleSendSource(data)}
            hoverAll={true}
            filterTxt={true}
          />
        </div>
      ) : (
        <div
          className={`moduleContainer ${noPadding ? "moduleContainerNoPadding" : ""}`}
          style={{ padding: noPadding ? 0 : "15px 15px 30px 15px" }}
        >
          <AddSourceModal
            isOpen={true}
            onClose={() => {}}
            onAddSource={(data) => {
              setLoading(true);

              if (noPadding) setIsWorking(true);

              // Ajouter le fichier avec statut 1 si nécessaire
              handleAddSource(
                data,
                cours,
                () => {},
                () => {},
                processUploadResponse,
                '/modules/handleTxtConversion.php',
                false,
                isFullFileNeeded,
                setFullFileData
              );

              // Ajouter ou mettre à jour la source dans les sources du cours si noPadding est vrai
              if (noPadding) {

                handleAddSource(
                  data,
                  cours,
                  setCours,
                  setError,
                  processUploadResponseAddSource,
                  '/handleFileUpload.php'
                );
              }
            }}
            cours={cours}
            wide={true}
            showNotes={showNotes}
            noPadding={noPadding}
          />
        </div>
      )}
    </>
  );
};

export default Module_selectFile_1;
