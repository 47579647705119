// CreateGroupModal.js
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import LoadingCircle from './LoadingCircle';
import ColorGrid from './ColorGrid';
import './CreateGroupModal.css';

const CreateGroupModal = ({
    onClose,
    handleSubmitGroupe,
    creatingGroupe,
    groupeName,
    setGroupeName,
    groupeNameError,
    setGroupeNameError,
    setColor,
    matiere,
    setMatiere,
    userInfo
}) => {
    const handleClickOutsideModal = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleGroupeNameChange = (e) => {
        const value = e.target.value;
        setGroupeName(value);
        if (value.trim() === '' || value.length > 40) {
            setGroupeNameError(true);
        } else {
            setGroupeNameError(false);
        }
    };

    // Utiliser useEffect pour définir l'état 'matiere' au montage du composant
    useEffect(() => {
        if (userInfo && userInfo.matieres) {
            if (userInfo.matieres.length === 1) {
                setMatiere(userInfo.matieres[0].id);
            } else if (userInfo.matieres.length === 0) {
                setMatiere(-1);
            } else if (userInfo.matieres.length > 1) {
                setMatiere(userInfo.matieres[0].id);
            }
        }
    }, [userInfo]);

    // Gestionnaire de soumission du formulaire
    const onSubmit = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        if (!groupeNameError && groupeName.trim() !== '') {
            handleSubmitGroupe();
        }
    };

    return ReactDOM.createPortal(
        <div className="login-container modalContainer" onClick={handleClickOutsideModal}>
            <div className="login-box" onClick={(e) => e.stopPropagation()} style={{ height: 'auto', width: '460px' }}>
                <h2 style={{ textAlign: 'center', fontSize: '24px', marginTop: '0' }}>Créer un groupe</h2>
                <p className="small" style={{ fontSize: '16px', textAlign: 'justify' }}>
                </p>
                {/* Encapsuler le formulaire ici */}
                <form className="login-form small" style={{ margin: '20px 0' }} onSubmit={onSubmit}>
                    <div className="input-group" style={{ marginBottom: '10px' }}>
                        <label htmlFor="groupe-name" className="login-label w140">Nom du groupe :</label>
                        <input
                            type="text"
                            id="groupe-name"
                            className={`login-input ${groupeNameError ? 'input-error' : ''}`}
                            value={groupeName}
                            onChange={handleGroupeNameChange}
                            maxLength={40}
                            required
                        />
                    </div>
                    {userInfo && userInfo.matieres && userInfo.matieres.length > 1 && (
                        <div className="input-group" style={{ marginBottom: '10px' }}>
                            <label htmlFor="matiere-select" className="login-label w140">Matière du groupe :</label>
                            <select
                                id="matiere-select"
                                className="login-input"
                                value={matiere}
                                onChange={(e) => setMatiere(Number(e.target.value))}
                            >
                                {userInfo.matieres.map((matiereItem) => (
                                    <option key={matiereItem.id} value={matiereItem.id}>
                                        {matiereItem.nom_long}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="input-group" style={{ marginBottom: '10px' }}>
                        <label className="login-label w140">Couleur associée :</label>
                        <ColorGrid setColor={setColor} />
                    </div>
                    <div style={{ display: 'flex', gap: '40px', justifyContent: 'center', marginTop: '30px' }}>
                        <button
                            type="submit" // Changer le type en 'submit'
                            className="login-button"
                            disabled={!groupeName || groupeName.length > 40 || groupeName.trim() === ''}
                            style={{
                                width: '150px',
                                backgroundColor: (!groupeName || groupeName.length > 40 || groupeName.trim() === '') ? '#a0c4ff' : 'var(--menu-icon-color)',
                                cursor: (!groupeName || groupeName.length > 40 || groupeName.trim() === '') ? 'not-allowed' : 'pointer',
                            }}
                        >
                            {creatingGroupe ? <LoadingCircle taille={20} /> : 'Créer le groupe'}
                        </button>
                        <button
                            type="button" // Assurez-vous que ce bouton ne soumet pas le formulaire
                            className="signup-button"
                            onClick={onClose}
                            style={{ width: '150px' }}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </div>
        </div>,
        document.body
    );
};

export default CreateGroupModal;
