import { useRef, useEffect, useState, useCallback } from 'react';
import './Header.css';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { fr } from "react-day-picker/locale";
import SettingsModal from './SettingsModal';
import ProfileButton from './ProfileButton';
import { serverPHP } from "../consts";

import { ReactComponent as PicsSettings } from "../pics/settings.svg";
import { ReactComponent as PicsLogo } from "../pics/picLogo.svg";
import PicModal from './PicModal';
import LoadingCircle from './LoadingCircle';

const Header = ({ cours, setCours, userInfo, setUserInfo, updateAssistant, showAutomaticMenu, setShowAutomaticMenu }) => {
  const inputRef = useRef(null);
  const spanRef = useRef(null);
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [triggerAutomaticPicGen, setTriggerAutomaticPicGen] = useState(false)
  const [isPicModalOpen, setIsPicModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localSujet, setLocalSujet] = useState(cours?.sujet || '');
  const [redOutline, setRedOutline] = useState(false);
  
  // Ajout de l'état imageVersion pour gérer le cache-busting
  const [imageVersion, setImageVersion] = useState(Date.now());

  // Fonction pour mettre à jour imageVersion
  const handleImageUpload = useCallback(() => {
    setImageVersion(Date.now());
  }, []);

  useEffect(() => {
    if (showAutomaticMenu) setIsModalOpen(true);
  }, [showAutomaticMenu]);

  const handleChange = useCallback((e) => {
    if (e.target.value.length <= 60) {
      setLocalSujet(e.target.value);
    } else {
      setRedOutline(true);
      setTimeout(() => setRedOutline(false), 200);
    }
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      updateAssistant({ ...cours, sujet: localSujet });
      inputRef.current.blur();
    }
  }, [cours, localSujet, updateAssistant]);

  const handleBlur = useCallback(() => {
    const updatedSujet = localSujet.trim() === '' ? 'Nouveau cours' : localSujet;
    setLocalSujet(updatedSujet);
    updateAssistant({ ...cours, sujet: updatedSujet });
  }, [cours, localSujet, updateAssistant]);

  const handleFocus = useCallback(() => {
    if (localSujet === 'Nouveau cours') {
      setLocalSujet('');
    }
  }, [localSujet]);

  useEffect(() => {
    if (cours && cours.sujet) setLocalSujet(cours.sujet);
  }, [cours?.sujet]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current && !calendarRef.current.contains(event.target) &&
        buttonRef.current && !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const MyDatePicker = useCallback(({ setIsOpen }) => (
    <DayPicker
      mode="single"
      selected={cours?.date}
      onSelect={(date) => {
        updateAssistant({ ...cours, date });
        setIsOpen(false);
      }}
      locale={fr}
      defaultMonth={cours?.date}
      required
    />
  ), [cours, updateAssistant]);

  const formatDate = useCallback((date) => {
    if (!date) return '';
    const validDate = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(validDate)) return '';
    return validDate.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' });
  }, []);

  useEffect(() => {
    if (inputRef.current && spanRef.current) {
      spanRef.current.textContent = inputRef.current.value || '';
      inputRef.current.style.width = `${spanRef.current.offsetWidth - 15}px`;
    }
  }, [localSujet]);

  return (
    <div className="header-banner">
      <div style={{ flex: 1 }}>
        {cours && (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '70px', height: '70px', marginRight: '10px' }}>
                {cours.hasIcon == 2 ? 
                  <img 
                    src={`${serverPHP}/users/${cours.id}/icon.png?${imageVersion}`} 
                    className='iconeCours'
                    onClick={() => setIsPicModalOpen(!isPicModalOpen)}
                    alt="Icone du cours"
                  />
                  : (cours.hasIcon == 1) ?
                  <div className='iconeCours' style={{position:'relative', outline:'none'}}
                  onClick={() => setIsPicModalOpen(!isPicModalOpen)}>
                      <LoadingCircle taille={50} />
                      </div>
                  : (cours.hasIcon == 0) ?
                  <PicsLogo 
                    className='iconeCours'
                    onClick={() => setIsPicModalOpen(!isPicModalOpen)}
                  />
                  : <></>
                }
              </div>
              <div style={{marginTop:'5px'}}>
                <div className="titreDate">
                  Chatbot créé le{' '}
                  <span
                    style={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => setIsOpen(prev => !prev)}
                    ref={buttonRef}
                  >
                    {cours?.date ? formatDate(cours.date) : 'Choisissez une date'}
                  </span>
                </div>
                {isOpen && (
                  <div className={`date ${isOpen ? 'date_actif' : 'date_inactif'}`} ref={calendarRef}>
                    <MyDatePicker setIsOpen={setIsOpen} />
                  </div>
                )}
                <div className="input-container" style={{ marginTop: '7px' }}>
                  <input
                    type="text"
                    className={`titreSujet ${redOutline ? 'red-outline' : ''} ${localSujet === 'Nouveau cours' ? 'red-pulse-outline' : 'titreSujetHover'}`}
                    value={localSujet}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                  />
                  <span ref={spanRef} className="titreSujet titreSujet-span" aria-hidden="true">
                    {localSujet}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <div className="boutonSVG" onClick={() => setIsModalOpen(true)}>
          <PicsSettings width={20} height={20} />
          Personnaliser
        </div>
        <ProfileButton userInfo={userInfo} setUserInfo={setUserInfo} />
      </div>

      <SettingsModal
        isOpen={isModalOpen}
        onClose={() => {
          setShowAutomaticMenu(false);
          setIsModalOpen(false);
        }}
        cours={cours}
        setCours={setCours}
        updateAssistant={updateAssistant}
        showAutomaticMenu={showAutomaticMenu}
        setShowAutomaticMenu={setShowAutomaticMenu}
        setTriggerAutomaticPicGen={setTriggerAutomaticPicGen}
      />
      <PicModal
        isPicModalOpen={isPicModalOpen}
        setIsPicModalOpen={setIsPicModalOpen}
        cours={cours ? cours : null} 
        setCours={setCours}
        onImageUpload={handleImageUpload} // Passer la fonction ici
        imageVersion={imageVersion} // Passer imageVersion ici
        triggerAutomaticPicGen={triggerAutomaticPicGen}
        setTriggerAutomaticPicGen={setTriggerAutomaticPicGen}
      />
    </div>
  );
};

export default Header;
