import { useState, useEffect } from 'react';
import './Modules.css';
import Module_selectFile from './Module_selectFile';
import ModuleAccessibilite_parametres from './ModuleAccessibilite_parametres.js';
import ModuleAccessibilite_ListeAccessibilite from './ModuleAccessibilite_ListeAccessibilite.js';
import { serverPHP } from '../../consts/index.js';


const ModuleAccessibilite = ({ cours, setCours, setTitreMenu, reset, setReset, setJobsInProgress }) => {
    const [contenuFichier, setContenuFichier] = useState(null);
    const [fichierFromSources, setFichierFromSources] = useState(null);
    const [fullFileData, setFullFileData] = useState(null);

    useEffect(() => {
        console.log("setJobsInProgress dans ModuleAccessibilite", setJobsInProgress);

        if (reset) {
            setContenuFichier(null);
            setReset(false);
            setFichierFromSources(null);
        }
    }, [reset]);

    const handleDelete = async (file) => {
        // Mettre à jour l'état pour supprimer le fichier sélectionné
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                accessibilite: prevCours.modules.accessibilite.filter(
                    (item) => item.filename !== file.filename || item.statut !== file.statut
                )
            }
        }));

        if (file.statut !== 0) { // le fichier était en erreur. ça veut dire qu'il n'y a pas eu de fichier produit
            //la précision est importante parce que on peut avoir envoyé un fichier qui a le même nom qu'un fichier qui a déjà été produit
            // si on ne rajoute pas cette précision, le bon fichier est supprimé aussi

            // Envoyer la requête de suppression au serveur
            try {
                await fetch(`${serverPHP}/db/moduleAccessibilite_delAccessibilite.php`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        coursId: cours.id,
                        filename: file.filename
                    })
                });
            } catch (error) {
                console.error('Error deleting accessible file:', error);
            }
        }
    };

    // Fonction pour supprimer tous les fichiers d'accessibilité
    const handleDeleteAll = async () => {
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                accessibilite: []
            }
        }));

        try {
            await fetch(`${serverPHP}/db/moduleAccessibilite_delAllAccessibilite.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    coursId: cours.id
                })
            });
        } catch (error) {
            console.error('Error deleting all accessible files:', error);
        }
    };

    return (
        <div className="moduleMain">
            {fullFileData === null ? (
                <>
                    <Module_selectFile
                        cours={cours}
                        reset={reset}
                        setReset={setReset}
                        setContenuFichier={setContenuFichier}
                        setTitreMenu={setTitreMenu}
                        navigationSelectFile={fichierFromSources}
                        setNavigationSelectFile={setFichierFromSources}
                        titreSelection={<><strong>Générer</strong> des fichiers accessibles</>}
                        isFullFileNeeded={true}
                        setFullFileData={setFullFileData}
                        showNotes={false}
                    />
                    {fichierFromSources === null && (
                        <div className="moduleContainer">
                            <div className="moduleTitre">Fichiers accessibles <strong>ajoutés</strong></div>
                            <div className="signup-choice" style={{ margin: '0 0 20px 0', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <ModuleAccessibilite_ListeAccessibilite
                                        cours={cours}
                                        isProf={true}
                                        handleDelete={handleDelete}
                                    />
                                </div>
                                {cours.modules.accessibilite.length > 0 &&
                                    <button
                                        className="login-button"
                                        style={{ width: '200px', margin: 'auto', marginTop: '-20px' }}
                                        onClick={handleDeleteAll}
                                    >
                                        Tout supprimer
                                    </button>
                                }
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <ModuleAccessibilite_parametres
                    setTitreMenu={setTitreMenu}
                    setCours={setCours}
                    fullFileData={fullFileData}
                    setFullFileData={setFullFileData}
                    setFichierFromSources={setFichierFromSources}
                    cours={cours}
                    setJobsInProgress={setJobsInProgress} // Passer setJobsInProgress ici
                />
            )}
        </div>
    );
};

export default ModuleAccessibilite;
