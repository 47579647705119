import ProfileButton from "./ProfileButton";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { serverPHP } from "../consts";
import "./ListeMenuEleve.css";
import { ReactComponent as PicsFleche } from "../pics/fleche.svg";
import { modules_full } from '../consts';
import { useNavigate } from 'react-router';
import { ReactComponent as PicsLogo } from "../pics/picLogo_inv.svg";


const ListeMenuEleve = ({ userInfo, setUserInfo, groupes, setRefresh, loading, groupe_id, currentGroupe }) => {
    const [isAddingGroup, setIsAddingGroup] = useState(false);
    const [groupCode, setGroupCode] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error messages
    const addGroupRef = useRef(null);
    const navigate = useNavigate();

    const renderInfoMessage = useMemo(() => {
        if (groupes) {
            if (groupes.length === 0)
                return <div className="info-message">Bienvenue dans EduBot !<br />Pour commencer, ajoutez un groupe avec le code qui vous a été donné par votre enseignant.</div>;
        }
        return null;
    }, [groupes]);

    const showAddGroup = () => {
        setIsAddingGroup(true);
    };

    const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength).trim() + "...";
        }
        return text;
    };

    const handleAddGroup = async () => {
        try {
            const response = await fetch(serverPHP + "/db/addUserToGroup.php", {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code: groupCode }),
            });

            const data = await response.json();

            if (data.error) {
                setErrorMessage(data.error);
                // Clear the error message after 2 seconds
                setTimeout(() => {
                    setErrorMessage('');
                }, 2000);
            } else {
                // Success, refresh the groups
                setRefresh(true);
                setIsAddingGroup(false);
                setGroupCode('');
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du groupe :", error);
            setErrorMessage("Une erreur inattendue est survenue.");
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                isAddingGroup &&
                addGroupRef.current &&
                !addGroupRef.current.contains(event.target)
            ) {
                setIsAddingGroup(false);
                setErrorMessage(''); // Clear any existing error message when clicking outside
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isAddingGroup]);

    const groupe = groupes?.find((g) => (g.id === groupe_id));

    return (
        <>
            <div className="bandeauHaut">
                <div className="titreHaut">
                    {(currentGroupe === null || currentGroupe === undefined) ?
                        <>
                            <span style={{ fontWeight: 'bold', fontSize: 'inherit' }}>EduBot</span>
                        </> :
                        <div className="menuHautEleve" onClick={() => navigate("/")}>
                            <PicsFleche width={30} height={30} alt="Retour" />
                            Autres groupes
                        </div>
                    }
                </div>
                <div className="rightProfileButton">
                    <ProfileButton setUserInfo={setUserInfo} userInfo={userInfo} />
                </div>
            </div>
            {renderInfoMessage}
            {/* New Assistant Card */}
            {(currentGroupe === null) || (currentGroupe === undefined) ?
                <>
                    <div className="assistants-container assistants-container-eleve" style={{ padding: '40px 80px' }}>

                        {/* Existing Group Cards */}
                        {groupes.map((groupe, index) => (
                            <div
                                key={index}
                                className="assistant-card assistant-card-eleve existing-card existing-cardHover fade-in"
                                style={{
                                    justifyContent: "space-between",
                                    animationDuration: '0.1s',
                                    animationDelay: `${index * 0.05}s`,
                                }}
                                onClick={() => {
                                    navigate("/groupe/" + groupe.id);
                                }}
                            >
                                <div className="hideMobile spaceBetween">
                                    <div className="divMatiere">
                                        <img src={`/matiere/${groupe.matiere.id}_m.svg`} className="iconeMatiere" alt="Matière" />
                                    </div>
                                    <div className="infosGroupe">
                                        <div className="carteGroupe">{groupe.matiere.nom_court}</div>
                                        <div className="carteGroupeNomAuteur">
                                            {groupe.auteur_nom}
                                        </div>
                                    </div>
                                    <div className="footerGroupes">
                                        <div>{groupe.nom}</div>
                                        <div className="nbChatbots">
                                            {groupe.cours.length} Chatbot
                                            {groupe.cours.length > 1 && 's'}
                                        </div>
                                    </div>
                                </div>
                                <div className="showMobile">
                                    <div className="divIconeGroupe">
                                        <img src={`/matiere/${groupe.matiere.id}_m.svg`} className="iconeMatiere iconeMatiereMobile" alt="Matière Mobile" />
                                    </div>
                                    <div className="rightPanelGroupe">
                                        <div className="">
                                            <div className="matiereGroupeMobile">{groupe.matiere.nom_court}</div>
                                            <div className="nomProfGroupeMobile">
                                                {groupe.auteur_nom}
                                            </div>
                                        </div>
                                        <div className="divCaracteristiquesGroupeMobile">
                                            <div>{groupe.nom}</div>
                                            <div className="">
                                                {groupe.cours.length} Chatbot
                                                {groupe.cours.length > 1 && 's'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* New Assistant Card */}
                        {!loading && (
                            <div
                                className="assistant-card assistant-card-eleve new-assistant fade-in"
                                onClick={showAddGroup}
                                ref={addGroupRef}
                                style={{
                                    animationDuration: '0.1s',
                                    animationDelay: `${groupes.length * 0.05}s`,
                                }}
                            >
                                {/* Nouveau conteneur pour le scale de hover */}
                                <div className="new-assistant-inner">
                                    {errorMessage ? <>
                                        <div className="error-message-ListeMenuEleve">
                                            {errorMessage}
                                        </div>
                                    </> :
                                        <>
                                            {isAddingGroup ? (
                                                <div className="codeMobile">
                                                    <input
                                                        type="text"
                                                        className="login-input"
                                                        value={groupCode}
                                                        placeholder="Votre code ici"
                                                        onChange={(e) => setGroupCode(e.target.value)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    <button
                                                        onClick={handleAddGroup}
                                                        className="login-button ajouterGroupeFirst"
                                                    >
                                                        Ajouter
                                                    </button>
                                                    {/* Display the error message if it exists */}

                                                </div>
                                            ) : (
                                                <div className="nouveauGroupeMobile">
                                                    <div className="plus-sign">+</div>
                                                    <div className="assistant-title assistant-title-eleve ajouterGroupe">
                                                        Ajouter
                                                        <br />
                                                        un groupe
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </>
                : <>
                    <div className="assistants-container assistants-container-eleve" style={{ padding: '40px 80px' }}>
                        <div
                            key="menu"
                            className="assistant-card assistant-card-eleve fade-in assistantCardGroupe"
                            style={{
                                justifyContent: "space-between",
                                animationDuration: '0.1s',
                                animationDelay: `0s`
                            }}
                            onClick={() => {
                                navigate("/groupe/" + groupe.id);
                            }}
                        >
                            <div className="hideMobile spaceBetween">
                                <div className="divMatiere divMatiereCorrectifMarginTop">
                                    <img src={`/matiere/${groupe.matiere.id}_m.svg`} className="iconeMatiereGroupe" alt="Matière Groupe" />
                                </div>
                                <div className="infosGroupe widthSelectedGroupe infosGroupeCorrectifMarginTop">
                                    <div className="carteGroupe">{groupe.matiere.nom_court}</div>
                                    <div className="carteGroupeNomAuteur">
                                        {groupe.auteur_nom}
                                    </div>
                                </div>
                                <div className="footerGroupes widthSelectedGroupe">
                                    <div>{groupe.nom}</div>
                                    <div className="nbChatbots">
                                        {groupe.cours.length} Chatbot
                                        {groupe.cours.length > 1 && 's'}
                                    </div>
                                </div>
                            </div>
                            <div className="showMobile divSelectedGroupe">
                                <div className="divIconeGroupe">
                                    <img src={`/matiere/${groupe.matiere.id}_m.svg`} className="iconeMatiereGroupe iconeMatiereMobile" alt="Matière Groupe Mobile" />
                                </div>
                                <div className="rightPanelGroupe">
                                    <div className="">
                                        <div className="matiereGroupeMobile">{groupe.matiere.nom_court}</div>
                                        <div className="nomProfGroupeMobile">
                                            {groupe.auteur_nom}
                                        </div>
                                    </div>
                                    <div className="divCaracteristiquesGroupeMobile">
                                        <div>{groupe.nom}</div>
                                        <div className="">
                                            {groupe.cours.length} Chatbot
                                            {groupe.cours.length > 1 && 's'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentGroupe.cours && currentGroupe.cours.length > 0 ? (
                            <>
                                {currentGroupe.cours.map((cours, index) => {
                                    const modules = modules_full(cours);

                                    return (
                                        <div
                                            key={index}
                                            className="assistant-card assistant-card-eleve existing-card existing-cardHover fade-in carteCoursMobile"
                                            onClick={() => {
                                                console.log(cours.assistant_id);
                                                console.log(cours);
                                                navigate("/groupe/" + currentGroupe.id + "/chatbot/" + cours.id);
                                            }}
                                            style={{
                                                justifyContent: 'space-between',
                                                animationDuration: '0.1s',
                                                animationDelay: `${(index + 1) * 0.05}s`,
                                            }}
                                        >
                                            <div className="assistant-title assistant-title-eleve">
                                                <div>

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {cours.hasIcon === 2 ?
                                                            <img
                                                                src={`${serverPHP}/users/${cours.id}/icon.png`}
                                                                className='iconeEleveCoursBig'
                                                                alt="Icone du cours"
                                                            />
                                                            : (cours.hasIcon === 0) ?
                                                                <PicsLogo
                                                                    className='iconeEleveCoursBig'
                                                                />
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="containerFooterEleve spaceBetween">
                                                <div className="sujetEleve">{truncateText(cours.sujet, 68)}</div>

                                                <div className={`footerGroupes ${modules.filter(m => m.isVisible).length > 0 ? 'footerCours' : ''}`}>
                                                    {modules.filter(m => m.isVisible).length > 0 ?
                                                        <><div className="coursEleve">


                                                            <div className="carteCoursEleve">
                                                                {modules.filter(m => m.isVisible).map((m, i) => (
                                                                    <span key={i}>
                                                                        {React.cloneElement(m.icon, { title: m.statut, className: "iconeModuleEleve" })}
                                                                    </span>
                                                                ))}
                                                            </div>

                                                        </div>
                                                        </>
                                                        :
                                                        <div className="listeModulesEleves">Aucun module</div>
                                                    }
                                                    <div className='footerDate'>
                                                        {new Date(cours.date).toLocaleDateString('fr-FR', {
                                                            day: '2-digit',
                                                            month: '2-digit'
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div style={{ fontSize: '20px' }}>Aucun chatbot n'a encore été ajouté à ce cours.</div>
                        )}
                    </div>
                </>
            }
        </>
    );
}

export default ListeMenuEleve;
