import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ModuleQCM_Prof2_parametres.css';
import { serverPHP } from '../../consts';
import LoadingCircle from '../LoadingCircle';

const ModuleQCM_Prof2_parametres = ({ contenuFichier, setContenuFichier, setTitreMenu, listeQCM, setListeQCM, cours, setCours, setCurrentQCM }) => {

    const [loading, setLoading] = useState(false);

    const [parametresQCM, setParametresQCM] = useState({
        nb_reponses: 4,
        has_feedback: true,
        niveau: "lycée",
        nb_qcm: 5,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Step 1: Define an array of prompts
    const QCMPrompts = [
        {
            label: "Questions de cours",
            prompt: "Ce document est un cours ; il faut donc générer des QCM qui testent l'utilisateur sur sa compréhension des idées et des informations importantes."
        },
        {
            label: "Explication de texte",
            prompt: "Ce document est un texte issu du livre [titre], écrit par [auteur]. Il faut tester la compréhension de l'élève, en lui demandant d'expliciter ou de reformuler les expressions les plus difficiles."
        },
        {
            label: "Vocabulaire clé",
            prompt: "Identifie les termes ou expressions clés dans ce document pour évaluer la compréhension de leur signification par l'élève."
        },
        {
            "label": "Événements principaux",
            "prompt": "Ce document relate des événements importants. Génère des QCM qui demandent à l'élève d'identifier les événements majeurs et leur chronologie."
        },
        {
            "label": "Analyse des personnages",
            "prompt": "Ce document présente des personnages. Crée des questions à choix multiples visant à évaluer la compréhension des traits de caractère, des motivations et des relations entre les personnages."
        },
        {
            "label": "Résumé et synthèse",
            "prompt": "Génère des questions à choix multiples qui demandent à l'élève de résumer les points clés du document ou de synthétiser les informations présentées."
        },
        {
            "label": "Application des connaissances",
            "prompt": "Formule des questions à choix multiples qui demandent à l'élève d'appliquer les informations ou concepts du document à de nouvelles situations ou problèmes."
        },
        {
            label: "Personnaliser",
            prompt: ""
        }
    ];

    // Step 2: Add a state variable for the prompt
    const [prompt, setPrompt] = useState(QCMPrompts[0].prompt);

    useEffect(() => {
        setTitreMenu("2. Paramètres");
    }, [setTitreMenu]);

    const handleNbQcmChange = (event) => {
        setParametresQCM({
            ...parametresQCM,
            nb_qcm: parseInt(event.target.value),
        });
    };

    const handleNbReponsesChange = (event) => {
        setParametresQCM({
            ...parametresQCM,
            nb_reponses: parseInt(event.target.value),
        });
    };

    const handleNiveauChange = (event) => {
        setParametresQCM({
            ...parametresQCM,
            niveau: event.target.value,
        });
    };

    const handleHasFeedbackChange = (event) => {
        setParametresQCM({
            ...parametresQCM,
            has_feedback: event.target.checked,
        });
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = (updatedContent) => {
        setContenuFichier(updatedContent);  // Update content only on modal close
        setIsModalOpen(false);
    };

    // Step 3: Handle prompt selection
    const handlePromptSelection = (event) => {
        const selectedPrompt = event.target.value;
        setPrompt(selectedPrompt);
    };

    const handleGenerateQCM = () => {
        setLoading(true);
        fetch(`${serverPHP}/moduleQCM_handleQCMCreation.php`, {
            method: 'POST',
            credentials:'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // Step 4: Include prompt in the fetch request
            body: JSON.stringify({
                ...parametresQCM,
                prompt: prompt,
                contenuFichier: contenuFichier,
                assistant_id: cours.id
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Response from server:', data);
                if (data && Array.isArray(data)) {
                    setCours(prevCours => ({
                        ...prevCours,
                        modules: {
                            ...prevCours.modules,
                            qcm: [...(prevCours.modules.qcm || []), ...data]
                        }
                    }));
                    setLoading(false);
                    if (data[0] && data[0].question_id) setCurrentQCM(data[0].question_id);
                } else {
                    console.error('Invalid data format received:', data);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };

    const Modal = ({ isOpen, onClose, initialContent }) => {
        const [localContent, setLocalContent] = useState(initialContent);

        useEffect(() => {
            setLocalContent(initialContent);
        }, [initialContent]);

        if (!isOpen) return null;

        return ReactDOM.createPortal(
            <div className="modal-overlay" onClick={() => onClose(localContent)}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <textarea
                        value={localContent}
                        onChange={(e) => setLocalContent(e.target.value)}
                        className="modal-textarea"
                        style={{ width: '100%', height: '355px' }}
                    />
                    <button onClick={() => onClose(localContent)} className="login-button" style={{ marginTop: '10px' }}>Fermer</button>
                </div>
            </div>,
            document.body
        );
    };

    return (
        <>
            <div className="moduleContainer" style={{ height: '610px', position: 'relative' }}>
                {loading ?
                    <>
                        <LoadingCircle taille={150} />
                        <div className="generationQCMEnCours">
                            Génération du QCM en cours...<br /><span style={{ fontSize: '14px' }}>Cette opération peut prendre une vingtaine de secondes</span>
                        </div>
                    </>
                    :
                    <>
                        <div className="moduleTitre">Choisissez les <strong>paramètres</strong></div>

                        <div className="login-form small" style={{ marginLeft: 0 }}>
                            <div className="input-group">
                                <label className="login-label parametresQCM">Texte chargé :</label>
                                <button onClick={openModal} className="login-button boutonQCM" style={{ margin: 0 }}>Modifier</button>
                            </div>
                            <div className="input-group">
                                <label className="login-label parametresQCM">QCM à générer :</label>
                                <select
                                    value={parametresQCM.nb_qcm}
                                    onChange={handleNbQcmChange}
                                    style={{ width: '120px' }}
                                >
                                    {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                                        <option key={num} value={num}>
                                            {num}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group">
                                <label className="login-label parametresQCM">Réponses pour chaque QCM :</label>
                                <select
                                    value={parametresQCM.nb_reponses}
                                    onChange={handleNbReponsesChange}
                                    style={{ width: '120px' }}
                                >
                                    {Array.from({ length: 5 }, (_, i) => i + 2).map((num) => (
                                        <option key={num} value={num}>
                                            {num}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group">
                                <label className="login-label parametresQCM">Niveau de difficulté :</label>
                                <select
                                    value={parametresQCM.niveau}
                                    onChange={handleNiveauChange}
                                    style={{ width: '120px' }}
                                >
                                    <option key={0} value={"primaire"}>Primaire</option>
                                    <option key={1} value={"collège"}>Collège</option>
                                    <option key={2} value={"lycée"}>Lycée</option>
                                    <option key={3} value={"université"}>Université</option>
                                </select>
                            </div>
                            <div className="input-group" style={{ marginTop: '10px' }}>
                                <input
                                    type="checkbox"
                                    checked={parametresQCM.has_feedback}
                                    onChange={handleHasFeedbackChange}
                                />
                                <label className="login-label" style={{ marginLeft: '20px', width: 'auto' }}>
                                    Ajouter des feedbacks
                                </label>
                            </div>

                            {/* Step 3: Add the Instructions select and textarea */}
                            <div className="input-group" style={{marginTop:'20px'}}>
                                <label className="login-label parametresQCM" style={{width:'110px'}}>Instructions :</label>
                                <select
                                    onChange={handlePromptSelection}
                                    style={{ width: '100%', maxWidth: '250px' }}
                                >
                                    {QCMPrompts.map((item, index) => (
                                        <option key={index} value={item.prompt}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <textarea
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                                className="modal-textarea textarea"
                            />
                        </div>
                        <button
                            className="login-button"
                            style={{ width: '200px', margin: 'auto' }}
                            onClick={handleGenerateQCM}
                        >
                            Générer les QCM
                        </button>
                    </>}
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                initialContent={contenuFichier}
            />
        </>
    );
};

export default ModuleQCM_Prof2_parametres;
