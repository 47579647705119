import React, { useState } from 'react';
import FileList from "../composants/Modules/FileList";
import { serverPHP } from '../consts';
import { ReactComponent as PicsDownload } from "../pics/import_URL.svg";

const PartageEleve = ({ cours }) => {
  const [error, setError] = useState(null);

  const handleClickButton = async (file) => {
    setError(null); // Réinitialise l'erreur avant chaque tentative

    const formData = new FormData();
    formData.append('assistant_id', cours.id);
    formData.append('fichier', file.filename);
    formData.append('source', file.is_shared);

    try {
      const response = await fetch(`${serverPHP}/modules/partage/getFilePartage.php`, {
        method: 'POST',
        credentials:'include',
        body: formData,
      });
      
      if (!response.ok) throw new Error('Le serveur a renvoyé une erreur');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      setError("Échec du téléchargement du fichier.");
    }
  };

  return (
    <>
      {error && <p className="error-message">{error}</p>}
      <FileList
        listeFichiers={
          cours.files
            .filter((f) => f.is_shared)
            .filter((f) => f.statut === 2)
            .concat(cours.modules.partage) || []
        }
        isSource={false}
        onClickButton={handleClickButton}
        image={<PicsDownload />}
        opacity={0.5}
        hoverAll={true}
      />
    </>
  );
};

export default PartageEleve;
