import React, { useState, useEffect } from 'react';
import { serverPHP, getFilenameWithoutExtension } from '../../consts';
import './ModuleAccessibilite.css';
import LoadingCircle from '../LoadingCircle';

const ModuleAccessibilite_parametres = ({
    setTitreMenu,
    setCours,
    fullFileData,
    setFullFileData,
    setFichierFromSources,
    cours,
    setJobsInProgress, // Nouvelle prop
}) => {

    
    const [nomFichier, setNomFichier] = useState(getFilenameWithoutExtension(fullFileData?.filename));

    useEffect(() => {
        setTitreMenu("2. Avertissement");
    }, [setTitreMenu]);

    const handleNomFichierBlur = () => {
        if (!nomFichier.trim()) {
            setNomFichier("Document accessible 1");
        }
    };

    const handleGenerateAccessibilite = async () => {
        if (!nomFichier.trim()) {
            setNomFichier("Document accessible 1");
        }
    
        // Créer un FormData
        const formData = new FormData();
    
        // Ajouter les champs standards
        Object.entries(fullFileData).forEach(([key, value]) => {
            if (key !== "file" && key !== "filename") {
                formData.append(key, value); // Ajoute les champs normaux
            }
        });
    
        // Ajouter le fichier avec son nom d'origine
        if (fullFileData.file) {
            formData.append("file", fullFileData.file, fullFileData.filename); // Utilisez le nom original
        }
    
        // Ajouter le nom d'origine et le nouveau nom séparément
        formData.append("originalFilename", fullFileData.filename); // Nom original avec extension
        formData.append("outputFilename", nomFichier); // Nouveau nom sans extension
    
        try {

            const uniqueId = `access_${Date.now()}_${Math.random().toString(36).substring(2, 10)}`;


            setCours((prevCours) => ({
                ...prevCours,
                modules: {
                    ...prevCours.modules,
                    accessibilite: [
                        ...(prevCours.modules.accessibilite || []),
                        {
                            id: uniqueId,
                            filename: nomFichier + '.html',
                            filesize: null,
                            statut: 1,
                        },
                    ],
                },
            }));
            setFullFileData(null);
            setFichierFromSources(null);

            const response = await fetch(`${serverPHP}/modules/moduleAccessibilite_sendFile.php`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
            const responseText = await response.text();
            console.log('Response text:', responseText);
            let data;
            try {
                data = JSON.parse(responseText);
                console.log('Parsed data:', data);
                if (data.success) {
                    // Générer un ID unique pour ce fichier
                    const job = {
                        jobId: data.job_id,
                        filename: nomFichier + '.html',
                        uniqueId: uniqueId,
                        outputFilename: nomFichier + '.html',
                        status: 'pending',
                        cours_id: String(cours.id), // Convertir en chaîne
                    };
                    // Mettre à jour l'état jobsInProgress
                    setJobsInProgress((prevJobs) => [...prevJobs, job]);
    
                    // Ajouter le fichier avec statut 1 dans cours.modules.accessibilite
                    setCours((prevCours) => ({
                        ...prevCours,
                        modules: {
                            ...prevCours.modules,
                            accessibilite: (prevCours.modules.accessibilite || []).map((item) =>
                                item.id === uniqueId
                                    ? {
                                          ...item,
                                          jobId: data.job_id, // Ajoute ou remplace jobId pour l'élément correspondant
                                      }
                                    : item
                            ),
                        },
                    }));
                    
    

                } else {
                    console.error('Error starting processing:', data.error);
                    // Gérer l'erreur, par exemple afficher un message à l'utilisateur
                }
            } catch (e) {
                console.error('Error parsing response as JSON:', e);
                console.log('Response text (could not parse):', responseText);
                // Gérer l'erreur, par exemple afficher un message à l'utilisateur
            }
        } catch (error) {
            console.error('Error starting processing:', error);
            // Gérer l'erreur, par exemple afficher un message à l'utilisateur
        }
    };
    
    return (
        <>
            <div className="moduleContainer" style={{ position: 'relative' }}>
                <>
                    <div className="moduleTitre">Nommer votre <strong>fichier</strong></div>
                    <div className="login-form small" style={{ marginLeft: 0 }}>
                        <div className="input-group" style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className="login-label parametresQCM" style={{ width: '400px', marginRight: 0, marginBottom: '5px' }}>Nouveau nom :</label>
                            <input
                                type="text"
                                value={nomFichier}
                                onChange={(e) => setNomFichier(e.target.value)}
                                onBlur={handleNomFichierBlur}
                                className="login-input"
                                style={{ width: '400px' }}
                            />
                        </div>
                    </div>
                    <div className="infoAccess">
                        <p>
                            Ce module permet de transformer <strong>n'importe quel document texte</strong> pour le rendre accessible.
                        </p>
                        <p>Cela implique :</p>
                        <ul>
                            <li>une conversion au format <strong>HTML</strong></li>
                            <li>l'ajout d'une description pour chaque image</li>
                            <li>une mise en forme lisible et aérée</li>
                        </ul>
                        <p>Ce processus complexe est exigeant en ressources. Il est donc conseillé de :</p>
                        <ul>
                            <li>Limiter la taille du fichier importé (pas plus de <strong>10 pages</strong>)</li>
                            <li>
                                Limiter le nombre des images incluses dans ce fichier. En particulier, il faut&nbsp;
                                <strong>éviter les éléments purement décoratifs</strong>, qui seraient traités comme les autres images.
                                Si votre fichier contient plus de <strong>vingt images</strong>, elles seront tout simplement ignorées.
                            </li>
                            <li>
                                Ne pas fournir de fichiers PDF avec une mise en page trop complexe (type manuel de cours, page web avec interface, ou journal) :
                                ceux-ci seront mal reconnus. Préférez un format comme <strong>.odt</strong> ou <strong>.docx</strong>&nbsp;
                                et des mises en page <strong>plus linéaires</strong>.
                            </li>
                        </ul>
                        <p>
                            Le temps d'attente est de <strong>plusieurs minutes</strong> pour un fichier complexe.
                        </p>
                    </div>
                    <div className="divEnBas">
                        <button
                            className={`login-button boutonGenererAudio`}
                            onClick={handleGenerateAccessibilite}
                        >
                            Rendre accessible
                        </button>
                    </div>
                </>
            </div>
        </>
    );
};

export default ModuleAccessibilite_parametres;
