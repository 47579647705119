// SourcesMenu.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import './SourcesMenu.css';
import LoadingCircle from './LoadingCircle';
import StatusIndicator from './StatusIndicator';
import AddSourceModal from './AddSourceModal';
import DropdownMenu from '../chatbot/DropDownMenu';
import { serverPHP } from '../consts';
import { ReactComponent as PicsFleche } from "../pics/fleche.svg";
import { ReactComponent as PicsPlus } from "../pics/plus.svg";
import { ReactComponent as PicsPlusActif } from "../pics/plus_actif.svg";
import { ReactComponent as PicsExclamation } from "../pics/exclamation.svg";
import { ReactComponent as PicsTextfile } from "../pics/textfile.svg";
import { modules_full } from '../consts';
import { useNavigate } from 'react-router';
import { handleAddSource } from './addSourceFunctions';
import { processFile } from './fileUtils';


const SourcesMenu = ({ cours, setCours, setShowModules, showModules, setModuleActif, updateAssistant }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [addSourceReady, setAddSourceReady] = useState(false);
  const [openMenuFileId, setOpenMenuFileId] = useState('no-open');
  const [isDragOver, setIsDragOver] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [isValidExtension, setIsValidExtension] = useState(true);
  const [duplicateNameError, setDuplicateNameError] = useState(null);
  const fileRefs = useRef({});
  const dragCounter = useRef(0);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const leftColumnRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [numFiles, setNumFiles] = useState(0); // État pour le nombre de fichiers



  const createVectorStoreFileBatch = useCallback((newCours) => {
    if (newCours && newCours.files.length > 0) {
      const allFilesReady = newCours.files.every((file) => file.statut === 2 || file.statut === 0);
      if (allFilesReady) {
        const fileIds = newCours.files.filter((f) => f.statut === 2).map((file) => file.openai_id);
        fetch(serverPHP + "/createVectorStoreFileBatch.php", {
          method: "POST",
          credentials:'include',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ file_ids: fileIds, vector_store: newCours.vector_store }),
        }).catch((error) => console.error("Erreur lors de l'envoi du batch de fichiers :", error));
      }
    }
  }, []);

  const processUploadResponse = useCallback((response, result, filename) => {
    if (response.ok && result.db_id) {
      setCours((prevCours) => {
        const updatedCours = {
          ...prevCours,
          files: prevCours.files.map((f) =>
            f.filename === filename ? { ...f, id: result.db_id, openai_id: result.id, statut: 2, filesize: result.filesize } : f
          ),
        };
        createVectorStoreFileBatch(updatedCours);
        return updatedCours;
      });
    } else {
      const errorMessage = result.error?.message || result.error || "Erreur lors de l'ajout du fichier";
      setError(errorMessage);
      setCours((prevCours) => ({
        ...prevCours,
        files: prevCours.files.map((f) => (f.filename === filename ? { ...f, statut: 0, messageErreur: errorMessage } : f)),
      }));
    }
  }, [createVectorStoreFileBatch, setCours]);

  useEffect(() => {
    setAddSourceReady(cours && cours.assistant_id && cours.vector_store);
  }, [cours?.assistant_id, cours?.vector_store]);

  const deleteFile = useCallback(async (file) => {
    if (!file) {
      setError('Fichier invalide.');
      return;
    }

    if (file.statut === 0) {
      // Supprimer simplement le fichier de la liste locale
      setCours((prevCours) => ({
        ...prevCours,
        files: prevCours.files.filter((f) => f.filename !== file.filename),
      }));
      return;
    }

    console.log("SourcesMenu.js")
    setCours((prevCours) => ({
      ...prevCours,
      files: prevCours.files.map((f) => (f.id === file.id ? { ...f, statut: 1 } : f)),
    }));

    try {
      const formData = new FormData();
      formData.append('fileId', file.id);
      formData.append('coursId', cours.id);

      const response = await fetch(serverPHP + '/deleteFile.php', {
        method: 'POST',
        credentials:'include',
        body: formData
      });
      const result = await response.json();

      if (response.ok) {
        setCours((prevCours) => ({
          ...prevCours,
          files: prevCours.files.filter((f) => f.id !== file.id),
        }));
        createVectorStoreFileBatch(cours);
      } else if (result.error) {
        setError(`Erreur lors de la suppression du fichier : ${result.error}`);
      }
    } catch (err) {
      console.error('Erreur lors de la suppression du fichier', err);
      setError('Erreur lors de la suppression du fichier');
    }
  }, [cours, setCours, createVectorStoreFileBatch]);

  const downloadFile = useCallback(async (file) => {
    if (!file || !file.id) {
      setError('Fichier invalide ou ID manquant.');
      return;
    }

    try {
      setError(null);

      const formData = new FormData();
      formData.append('fileId', file.id);

      const response = await fetch(serverPHP + '/db/downloadFile.php', {
        method: 'POST',
        credentials:'include',
        body: formData
      });
      if (!response.ok) throw new Error('Erreur lors du téléchargement du fichier');

      const blob = await response.blob();
      const customFilename = (file.filename || 'default_name.txt').replace(/_/g, ' ');
      const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', customFilename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Erreur lors du téléchargement du fichier', err);
      setError(err.message || 'Erreur lors du téléchargement du fichier.');
    }
  }, [cours]);

  const modules = useMemo(() => modules_full(cours), [cours]);

  

  // Gestion des événements de glisser-déposer
  const onDragOver = useCallback((event) => {
    if (dialogOpen) return;
    event.preventDefault();
    event.stopPropagation();
  }, [dialogOpen]);

  const onDragEnter = useCallback((event) => {
    if (dialogOpen) return;
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.types.includes('Files')) {
      const files = event.dataTransfer.items || [];
      setNumFiles(files.length); // Mettre à jour le nombre de fichiers
      dragCounter.current++;
      if (dragCounter.current === 1) {
        setIsDragOver(true);
      }
    }
  }, [dialogOpen]);
  
  const onDragLeave = useCallback((event) => {
    if (dialogOpen) return;
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.types.includes('Files')) {
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setIsDragOver(false);
        setNumFiles(0); // Réinitialiser le nombre de fichiers
      }
    }
  }, [dialogOpen]);
  
  

  useEffect(() => {
    const handleDragStart = () => {
      setIsDragging(true);
    };
  
    const handleDragEnd = () => {
      setIsDragging(false);
      dragCounter.current = 0;
      setIsDragOver(false);
    };
    
  
    document.addEventListener('dragstart', handleDragStart);
    document.addEventListener('dragend', handleDragEnd);
  
    return () => {
      document.removeEventListener('dragstart', handleDragStart);
      document.removeEventListener('dragend', handleDragEnd);
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    // Rien à faire ici pour le moment
  }, []);
  
  const onMouseLeave = useCallback(() => {
    if (!isDragging) {
      setIsDragOver(false);
      dragCounter.current = 0;
    }
  }, [isDragging]);

  const onDrop = useCallback(async (event) => {
    if (dialogOpen) return;
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current = 0;
    setIsDragOver(false);
    setError(null);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        // Check if a file with the same name already exists
        let uniqueFilename = file.name;
        let suffix = 1;
        const extension = file.name.split('.').pop();
        let nameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
  
        while (cours.files.some((existingFile) => existingFile.filename === uniqueFilename)) {
          uniqueFilename = `${nameWithoutExtension}(${suffix}).${extension}`;
          suffix++;
        }
  
        // Create a new File object with the unique filename
        const fileWithUniqueName = new File([file], uniqueFilename, { type: file.type });
  
        // Use processFile to validate the file
        const { fileData, fileInfo, isValidExtension, error: fileError } = await processFile(fileWithUniqueName, cours.files);
  
        if (fileError) {
          // Display the error message and add the file with status 0 (error)
          setError(fileError);
          setCours((prevCours) => ({
            ...prevCours,
            files: [
              ...prevCours.files,
              {
                filename: uniqueFilename,
                statut: 0,
                messageErreur: fileError,
              },
            ],
          }));
          continue;
        }
  
        // Prepare data for handleAddSource
        const data = {
          type: 'file',
          data: {
            file: fileData,
            filename: uniqueFilename,
          },
        };
  
        // Call handleAddSource
        handleAddSource(data, cours, setCours, setError, processUploadResponse, '/handleFileUpload.php');
      }
    }
  }, [cours, setCours, setError, processUploadResponse, dialogOpen]);

  // Gestion de la fermeture des menus déroulants lors du défilement
  useEffect(() => {
    const handleScroll = () => {
      setOpenMenuFileId('no-open');
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div
  className="left-column"
  onDragOver={onDragOver}
  onDragEnter={onDragEnter}
  onDragLeave={onDragLeave}
  onDrop={onDrop}
  onMouseEnter={onMouseEnter}
  onMouseLeave={onMouseLeave}
  ref={leftColumnRef}
  style={{ position: 'relative' }}
>
      {isDragOver ? (
         <div className="drop-zone-overlay">
         <div className="drop-zone-message">
           {numFiles === 1 ? (
             <p>Déposez<br />le fichier<br />ici</p>
           ) : (
             <p>Déposez<br />les fichiers<br />ici</p>
           )}
         </div>
       </div>
      ) : (
        <>
          <div className="containerLeftColumn" ref={containerRef}>
            <div className="retourListe" onClick={() => navigate("/")}>
              <PicsFleche width={30} height={30} alt="Retour" />
              Retour à la liste
            </div>
            <div className="topMenuLeft">
              <div className="groupeMenuGauche">
                <div className="sources-header">
                  <h2>Sources</h2>
                  <button onClick={() => setDialogOpen(true)} className="add-file-btn" style={{ display: 'flex' }}>
                    <PicsPlus height={20} width={20} alt="Ajouter" />
                  </button>
                </div>
                {cours && (
                  <div className="file-list">
                    {cours.files.length === 0 ? (
                      <p>Ajoutez des fichiers pour nourrir votre chatbot : cliquez sur le "+" ci-dessus, ou déposez-les ici.</p>
                    ) : (
                      <ul>
                        {cours.files.map((file, index) => {
                          const uniqueKey = file.id != null ? file.id : `${file.filename}-${index}`;
                          if (!fileRefs.current[uniqueKey]) {
                            fileRefs.current[uniqueKey] = React.createRef();
                          }
                          const fileRef = fileRefs.current[uniqueKey];

                          return (
                            <li key={uniqueKey}>
                              <div
                                className="fileItem"
                                title={file.filename}
                                ref={fileRef}
                                onClick={() => {
                                  setOpenMenuFileId(openMenuFileId === uniqueKey ? 'no-open' : uniqueKey);
                                }}
                              >
                                <div className="fileImage">
                                  {file.statut === 1 ? (
                                    <LoadingCircle taille={30} marginLeft={-15} />
                                  ) : file.statut === 0 ? (
                                    <div className="image-container">
                                      <PicsExclamation className="svg-icon" style={{ height: '30px', width: '30px' }} alt="Erreur" />
                                    </div>
                                  ) : (
                                    <div className="image-container">
                                      <PicsTextfile className="svg-icon" style={{ height: '30px', width: '30px' }} alt="Fichier" />
                                    </div>
                                  )}
                                </div>
                                <div className="textEllipsis">{file.filename.replace(/_/g, ' ')}</div>
                              </div>
                              <DropdownMenu
                                openMenu={openMenuFileId === uniqueKey}
                                setOpenMenu={() => setOpenMenuFileId('no-open')}
                                buttonRef={fileRef}
                                menuItems={[
                                  ...(file.statut === 2
                                    ? [{ label: 'Télécharger le fichier', onClick: () => downloadFile(file) }]
                                    : []),
                                  { label: 'Supprimer le fichier', onClick: () => deleteFile(file) },
                                ]}
                                openDirection="right"
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    {error && <p className="error-message">{error}</p>}
                  </div>
                )}
              </div>

              <div className="groupeMenuGauche">
                <div className="sources-header">
                  <h2>Modules</h2>
                  <button
                    onClick={() => setShowModules((prev) => {
                      if (!prev) setModuleActif(null);
                      return !prev;
                    })}
                    className="add-file-btn"
                    style={{ display: 'flex' }}
                  >
                    {showModules ? (
                      <PicsPlusActif height={20} width={20} alt="Ajouter" />
                    ) : (
                      <PicsPlus height={20} width={20} alt="Ajouter" />
                    )}
                  </button>
                </div>
                <div className="file-list">
                  <ul>
                    {modules.map((module, index) =>
                      module.isVisible && (
                        <li key={module.id || `module-${index}`}>
                          <div
                            className="fileItem"
                            onClick={() => {
                              setShowModules(true);
                              setModuleActif(module.title);
                            }}
                          >
                            <div className="fileImage svg-icon" style={{ height: '40px', width: '40px' }} alt={module.title}>
                              {module.icon}
                            </div>
                            <div style={{ marginLeft: '5px' }}>
                              <strong>{module.title}</strong>
                              <div>{module.statut}</div>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                    {modules.every((m) => !m.isVisible) && <p>Ajoutez des modules pour plus d'interactivité avec vos élèves.</p>}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bottomMenuLeft">
            <StatusIndicator cours={cours} />
          </div>
          {dialogOpen && (
            <div className="modal-overlay">
              <AddSourceModal
                isOpen={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAddSource={(data) => handleAddSource(data, cours, setCours, setError, processUploadResponse, '/handleFileUpload.php')}
                cours={cours}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SourcesMenu;
