import { useRef, useState, useEffect, useCallback } from 'react';
import { ReactComponent as PicsImport } from "../pics/import_URL.svg";
import { ReactComponent as PicsGenerate } from "../pics/generate.svg";
import { ReactComponent as PicsLogo } from "../pics/picLogo.svg";
import { ReactComponent as PicsDelete } from "../pics/delete.svg";

import './PicModal.css';
import { serverPHP } from "../consts";
import LoadingCircle from './LoadingCircle';

const PicModal = ({ isPicModalOpen, setIsPicModalOpen, cours, setCours, onImageUpload, imageVersion, triggerAutomaticPicGen, setTriggerAutomaticPicGen  }) => {
    const modalRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState("importer");
    const [prompt, setPrompt] = useState("");
    const [uploading, setUploading] = useState(false);
    const [generating, setGenerating] = useState(false); // Nouvel état pour la génération d'image
    const [error, setError] = useState(null);

    useEffect(() => {
        if (triggerAutomaticPicGen)
        {
            generateImage(`Une illustration pour un cours portant sur le thème suivant : "${cours.sujet === "Nouveau cours" ? "[Ajoutez le thème de votre cours ici]" : cours.sujet}"`);
            setTriggerAutomaticPicGen(false);
        }
    }, [triggerAutomaticPicGen])

    useEffect(() => {
        if (cours && cours.sujet) {
            setPrompt(`Une illustration pour un cours portant sur le thème suivant : "${cours.sujet === "Nouveau cours" ? "[Ajoutez le thème de votre cours ici]" : cours.sujet}"`);
        }
    }, [cours?.sujet]);

// Fonction pour supprimer l'image
const deletePic = async () => {
    setCours((prevCours) => ({ ...prevCours, hasIcon: 0 }));
    setError(null);
    setIsPicModalOpen(false);

    try {
        const response = await fetch(`${serverPHP}/deleteImage.php`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                coursId: cours.id,
            }),
        });

        const result = await response.json();

        if (response.ok && result.success) {
            onImageUpload(); // Notifier le parent pour mettre à jour l'image
        } else {
            setError(result.error || "Une erreur est survenue lors de la suppression de l'image.");
        }
    } catch (err) {
        setError("Erreur réseau : " + err.message);
    }
};

    // Gestion du clic en dehors du modal
    const handleClickOutside = useCallback(
        (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsPicModalOpen(false);
            }
        },
        [setIsPicModalOpen]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    // Gestion de la sélection de fichier
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && validateFile(file)) {
            uploadFile(file);
        } else {
            setError("Format de fichier invalide. Veuillez sélectionner un fichier .jpg, .jpeg ou .png.");
        }
    };

    // Validation du fichier sélectionné
    const validateFile = (file) => {
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        return allowedTypes.includes(file.type);
    };

    // Fonction d'upload du fichier
    const uploadFile = async (file) => {
        setUploading(true);
        setError(null);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('coursId', cours.id);
        setCours((prevCours) => ({ ...prevCours, hasIcon: 1 })); //working
        try {
            const response = await fetch(`${serverPHP}/setImageCours.php`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });

            const result = await response.json();

            if (response.ok) {
                setCours((prevCours) => ({ ...prevCours, hasIcon: 2 }));
                onImageUpload(); // Notifier le parent pour mettre à jour l'image
                //setIsPicModalOpen(false);
            } else {
                setError(result.error || "Une erreur est survenue lors de l'upload de l'image.");
            }
        } catch (err) {
            setError("Erreur réseau : " + err.message);
        } finally {
            setUploading(false);
        }
    };

    // Fonction pour générer une image via l'API OpenAI
    const generateImage = async (newPrompt) => {
        if (!newPrompt.trim()) {
            setError("Le prompt ne peut pas être vide.");
            return;
        }

        setGenerating(true);
        setError(null);
        setCours((prevCours) => ({ ...prevCours, hasIcon: 1 })); //working

        try {
            const response = await fetch(`${serverPHP}/generateImage.php`, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    coursId: cours.id,
                    prompt: newPrompt.trim(),
                }),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                setCours((prevCours) => ({ ...prevCours, hasIcon: 2 }));
                onImageUpload(); // Notifier le parent pour mettre à jour l'image
                //setIsPicModalOpen(false);
            } else {
                setError(result.error || "Une erreur est survenue lors de la génération de l'image.");
            }
        } catch (err) {
            setError("Erreur réseau : " + err.message);
        } finally {
            setGenerating(false);
        }
    };

    if (!isPicModalOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef} style={{ height: '405px', minWidth:'555px', width: '555px' }}>
                <div className="options-menu" style={{ gap: '80px' }}>
                    <div
                        className={`option-item ${selectedTab === 'importer' ? 'selected' : ''}`}
                        onClick={() => setSelectedTab('importer')}
                    >
                        <PicsImport alt="Importer" className="tab-icon" />
                        <span>Importer</span>
                        {selectedTab === 'importer' && <div className="option-underline"></div>}
                    </div>
                    <div
                        className={`option-item ${selectedTab === 'generer' ? 'selected' : ''}`}
                        onClick={() => setSelectedTab('generer')}
                    >
                        <PicsGenerate alt="Générer" className="tab-icon" />
                        <span>Générer</span>
                        {selectedTab === 'generer' && <div className="option-underline"></div>}
                    </div>
                    <div
                        className={`option-item ${cours.hasIcon === 2 ? 'selected' : 'forbiddenDelete'}`}
                        onClick={() => {if (cours.hasIcon === 2) deletePic()}}
                    >
                        <PicsDelete alt="Supprimer" className="tab-icon" />
                        <span style={{color:"grey"}}>Supprimer</span>
                    </div>
                </div>
                <div className="modal-content-area">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='iconeCoursBig' style={{ marginRight: '20px' }}>
                            {(cours.hasIcon == 2) ?
                                <img
                                    src={`${serverPHP}/users/${cours.id}/icon.png?${imageVersion}`} // Ajout de imageVersion
                                    className='iconeCoursBig'
                                    alt="Icone du cours"
                                />
                                : (cours.hasIcon == 1) ?
                                <div className='iconeCoursBig' style={{position:'relative'}}>
                                    <LoadingCircle taille={150} />
                                    </div>
                                : (cours.hasIcon == 0) ?
                                <div className='iconeCoursBig noImagePic'>
                                    <div>Aucune image</div>
                                    <div>pour ce cours</div>
                                    </div>
                                : <></>
                            }
                        </div>
                        <div className='panneauGauche'>
                            {selectedTab === 'importer' ? (
                                <>
                                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <div className='infoUploadImage'>
                                            <div>Vous pouvez charger une image au format JPEG ou PNG grâce au bouton ci-dessous.</div>
                                            <div style={{marginTop:'20px'}}>Votre image sera transformée en un carré de dimensions 512*512.</div>
                                        </div>
                                        <div style={{width:'100%'}}>
                                        <button
                                            className={generating || uploading ? 'disabled-button' : 'login-button'}
                                            onClick={() => document.getElementById('fileInput').click()}
                                            disabled={generating || uploading}
                                            style={{ width: '100%', height: '45px', justifyContent: 'center', height:'45px' }}
                                        >
                                            {generating ? "Génération..." : uploading ? "Téléchargement..." : "Charger le fichier"}
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept=".jpg,.jpeg,.png"
                                            onChange={handleFileChange}
                                        />
                                        {error && <div className="error-message" style={{width:'80%'}}>{error}</div>}
                                        </div>
                                    </div>
                                </>
                            ) : selectedTab === 'generer' ? (
                                <>
                                    <div>
                                        <div style={{ marginBottom: '10px' }}>Je veux :</div>
                                        <textarea
                                            value={prompt}
                                            onChange={(e) => setPrompt(e.target.value)}
                                            className="modal-textarea"
                                            style={{ height: '150px' }}
                                            placeholder="Saisissez le message d'accueil ici"
                                        />
                                    </div>
                                    <button
                                        onClick={() => generateImage(prompt)} // Utiliser la fonction generateImage
                                        className={generating || uploading ? 'disabled-button' : 'login-button'}
                                        style={{ width: '100%', marginTop: '20px', height:'45px' }}
                                        disabled={generating || uploading}
                                    >
                                        {generating ? "Génération..." : uploading ? "Téléchargement..." : "Générer une image"}
                                    </button>
                                    {error && <div className="error-message">{error}</div>}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default PicModal;
