// LoginPage.jsx

import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import LoadingCircle from '../composants/LoadingCircle';
import { serverPHP } from '../consts';
import { useNavigate } from 'react-router';
import { ReactComponent as PicsLogo } from "../pics/logo.svg";
import { ReactComponent as PicsFleche_Droite } from "../pics/fleche_droite.svg";
import AccountForm from '../composants/AccountForm';

const LoginPage = ({ setUserInfo, setError, error, info, setInfo, matieres, loadSignUpStep }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stayConnected, setStayConnected] = useState(false);
    const [shake, setShake] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [isMouseDownInsideModal, setIsMouseDownInsideModal] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [inputCode, setInputCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [showCodeModal, setShowCodeModal] = useState(false);
    const [signUpStep, setSignUpStep] = useState(0); // 0: login, 1: choice, 2: sign-up form
    const [localUserInfo, setLocalUserInfo] = useState({ prof: 0 }); // Local state to manage userInfo



    const navigate = useNavigate();

    useEffect(() => {
        setSignUpStep(loadSignUpStep);
    }, [loadSignUpStep])

    useEffect(() => {
        if (error !== null) {
            triggerShake();
            setShowModal(true);
        }
    }, [error]);

    useEffect(() => {
        if (info !== null) {
            setShowAccessModal(true);
        }
    }, [info]);
    

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);

    };

    const triggerShake = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value); // Always update the password state
    
        if (value.length > 40 || !value) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    };

    const handleLogin = async () => {
        if (!validateEmail(email)) {
            setError('L\'adresse e-mail n\'est pas valide.');
            return;
        }
        if (!email || !password) {
            setError('Veuillez renseigner l\'adresse e-mail et le mot de passe.');
            return;
        }

        const loginData = {
            mail: email,
            password: password,
            remember_me: stayConnected,
        };

        try {
            setWaiting(true); // Start the loading indicator
            const response = await fetch(serverPHP + '/db/login.php', {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(loginData),
            });

            const data = await response.json();
            setWaiting(false); // Stop the loading indicator

            if (data.status === 'success') {
                setUserInfo({
                    id: data.id,
                    nom: data.nom,
                    prof: data.prof,
                    instructions_default: data.instructions_default,
                    accroche_default: data.accroche_default,
                    niveau_reponses: data.niveau_reponses,
                    longueur_reponses: data.longueur_reponses,
                    matieres: data.matieres
                });
                setError(null);
                setShowModal(false);
            } else if (data.reason === 'needCode') {
                setError(null);
                setShowCodeModal(true);
            } else {
                setError(data.message || 'Erreur de connexion');
            }
        } catch (err) {
            setWaiting(false); // Stop the loading indicator in case of error
            setError('Erreur lors de la connexion. Veuillez réessayer.');
        }
    };

    const handleClickOutsideModal = (e) => {
        if (!isMouseDownInsideModal && e.target === e.currentTarget) {
            setShowModal(false);
            setShowAccessModal(false);
            setShowCodeModal(false);
            setError(null);
            setInfo(null);
            setCodeError("");
            setInputCode("");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleKeyPress2 = (e) => {
        if (e.key === 'Enter') {
            handleCodeValidation();
        }
    };

    const handleCodeValidation = () => {
        if (inputCode.length !== 5) {
            setCodeError('Le code doit comporter 5 caractères.');
        } else {
            const escapedCode = encodeURIComponent(inputCode);
            navigate("confirm/" + escapedCode);
        }
    };

    return (
        <div className="login-container" id="login-container">
            <div className={`login-box ${shake ? 'shake' : ''}`} style={{width:signUpStep === 0 ? '500px' : '650px', transition: signUpStep!==0 ? 'all 0.3s ease' : 'none'}}>
                {
                        signUpStep === 0 ?
                            // Login form
                            <>
                                <div className="msgaccueil">
                                    <div className="imgLogo">
                                        <PicsLogo height={133} width={200} alt="Logo" className="svgLogo"/>
                                    </div>
                                    <div className="msgEduBot">
                                        <div className="login-title titrelogo bigLogo">
                                            EduBot
                                        </div>
                                        <div className="login-title titrelogo smallLogo" style={{ fontSize: '18px', fontWeight: 'normal' }}>
                                            Chatbots personnalisés<br />pour les enseignants
                                        </div>
                                    </div>
                                </div>
                                <div className="login-form">
                                    <div className="input-group">
                                        <label htmlFor="email" className="login-label">Adresse e-mail :</label>
                                        <input
                                            type="text"
                                            id="email"
                                            className={`login-input ${emailError ? 'input-error' : ''}`}
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailError(false);
                                            }}
                                            onBlur={() => {
                                                if (!validateEmail(email)) {
                                                    setEmailError(true);
                                                } else {
                                                    setEmailError(false);
                                                }
                                            }}
                                            onKeyDown={handleKeyPress}
                                        />
                                    </div>

                                    <div className="input-group">
                                        <label htmlFor="password" className="login-label">Mot de passe :</label>
                                        <input
                                            type="password"
                                            id="password"
                                            className={`login-input ${passwordError ? 'input-error' : ''}`}
                                            value={password}
                                            onChange={handlePasswordChange}
                                            onKeyDown={handleKeyPress}

                                        />
                                    </div>

                                    <div className="login-checkbox-container">
                                        <input
                                            type="checkbox"
                                            id="stay-connected"
                                            className="login-checkbox"
                                            checked={stayConnected}
                                            onChange={(e) => setStayConnected(e.target.checked)}
                                        />
                                        <label htmlFor="stay-connected" className="login-checkbox-label">Rester connecté</label>
                                    </div>
                                </div>
                                <div className="button-container" style={{padding:'0 90px'}}>
                                    <button className="login-button" onClick={handleLogin}>Se connecter</button>
                                    <button className="signup-button" onClick={() => navigate("/inscription/")}/*() => setSignUpStep(1)}*/>
                                        <PicsFleche_Droite width={20} height={20} className="flecheDroite" alt="S'inscrire" />S'inscrire
                                    </button>
                                </div>
                            </>
                            : signUpStep === 1 ?
                                // Choice between 'élève' and 'enseignant'
                                <>
                                    <h1 className="login-title" style={{ fontSize: '26px' }}>Inscription</h1>
                                    <div className="signup-choice">
                                        <button className="login-button" onClick={() => { setLocalUserInfo({ prof: 0 }); setSignUpStep(2); }}>Je suis élève</button>
                                        <button className="login-button" onClick={() => { setLocalUserInfo({ prof: 1 }); setSignUpStep(2); }}>Je suis enseignant·e</button>
                                    </div>
                                    <div className="signup-message">
                                        <p>Un accès <strong>enseignant</strong> permet de construire de nouveaux chatbots, alors que l'accès <strong>élève</strong> permet seulement de les utiliser.</p>
                                        <p>Pour obtenir un accès enseignant, il faut s'inscrire avec une <strong>adresse mail académique</strong> (de type nom@ac-nantes.fr, nom@ac-versailles.fr...).</p>
                                    </div>
                                </>
                                : signUpStep === 2 ?
                                    // Sign-up form using AccountForm
                                    <>
                                        <AccountForm
                                            goBack={() => setSignUpStep(0)}
                                            isTeacher={localUserInfo.prof}
                                            matieres={matieres}
                                            setInfo={setInfo}
                                        />
                                    </>
                                    : null
                }
            </div>

            {showModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-login">
                        {error && <p>{error}</p>}
                    </div>
                </div>
            )}

            {showAccessModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-access" style={{ width: '450px' }}>
                        {info}
                    </div>
                </div>
            )}

            {showCodeModal && (
                <div
                    className="modal-overlay-login"
                    onMouseDown={() => setIsMouseDownInsideModal(false)}
                    onClick={handleClickOutsideModal}
                >
                    <div
                        className="modal-content-access"
                        style={{ width: '250px' }}
                        onMouseDown={() => setIsMouseDownInsideModal(true)}
                        onMouseUp={() => setIsMouseDownInsideModal(false)}
                    >
                        <p>Entrez ici le code reçu par mail :</p>
                        <input
                            type="text"
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                            onKeyDown={handleKeyPress2}
                            className="login-input"
                        />
                        <p className="error-message">{codeError}</p>
                        <button onClick={handleCodeValidation} className="login-button">Valider</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginPage;
