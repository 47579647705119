import { useState, useEffect, useRef } from 'react';

import { formatFileSize } from "../../consts/index.js";
import { ReactComponent as PicsImagefile } from "../../pics/imagefile.svg";
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";
import { ReactComponent as PicsExclamation } from "../../pics/exclamation.svg";
import { ReactComponent as PicsDownload } from "../../pics/import_URL.svg"; // Ajout de l'icône de téléchargement
import LoadingCircle from '../LoadingCircle.js';
import { serverPHP } from '../../consts/index.js';

import './Modules.css';

const ModuleAccessibilite_ListeAccessibilite = ({ cours, handleDelete = () => { }, isProf = true }) => {

    useEffect(() => {
        console.log(cours.modules.accessibilite);
    }, [])

    const handleClickButton = async (file) => {
        const formData = new FormData();
        formData.append('assistant_id', cours.id);
        formData.append('fichier', file.filename);

        try {
            const response = await fetch(`${serverPHP}/modules/accessibilite/getFileAccessibilite.php`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });

            if (!response.ok) throw new Error('Le serveur a renvoyé une erreur');

            const blob = await response.blob();

            // Si le fichier est HTML, spécifiez le type avec charset
            const contentType = file.filename.endsWith('.html') ? 'text/html;charset=utf-8' : blob.type;
            const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));

            // Check file extension to determine action
            if (file.filename.endsWith('.html')) {
                // Ouvrir HTML dans un nouvel onglet
                window.open(url, '_blank');
            } else {
                // Télécharger les autres types de fichiers
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }

        } catch (error) {
            console.error('Erreur lors du téléchargement:', error);
            // Vous pouvez afficher un message d'erreur à l'utilisateur ici
        }
    };

    const handleDownload = async (file) => {
        const formData = new FormData();
        formData.append('assistant_id', cours.id);
        formData.append('fichier', file.filename);

        try {
            const response = await fetch(`${serverPHP}/modules/accessibilite/getFileAccessibilite.php`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });

            if (!response.ok) throw new Error('Le serveur a renvoyé une erreur');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Créer un lien de téléchargement
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erreur lors du téléchargement:', error);
            // Vous pouvez afficher un message d'erreur à l'utilisateur ici
        }
    };

    return (
        <div className="containerFichiersPartage">
            {cours.modules.accessibilite
                .slice() // Crée une copie du tableau pour ne pas modifier l'original
                .reverse() // Inverse l'ordre des éléments
                .map((file, index) => (
                    <div
                        key={`${file.filename}-${index}`} // Utiliser filename comme clé si c'est unique
                        className={`partageItem ${file.statut === 2 ? 'partageHover' : file.statut === 0 ? 'errorStatus' : 'noHoverCursor'}`}
                        style={{ justifyContent: "space-between", cursor: 'pointer', flexDirection: 'column', margin: 0, padding: 0 }}
                        onClick={() => handleClickButton(file)}
                    >
                        <div className="divAudio">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={`smallButton  ${file.statut === 0 ? 'errorStatus' : ''}`} style={{ position: 'relative' }}>
                                    {file.statut === 1 ? (
                                        <LoadingCircle taille={30} marginLeft={0} />
                                    ) : file.statut === 0 ? (
                                        <div className="image-container">
                                            <PicsExclamation className="svg-icon" style={{ height: '30px', width: '30px' }} alt="Erreur" />
                                        </div>
                                    ) : (
                                        <div className="image-container">
                                            <PicsImagefile className="svg-icon" style={{ height: '30px', width: '30px' }} alt="Fichier" />
                                        </div>
                                    )}
                                </div>
                                <span>{file.filename}</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="partageTaille" style={{ marginRight: '10px' }}>
                                    ({`${formatFileSize(file.filesize).size} ${formatFileSize(file.filesize).unit}`.trim()})
                                </span>
                                <div style={{display:'flex',gap:"7px"}}>
                                {(file.statut === 2) &&
                                <div
                                    className="downloadButtonSVG"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownload(file);
                                    }}
                                    title="Télécharger le fichier"
                                >
                                    <PicsDownload/>
                                </div>
                                }
                                {(isProf && (file.statut === 2 || file.statut === 0)) &&
                                    <div
                                        className="deleteButtonSVG"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(file);
                                        }}
                                    >
                                        <PicsDelete />
                                    </div>
                                }
                                </div>
                                {/* Bouton de téléchargement */}

                            </div>
                        </div>
                    </div>
                ))}

            {cours.modules.accessibilite.length === 0 &&
                <div style={{ width: '100%' }}>
                    <div className="containerFichiersPartage"></div>
                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                        Vous n'avez encore généré aucun document accessible.
                    </p>
                </div>
            }
        </div>
    )
}

export default ModuleAccessibilite_ListeAccessibilite;
