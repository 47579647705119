// fileUtils.js
import { formatFileSize } from '../consts';
import { fileTypeFromBlob } from 'file-type-browser';

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

export const acceptedExtensions = [
  'csv', 'docx', 'html', 'htm', 'md', 'pdf', 'pptx', 'txt', 'xlsx', 'xml', 'zip'
];

export const conditionallyAcceptedExtensions = [
  'odt', 'epub', 'rtf'
];

export const mimeTypeToExtension = {
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'text/html': 'html',
  'text/markdown': 'md',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'text/plain': 'txt',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/xml': 'xml',
  'text/xml': 'xml',
  'application/zip': 'zip',
  'application/vnd.oasis.opendocument.text': 'odt',
};

export const processFile = async (file, coursFiles, wide = false, noPadding) => {
  try {
    if (file.size > MAX_FILE_SIZE) {
      return { error: 'La taille du fichier dépasse la limite de 20 Mo.' };
    }

    const fileTypeResult = await fileTypeFromBlob(file);
    const detectedExtension = fileTypeResult ? fileTypeResult.ext : null;
    const extension = file.name.split('.').pop().toLowerCase();
    const isAccepted = acceptedExtensions.includes(extension) || conditionallyAcceptedExtensions.includes(extension);

    // Vérification de la correspondance entre l'extension et le type de fichier détecté
    let isTypeValid = true;
    if (detectedExtension) {
      if (extension !== detectedExtension) {
        // Cas particuliers où la différence est acceptable (par exemple, .odt détecté comme .zip)
        if (
          (extension === 'odt' && detectedExtension === 'zip') ||
          (extension === 'epub' && detectedExtension === 'zip')
        ) {
          isTypeValid = true;
        } else {
          isTypeValid = false;
        }
      }
    } else {
      // Si le type ne peut pas être détecté, on considère que c'est valide si l'extension est acceptée
      isTypeValid = isAccepted;
    }

    // Vérification de la validité globale
    const isValidExtension = isAccepted && isTypeValid;

    if (!isAccepted) {
      return { error: `L'extension .${extension} n'est pas acceptée.` };
    }

    if (!isTypeValid) {
      return { error: `Le contenu du fichier ne correspond pas à l'extension .${extension}. Veuillez vérifier l'intégrité de votre fichier.` };
    }

    const isDuplicate = (!wide || noPadding) && coursFiles.some((existingFile) => existingFile.filename === file.name);
    if (isDuplicate) {
      return { error: "Ce fichier existe déjà dans la base. Veuillez choisir un autre fichier, ou supprimez d'abord le fichier actuel." };
    }

    const formattedSize = formatFileSize(file.size);
    const fileInfo = {
      name: file.name,
      size: formattedSize.size,
      unit: formattedSize.unit,
      lastModifiedDate: file.lastModified ? new Date(file.lastModified).toLocaleString() : 'Inconnue',
      extension,
      detectedExtension,
      isTypeValid,
    };

    return { fileData: file, fileInfo, isValidExtension: true };
  } catch (error) {
    console.error('Erreur lors du traitement du fichier :', error);
    return { error: 'Une erreur est survenue lors du traitement du fichier.' };
  }
};



export const checkFileNameValidity = (fileName, extension, coursFiles, setDuplicateNameError, setIsValidExtension, wide = false, noPadding) => {
  if (!fileName.trim()) {
    setDuplicateNameError("Le nom du fichier ne peut pas être vide.");
    setIsValidExtension(true);
    return;
  }

  if (!acceptedExtensions.includes(extension) && !conditionallyAcceptedExtensions.includes(extension)) {
    setIsValidExtension(false);
  } else {
    setIsValidExtension(true);
  }

  const isDuplicate = (!wide || noPadding) && coursFiles.some((file) => file.filename === fileName);
  setDuplicateNameError(isDuplicate ? "Ce fichier existe déjà dans la base. Veuillez choisir un autre fichier, ou supprimez d'abord le fichier actuel." : null);
};
