import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './Modules.css';
import { serverPHP } from '../../consts';
import mermaid from 'mermaid';
import { toPng } from 'html-to-image';
import svgPanZoom from 'svg-pan-zoom';

const ModuleMindmap_ShowMindmap = ({
  currentMindmap,
  setCurrentMindmap,
  canModify = true,
  assistant_id = null,
  mindmap_id = null,
  updateSource = () => {},
}) => {
  const [diagramCode, setDiagramCode] = useState(currentMindmap.content);
  const [diagramSVG, setDiagramSVG] = useState('');
  const [error, setError] = useState(null);
  const [showSourceEditor, setShowSourceEditor] = useState(false);
  const [loading, setLoading] = useState(false);
  const diagramRef = useRef(null);
  const SVGRef = useRef(null);
  const panZoomInstanceRef = useRef(null);
  const sourceEditorRef = useRef(null);
  const modalRef = useRef(null); // Référence pour le modal

  // Générer un ID unique pour le diagramme
  const diagramIdRef = useRef(
    `mermaid-diagram-${Math.random().toString(36).substr(2, 9)}`
  );
  const diagramId = diagramIdRef.current;

  // Initialiser Mermaid avec la configuration appropriée
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
      securityLevel: 'strict',
      parseError: (err) => {
        console.error('Mermaid parse error:', err);
        setError('Erreur de syntaxe dans le diagramme Mermaid.');
        setDiagramSVG('');
        setLoading(false);
      },
      errorHandler: (err) => {
        console.error('Mermaid render error:', err);
        setError('Erreur lors du rendu du diagramme Mermaid.');
        setDiagramSVG('');
        setLoading(false);
      },
    });
  }, []);

  // Mettre à jour diagramCode lorsque currentMindmap change
  useEffect(() => {
    setDiagramCode(currentMindmap.content);
  }, [currentMindmap]);

  // Rendre le diagramme lorsque diagramCode change
  useEffect(() => {
    renderDiagram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagramCode]);

  const renderDiagram = async () => {
    setLoading(true);
    setError(null);
    setDiagramSVG('');

    // Nettoyer le code du diagramme en supprimant les balises de code
    let cleanedCode = diagramCode.trim();
    if (cleanedCode.startsWith('```mermaid')) {
      cleanedCode = cleanedCode.substring('```mermaid'.length);
    }
    if (cleanedCode.endsWith('```')) {
      cleanedCode = cleanedCode.substring(0, cleanedCode.length - 3);
    }
    cleanedCode = cleanedCode.trim();

    // Créer un conteneur temporaire pour empêcher Mermaid d'ajouter des éléments au DOM
    const container = document.createElement('div');

    try {
      // Rendre le diagramme dans le conteneur
      const { svg } = await mermaid.render(
        diagramId,
        cleanedCode,
        undefined,
        container
      );

      // Modifier le SVG pour avoir une largeur et une hauteur de 100% et définir l'ID
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
      const svgElement = svgDoc.documentElement;
      svgElement.setAttribute('width', '100%');
      svgElement.setAttribute('height', '100%');
      svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');
      svgElement.setAttribute('id', diagramId); // S'assurer que le SVG a le bon ID

      const serializer = new XMLSerializer();
      const modifiedSvg = serializer.serializeToString(svgElement);

      setDiagramSVG(modifiedSvg);
      setLoading(false);
      setError(null);
    } catch (err) {
      console.error('Mermaid render error:', err);
      setError('Erreur lors du rendu du diagramme Mermaid.');
      setDiagramSVG('');
      setLoading(false);
    }
  };

  // Initialiser svg-pan-zoom lorsque diagramSVG change
  useEffect(() => {
    if (diagramSVG && diagramId) {
      // Utiliser setTimeout pour retarder l'initialisation
      const timer = setTimeout(() => {
        const svgElement = document.getElementById(diagramId);
        if (svgElement) {
          // Détruire l'instance précédente si elle existe
          if (panZoomInstanceRef.current) {
            panZoomInstanceRef.current.destroy();
          }

          // Initialiser svg-pan-zoom
          panZoomInstanceRef.current = svgPanZoom(svgElement, {
            zoomEnabled: true,
            controlIconsEnabled: true,
            fit: true,
            center: true,
          });
        } else {
          console.error('Élément SVG introuvable pour l\'initialisation de svgPanZoom');
        }
      }, 0);

      // Nettoyer le timer lors du démontage
      return () => clearTimeout(timer);
    }

    // Nettoyer lors du démontage
    return () => {
      if (panZoomInstanceRef.current) {
        panZoomInstanceRef.current.destroy();
        panZoomInstanceRef.current = null;
      }
    };
  }, [diagramSVG, diagramId]);

  // Gérer les changements dans le textarea
  const handleChange = (e) => {
    setDiagramCode(e.target.value);
  };

  // Gérer l'événement onBlur du textarea
  const handleBlur = useCallback(() => {
    updateSource(diagramCode);

    // Préparer les données à envoyer
    const data = {
      assistant_id: assistant_id,
      mindmap_id: mindmap_id,
      content: diagramCode,
    };

    // Envoyer une requête POST pour mettre à jour la mindmap
    fetch(`${serverPHP}/db/moduleMindmap_modifyCarte.php`, {
      method: 'POST',
      credentials:'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('La réponse du réseau n\'était pas correcte');
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.error) {
          console.error('Erreur lors de la mise à jour de la mindmap:', responseData.error);
        } else {
          console.log('Mindmap mise à jour avec succès.');
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la requête fetch:', error);
      });
  }, [assistant_id, mindmap_id, diagramCode, updateSource]);

  // Fermer le modal
  const closeModal = useCallback(() => {
    setCurrentMindmap(null);
  }, [setCurrentMindmap]);

  // Télécharger le diagramme en tant que PNG
  const handleDownload = () => {
    if (SVGRef.current) {
      toPng(SVGRef.current, {
        backgroundColor: '#FFFFFF',
        pixelRatio: 3,
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'diagram.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Erreur lors de la génération de l\'image:', err);
        });
    }
  };

  // Empêcher le défilement du body lorsque le modal est ouvert
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  // Gérer les clics en dehors du modal
  const handleClickOutside = useCallback(
    (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Basculer l'éditeur de source
  const toggleSourceEditor = (e) => {
    e.stopPropagation();
    if (showSourceEditor) {
      setShowSourceEditor(false);
    } else {
      setShowSourceEditor(true);
    }
  };

  // Gérer les clics en dehors de l'éditeur de source
  const handleClickOutsideSourceEditor = useCallback(
    (e) => {
      if (
        showSourceEditor &&
        sourceEditorRef.current &&
        !sourceEditorRef.current.contains(e.target) &&
        !e.target.classList.contains('login-button')
      ) {
        handleBlur();
        setShowSourceEditor(false);
      }
    },
    [showSourceEditor, handleBlur]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSourceEditor);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSourceEditor);
    };
  }, [handleClickOutsideSourceEditor]);

  return (
    <>
      {ReactDOM.createPortal(
        <div
          className="modal-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: 'white',
              padding: '10px',
              width: '90%',
              height: '90%',
              maxWidth: '90vw',
              maxHeight: '90vh',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
            }}
            ref={modalRef} // Référence au modal
          >
            {/* Overlay de l'éditeur de source */}
            {showSourceEditor && (
              <div
                onClick={(e) => e.stopPropagation()}
                className="sourceEditor"
                ref={sourceEditorRef}
              >
                <textarea
                  className="textareaSVG"
                  value={diagramCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}

            {/* Affichage du diagramme */}
            <div className="containerMsgSVG" style={{ flex: 1 }}>
              {loading && <div>Chargement du diagramme...</div>}
              {!loading && error && (
                <div className="erreurSVG">
                  {error}
                  <br />
                  Veuillez vérifier la conformité du code.
                </div>
              )}
              {!loading && !error && diagramSVG && (
                <div
                  id="diagram-container"
                  style={{ width: '100%', height: '100%' }}
                  dangerouslySetInnerHTML={{ __html: diagramSVG }}
                  ref={SVGRef}
                />
              )}
            </div>

            {/* Boutons en bas */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '10px',
              }}
            >
              {canModify && (
                <button
                  onClick={toggleSourceEditor}
                  style={{ marginRight: '10px' }}
                  className="login-button"
                >
                  Modifier source
                </button>
              )}
              <button onClick={handleDownload} className="login-button">
                Télécharger
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default ModuleMindmap_ShowMindmap;
