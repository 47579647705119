import React from 'react';
import './Header.css';
import ProfileButton from './ProfileButton';
import { ReactComponent as PicsFleche } from "../pics/fleche.svg";
import { ReactComponent as PicsPlusRond } from "../pics/plus_rond.svg";
import { modules_full } from '../consts';
import { useNavigate } from 'react-router';
import { serverPHP } from "../consts";
import { ReactComponent as PicsLogo } from "../pics/picLogo.svg";
import LoadingCircle from './LoadingCircle';



const HeaderEleve = ({ cours, userInfo, setUserInfo, showModules, setShowModules, groupe_id }) => {

  const modules = modules_full(cours);
  const navigate = useNavigate();


  const formatDate = (date) => {
    if (!date) return '';

    const validDate = typeof date === 'string' ? new Date(date) : date;
    if (isNaN(validDate)) return '';

    return validDate.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
    });
  };

  return (

    <div className="header-banner header-bannerELEVEONLY">
      <div className="headerFirstLine">
        <div className="menuHautEleve menuHautEleve_seeAssistant showMobile" style={{ marginTop: '11px', marginLeft: '-2px' }}
          onClick={() => navigate("/groupe/" + groupe_id)}>
          <PicsFleche width={30} height={30} alt="Retour" />
          Autres cours
        </div>
        <div style={{ flex: 1, display: 'flex' }}>
          <div className="retourListeEleve hideMobile" onClick={() => navigate("/groupe/" + groupe_id)}>
            <PicsFleche width={50} height={50} alt="Retour" />
          </div>
          {cours && (
            <>

              <div style={{ display: 'flex', flexDirection: 'row', marginLeft:'10px' }}>
                <div className="containerCoursEleve">
                  {cours.hasIcon == 2 ?
                    <img
                      src={`${serverPHP}/users/${cours.id}/icon.png`}
                      className='iconeCoursEleve'
                      alt="Icone du cours"
                    />
                    : (cours.hasIcon == 1) ?
                      <div className='iconeCoursEleve' style={{ position: 'relative', outline: 'none' }}
                      >
                        <LoadingCircle taille={50} />
                      </div>
                      : (cours.hasIcon == 0) ?
                        <PicsLogo
                          className='iconeCoursEleve'
                        />
                        : <></>
                  }
                </div>
                <div>
                  <div className="titreDate hideMobile">
                    Cours du{' '}
                    <span style={{ fontWeight: 700 }}>
                      {cours?.date ? formatDate(cours.date) : 'Choisissez une date'}
                    </span>
                  </div>
                  <div className="input-container">
                    <span className="titreSujet titreSujetEleve hideMobile">
                      {cours?.sujet || ''}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '-5px', flex: 1, justifyContent: 'flex-end' }}>
          {modules.filter(m => m.isVisible).length > 0 &&
            <div className={showModules ? "boutonSVGEleve boutonRessourcesOn" : "boutonSVGEleve"} onClick={() => setShowModules((prev) => (!prev))}>
              <span>
                {modules.filter(m => m.isVisible).length} {modules.filter(m => m.isVisible).length > 1 ? 'modules' : 'module'}
              </span>
            </div>
          }
          <ProfileButton userInfo={userInfo} setUserInfo={setUserInfo} />
        </div>
      </div>
      <div>

      </div>
    </div>
  );
};

export default HeaderEleve;
