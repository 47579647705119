import React, { useEffect, useRef, useState, useCallback } from 'react';
import './SettingsModal.css';
import { ReactComponent as InstructionsIcon } from "../pics/instructionsIcon.svg";
import { ReactComponent as AccueilIcon } from "../pics/accueilIcon.svg";
import { ReactComponent as SuggestionsIcon } from "../pics/suggestionsIcon.svg";
import { ReactComponent as PicsDelete } from "../pics/delete.svg";
import { ReactComponent as ResumeIcon } from "../pics/resumeIcon.svg";
import { ReactComponent as PicsSettings } from "../pics/settings.svg";
import { ReactComponent as PicsSettingsOn } from "../pics/settings_on.svg";

import { serverPHP } from '../consts';
import Module_selectFile from './Modules/Module_selectFile';
import LoadingCircle from './LoadingCircle';

// Move this outside of SettingsModal
const Resume = ({ localResume, setLocalResume, cours }) => {
  return (
    <>
      <textarea
        value={localResume || ''}
        onChange={(e) => setLocalResume(e.target.value)}
        className="modal-textarea"
        placeholder="Saisissez le résumé du cours ici"
      />
      <div className="infos">
        Ce résumé du cours permet au chatbot d'avoir un regard synthétique sur son organisation. Il est conseillé ici de fournir l'idée principale et la structure du plan. Vous pouvez laisser ce champ vide.
      </div>
    </>
  );
};

const AutomatiserParametres = ({ contenuFichier, cours, setCours, closeAndSave, updateAssistant, setError, setContenuFichier, setManualMode, setIsWorking, setTriggerAutomaticPicGen }) => {

  useEffect(() => {
    if (contenuFichier !== null) {
      // Fetch the PHP script
      setIsWorking(true);
      fetch(serverPHP + "/automatiser_parametres.php", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          cours_id: cours.id,
          contenuFichier: contenuFichier
        })
      })
        .then(response => response.json())
        .then(data => {
          setIsWorking(false);
          // Vérifiez que les données nécessaires sont présentes
          if (data.instructions && data.resume && data.accroche && Array.isArray(data.suggestions)) {
            // Mettez à jour le cours avec les nouvelles données

            setCours((prevCours) => {
              const newCours = {
                ...prevCours,
                sujet: data.sujet,
                instructions: data.instructions,
                resume: data.resume,
                accroche: data.accroche,
                suggestions: data.suggestions
              };

              //console.log("Nouvel état de cours :", newCours);
              updateAssistant(newCours); // Appeler une fonction avec le nouvel objet
              return newCours;
            });
            setTriggerAutomaticPicGen(true);
            setManualMode(true);
            closeAndSave(false);
          } else {
            // Gérer l'erreur
            setIsWorking(false);
            console.error("Erreur dans les données reçues de automatiser_parametres.php", data);
            setError("Erreur lors de la génération des paramètres. Veuillez réessayer.");
            setContenuFichier(null); // Revenir à ModeAutomatique
          }
        })
        .catch(error => {
          setIsWorking(false);
          console.error("Erreur lors de l'appel à automatiser_parametres.php :", error);
          setError("Erreur de communication avec le serveur. Veuillez réessayer.");
          setContenuFichier(null); // Revenir à ModeAutomatique
        });
    }
  }, [contenuFichier, cours.id, setCours, setError, setContenuFichier]);

  return (
    <div
      className={`moduleContainer moduleContainerNoPadding`}
      style={{ position: 'relative' }}
    >
      <div style={{ height: '400px' }}>
        <LoadingCircle taille={150} />
      </div>
      <div className="generationQCMEnCours">
        Définition des paramètres...
        <br />
        <span style={{ fontSize: '14px' }}>
          Cette opération peut prendre une vingtaine de secondes
        </span>
      </div>
    </div>
  );
};

const ModeAutomatique = ({ cours, setCours, closeAndSave, updateAssistant, setError, setManualMode, setIsWorking, setTriggerAutomaticPicGen }) => {
  const [contenuFichier, setContenuFichier] = useState(null);
  const [fichierFromSources, setFichierFromSources] = useState(null);

  return (
    <>
      {contenuFichier !== null ? (
        <AutomatiserParametres
          contenuFichier={contenuFichier}
          cours={cours}
          setCours={setCours}
          closeAndSave={closeAndSave}
          updateAssistant={updateAssistant}
          setError={setError}
          setContenuFichier={setContenuFichier}
          setManualMode={setManualMode}
          setIsWorking={setIsWorking}
          setTriggerAutomaticPicGen={setTriggerAutomaticPicGen}
        />
      ) : (
        <>
          {fichierFromSources === null && (
            <div className="infoModeAutomatique">
              <p>
                Pour construire votre chatbot, certains paramètres doivent être définis. Vous pouvez <strong>automatiser</strong> ceux-ci en fournissant votre cours. Il est recommandé de s'assurer que les paramètres produits automatiquement sont corrects : pour cela, vous pouvez désactiver le mode automatique en cliquant sur le bouton ci-dessus.
              </p>
            </div>
          )}
          <Module_selectFile
            cours={cours}
            reset={null}
            setReset={() => { }}
            setContenuFichier={setContenuFichier}
            setTitreMenu={() => { }}
            navigationSelectFile={fichierFromSources}
            setNavigationSelectFile={setFichierFromSources}
            titreSelection={<></>}
            noPadding={true}
            setCours={setCours}
            setError={setError}
            setIsWorking={setIsWorking}
          />
        </>
      )}
    </>
  );
};

const SettingsModal = ({ isOpen, onClose, cours, setCours, updateAssistant, showAutomaticMenu, setShowAutomaticMenu, setTriggerAutomaticPicGen }) => {
  const [selectedTab, setSelectedTab] = useState('instructions');
  const [localInstructions, setLocalInstructions] = useState("");
  const [localAccroche, setLocalAccroche] = useState("");
  const [localResume, setLocalResume] = useState("");
  const [newSuggestion, setNewSuggestion] = useState("");
  const [error, setError] = useState(null); // Nouvel état pour l'erreur
  const suggestionsListRef = useRef(null);
  const [isWorking, setIsWorking] = useState(false);

  const modalRef = useRef(null);
  const [manualMode, setManualMode] = useState(true);

  useEffect(() => {
    if (cours) {
      if (cours.accroche) setLocalAccroche(cours.accroche);
      if (cours.instructions) setLocalInstructions(cours.instructions);
      if (cours.resume) setLocalResume(cours.resume);
    }
  }, [cours]);

  useEffect(() => {
    if (showAutomaticMenu) {
      setManualMode(false);
      setShowAutomaticMenu(false);
    }
  }, [showAutomaticMenu, setShowAutomaticMenu]);

  const updateInstructions = () => {
    // Implémenter si nécessaire
  };

  // Memoize closeAndSave to ensure it has the latest state
  const closeAndSave = useCallback((update = true) => {
    if (cours.instructions !== localInstructions) {
      updateInstructions(); // Update instructions
    }
    // Les messages se réinitialisent automatiquement en cas de changement de l'accroche

    if (update) updateAssistant({
      ...cours,
      instructions: localInstructions,
      resume: localResume,
      accroche: localAccroche
    });

    onClose();
  }, [localInstructions, localAccroche, localResume, updateAssistant, onClose, cours]);

  // Define handleClickOutside outside of useEffect
  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !isWorking) {
        closeAndSave();
      }
    },
    [closeAndSave]
  );

  // Close modal on outside click
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  if (!isOpen || !cours) return null;

  const reset = () => {
    if (selectedTab === 'instructions') {
      setLocalInstructions(`Tu es un assistant serviable. C'est le professeur qui t'a fourni les documents : appuie-toi sur eux pour répondre aux questions de l'élève. Utilise une mise en forme MarkDown (spécifiquement le gras) pour mettre en avant les informations les plus importantes. Si on t'interroge sur un autre sujet que le cours, refuse poliment et recadre la discussion.`);
    } else if (selectedTab === 'accueil') {
      setLocalAccroche(`Bonjour, je suis là pour répondre à toutes tes questions sur le cours.`);
    } else if (selectedTab === 'suggestions') {
      setNewSuggestion("");
    } else if (selectedTab === 'resume') {
      setLocalResume("");
    }
  };

  const handleAddSuggestion = () => {
    if (!cours.assistant_id || !newSuggestion.trim()) {
      console.error("assistant_id ou suggestion manquant.");
      return;
    }

    const suggestionToAdd = newSuggestion.trim();

    fetch(serverPHP + "/db/addSuggestion.php", {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        assistant_id: cours.id,
        suggestion: suggestionToAdd
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.id && data.suggestion) {
          // Add the new suggestion to the state
          setCours(prevCours => ({
            ...prevCours,
            suggestions: [...(prevCours.suggestions || []), { id: data.id, suggestion: data.suggestion }]
          }));
          setNewSuggestion("");

          // Scroll to the top of the suggestions list
          if (suggestionsListRef.current) {
            suggestionsListRef.current.scrollTop = 0;
          }
        } else {
          console.error("Erreur lors de l'ajout de la suggestion:", data.message);
          setError("Erreur lors de l'ajout de la suggestion.");
        }
      })
      .catch(error => {
        console.error("Erreur lors de l'ajout de la suggestion:", error);
        setError("Erreur lors de l'ajout de la suggestion.");
      });
  };

  const handleDeleteSuggestion = (suggestionId) => {
    if (!cours.assistant_id || !suggestionId) {
      console.error("assistant_id ou suggestion_id manquant.");
      return;
    }

    fetch(serverPHP + "/db/delSuggestion.php", {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        assistant_id: cours.id,
        suggestion_id: suggestionId
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          // Remove the suggestion from the array
          setCours(prevCours => ({
            ...prevCours,
            suggestions: prevCours.suggestions.filter(suggestion => suggestion.id !== suggestionId)
          }));
        } else {
          console.error("Erreur lors de la suppression de la suggestion:", data.message);
          setError("Erreur lors de la suppression de la suggestion.");
        }
      })
      .catch(error => {
        console.error("Erreur lors de la suppression de la suggestion:", error);
        setError("Erreur lors de la suppression de la suggestion.");
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef} style={{ height: '520px' }}>
        {!isWorking ?
          <div
            className={`boutonSVG ${!manualMode ? 'showModules' : ''}`}
            onClick={() => {
              setManualMode(!manualMode);
            }}
            style={{ marginTop: 0, width: '180px', alignSelf: 'end', marginBottom: manualMode ? '20px' : 0 }}
          >
            {!manualMode ? (
              <>
                <PicsSettingsOn width={20} height={20} />
              </>
            ) : (
              <>
                <PicsSettings width={20} height={20} />
              </>
            )}
            Mode automatique
          </div>
          : <div style={{ height: '50px' }} />
        }
        {manualMode ? (
          <>
            <div className="options-menu" style={{ gap: '60px' }}>
              <div
                className={`option-item ${selectedTab === 'instructions' ? 'selected' : ''}`}
                onClick={() => setSelectedTab('instructions')}
              >
                <InstructionsIcon alt="Instructions" className="tab-icon" />
                <span>Instructions</span>
                {selectedTab === 'instructions' && <div className="option-underline"></div>}
              </div>
              <div
                className={`option-item ${selectedTab === 'resume' ? 'selected' : ''}`}
                onClick={() => setSelectedTab('resume')}
              >
                <ResumeIcon alt="Résumé" className="tab-icon" />
                <span>Résumé</span>
                {selectedTab === 'resume' && <div className="option-underline"></div>}
              </div>
              <div
                className={`option-item ${selectedTab === 'accueil' ? 'selected' : ''}`}
                onClick={() => setSelectedTab('accueil')}
              >
                <AccueilIcon alt="Message d'accueil" className="tab-icon" />
                <span>Accroche</span>
                {selectedTab === 'accueil' && <div className="option-underline"></div>}
              </div>
              <div
                className={`option-item ${selectedTab === 'suggestions' ? 'selected' : ''}`}
                onClick={() => setSelectedTab('suggestions')}
                style={{ position: 'relative' }}
              >
                {cours.suggestions.length > 0 &&
                  <div className="nbsuggestions">{cours.suggestions.length}</div>
                }
                <SuggestionsIcon alt="Suggestions" className="tab-icon" />
                <span>Suggestions</span>
                {selectedTab === 'suggestions' && <div className="option-underline"></div>}
              </div>
            </div>

            <div className="modal-content-area">
              {selectedTab === 'instructions' ? (
                <>
                  <textarea
                    value={localInstructions || ''}
                    onChange={(e) => setLocalInstructions(e.target.value)}
                    className="modal-textarea"
                    placeholder="Saisissez les instructions ici"
                  />
                  <div className="infos">
                    Les instructions sont <strong>l'identité</strong> de votre chatbot : elles décrivent
                    qui il est, ce qu'il doit dire et faire. Il est fortement conseillé de demander au
                    bot de <strong>refuser de répondre</strong> à toute question qui ne
                    concerne pas le cours.
                  </div>
                </>
              ) : selectedTab === 'accueil' ? (
                <>
                  <textarea
                    value={localAccroche || ''}
                    onChange={(e) => setLocalAccroche(e.target.value)}
                    className="modal-textarea"
                    placeholder="Saisissez le message d'accueil ici"
                  />
                  <div className="infos">
                    L'<strong>accroche</strong> est le premier message qui sera affiché à l'utilisateur
                    quand il commencera une conversation avec le chatbot. Vous pouvez utiliser la <strong>syntaxe MarkDown</strong> pour votre message : écrivez *ainsi* pour l'italique, et **ainsi** pour le gras. Vous pouvez laisser ce champ
                    vide si vous ne voulez pas de message d'accueil.
                  </div>
                </>
              ) : selectedTab === 'resume' ? (
                <Resume localResume={localResume} setLocalResume={setLocalResume} cours={cours} />
              ) : selectedTab === 'suggestions' ? (
                <div className="suggestions-tab">
                  <div className="add-suggestion-container">
                    <input
                      type="text"
                      value={newSuggestion}
                      onChange={(e) => {
                        if (e.target.value.length <= 500) {
                          setNewSuggestion(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && newSuggestion.trim() && newSuggestion.length <= 500) {
                          handleAddSuggestion();
                        }
                      }}
                      className="url-input"
                      placeholder="Votre suggestion ici..."
                    />
                    <div
                      className={`login-button  ${newSuggestion.trim() && newSuggestion.length <= 500 ? '' : 'disabled'}`}
                      onClick={() => {
                        if (newSuggestion.trim() && newSuggestion.length <= 500) {
                          handleAddSuggestion();
                        }
                      }}
                      style={{ cursor: newSuggestion.trim() && newSuggestion.length <= 500 ? 'pointer' : 'default', fontWeight: 'normal', height: '40px', alignContent: 'center', padding: 0, width: '80px', textAlign: 'center' }}
                    >
                      Ajouter
                    </div>
                  </div>
                  <div className="suggestions-list" ref={suggestionsListRef}>
                    {(cours.suggestions || []).slice().reverse().map(suggestion => (
                      <div key={suggestion.id} className="suggestion-item">
                        <span className="suggestion-text">{suggestion.suggestion}</span>
                        <span
                          className="delete-suggestion"
                          onClick={() => handleDeleteSuggestion(suggestion.id)}
                          title="Supprimer la suggestion"
                        >
                          <PicsDelete className="deleteIcon" />
                        </span>
                      </div>
                    ))}
                    {cours.suggestions.length === 0 && (
                      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'flex-start', height: '100%' }}>
                        <div className="infos" style={{ alignSelf: 'center' }}>
                          Les <strong>questions</strong> que vous ajoutez ici seront suggérées à l'utilisateur.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="modal-div-boutons">
              <button onClick={reset} className="login-button" style={{ width: '150px' }}>
                Réinitialiser
              </button>
              <button onClick={closeAndSave} className="login-button" style={{ width: '150px' }}>
                Fermer
              </button>
            </div>
          </>
        ) : (
          <ModeAutomatique
            cours={cours}
            setCours={setCours}
            closeAndSave={closeAndSave}
            updateAssistant={updateAssistant}
            setError={setError} // Passer setError en prop
            setManualMode={setManualMode}
            setIsWorking={setIsWorking}
            setTriggerAutomaticPicGen={setTriggerAutomaticPicGen}
          />
        )}
        {/* Afficher le message d'erreur */}
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default SettingsModal;
