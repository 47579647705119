import { useState, useEffect } from 'react';
import ModuleQCM_Prof2_parametres from './ModuleQCM_Prof2_parametres';
import FileList from './FileList';
import { ReactComponent as PicsTextfile } from "../../pics/textfile.svg";
import './Modules.css';
import { serverPHP } from '../../consts';
import ModuleQCM_EditQCM from './ModuleQCM_EditQCM';
import Module_selectFile from './Module_selectFile';

const ModuleQCM_Prof = ({ cours, setCours, setTitreMenu, reset, setReset }) => {
    const [contenuFichier, setContenuFichier] = useState(null);
    const [listeQCM, setListeQCM] = useState([]);
    //const [importCompleted, setImportCompleted] = useState(false);
    const [currentQCM, setCurrentQCM] = useState(null);

    const [fichierFromSources, setFichierFromSources] = useState(null);


    useEffect(() => {
        if (reset) {
            //console.log("hello");
            setContenuFichier(null);
            setReset(false);
            setFichierFromSources(null);
            setCurrentQCM(null);
            setTitreMenu(null);
        }
    }, [reset]);

    // Construct the file list for listeFichiers
    const listeFichiers = cours.modules.qcm.map(n => ({
        filename: n.question,
        filesize: 0,
        question_id: n.question_id
    }));

    // Function to handle deleting all QCM
    const handleDeleteAllQCM = async () => {
        try {
            const response = await fetch(`${serverPHP}/db/delAllQCM.php`, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ assistant_id: cours.id }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error deleting all QCM:", errorData.error);
                return;
            }

            // Update cours to clear QCM list if deletion was successful
            setCours((prevCours) => ({
                ...prevCours,
                modules: {
                    ...prevCours.modules,
                    qcm: []
                }
            }));
        } catch (error) {
            console.error("Error deleting all QCM:", error);
        }
    };

    const handleClickButton = async (file) => {
        setCurrentQCM(file.question_id);
    };

    const setQcm = (updatedQcm) => {
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                qcm: prevCours.modules.qcm.map(qcm =>
                    qcm.question_id === updatedQcm.question_id ? updatedQcm : qcm
                )
            }
        }));
    };

    const qcmIndex = cours.modules.qcm.findIndex((p) => p.question_id === currentQCM);
    const backId = qcmIndex > 0 ? cours.modules.qcm[qcmIndex - 1].question_id : null; // Précédent index ou null s'il n'existe pas
    const nextId = qcmIndex < cours.modules.qcm.length - 1 ? cours.modules.qcm[qcmIndex + 1].question_id : null; // Précédent index ou null s'il n'existe pas


    return (
        <div className="moduleMain">
            {currentQCM !== null && qcmIndex !== -1 ? (
                <ModuleQCM_EditQCM
                    qcm={cours.modules.qcm[qcmIndex]}
                    setCours={setCours}
                    setQcm={setQcm}
                    backId={backId}
                    nextId={nextId}
                    setCurrentQCM={setCurrentQCM}
                    setContenuFichier={setContenuFichier}
                />)
                :
                <>

                    {contenuFichier === null ?
                        <>
                            <Module_selectFile
                                cours={cours}
                                reset={reset}
                                setReset={setReset}
                                setContenuFichier={setContenuFichier}
                                setTitreMenu={setTitreMenu}
                                navigationSelectFile={fichierFromSources}
                                setNavigationSelectFile={setFichierFromSources}
                            />
                            {(fichierFromSources === null) && 
                            <div className="moduleContainer">
                                <div className="moduleTitre">Questions <strong>ajoutées</strong></div>

                                <div className="signup-choice" style={{ margin: '0 0 20px 0', width: '100%' }}>
                                    <FileList
                                        listeFichiers={listeFichiers}
                                        isSource={true}
                                        selectFile={true}
                                        isFile={false}
                                        image={<PicsTextfile />}
                                        hoverAll={true}
                                        onClickButton={handleClickButton}
                                        filterTxt={false}
                                        noFileMsg={<>
                                            Vous n'avez pas encore généré de QCM.<br />
                                            Veuillez sélectionner un fichier avec l'une des méthodes ci-dessus.
                                        </>}
                                    />
                                    {listeFichiers.length > 0 &&
                                        <button
                                            className="login-button"
                                            style={{ width: '200px', margin: 'auto', marginTop: '-20px' }}
                                            onClick={handleDeleteAllQCM}
                                        >
                                            Tout supprimer
                                        </button>}
                                </div>
                            </div>
                            }
                        </>
                        :
                        <ModuleQCM_Prof2_parametres
                            contenuFichier={contenuFichier}
                            setContenuFichier={setContenuFichier}
                            setTitreMenu={setTitreMenu}
                            listeQCM={listeQCM}
                            setListeQCM={setListeQCM}
                            cours={cours}
                            setCours={setCours}
                            setCurrentQCM={setCurrentQCM}
                        />
                    }
                </>
            }
        </div>
    );
};

export default ModuleQCM_Prof;
