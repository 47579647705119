// MyAccountModalEleve.js
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { ReactComponent as UserIcon } from "../pics/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../pics/passwordIcon.svg";
import { serverPHP } from '../consts';

import ModifyPassword from './ModifyPassword';

const OptionItem = ({ nomMenu, icone: IconComponent, nomAffiche, selectedTab, setSelectedTab }) => (
    <div
        className={`option-item ${selectedTab === nomMenu ? 'selected' : ''}`}
        onClick={() => setSelectedTab(nomMenu)}
    >
        <IconComponent alt={nomAffiche} className="tab-icon" />
        <span>{nomAffiche}</span>
        {selectedTab === nomMenu && <div className="option-underline"></div>}
    </div>
);

const MyAccountModalEleve = ({
    isOpen,
    onClose,
    userInfo,
    setUserInfo,
}) => {
    const [selectedTab, setSelectedTab] = useState('mesinfos');
    const modalRef = useRef(null);

    const [nomAffiche, setNomAffiche] = useState(userInfo.nom);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [shake, setShake] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        if (error !== null) {
            triggerShake();
            setShowModal(true);
        }
    }, [error]);

    const triggerShake = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };

    const closeAndSave = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleClickOutside = useCallback(
        (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.modal-overlay-login')) {
                closeAndSave();
            }
        },
        [modalRef, closeAndSave]
    );

    const handleClickOutsideModal = (e) => {
        if (e.target === e.currentTarget) {
            setShowModal(false);
            setError(null);
        }
    };

    const handleSubmit = async () => {
        if (!nomAffiche) {
            setError('Il est nécessaire de choisir un nom à afficher.');
            return;
        }

        if (nomAffiche.length > 40) {
            setError('Le nom ne doit pas dépasser 40 caractères.');
            return;
        }

        if (oldPassword) {
            if (!newPassword) {
                setError("Si vous voulez changer de mot de passe, il est nécessaire d'en entrer un nouveau.");
                return;
            }
            if (!confirmNewPassword) {
                setError("Veuillez confirmer votre nouveau mot de passe.");
                return;
            }
            if (newPassword !== confirmNewPassword) {
                setError("Le nouveau mot de passe et la confirmation ne correspondent pas.");
                return;
            }
            if (oldPassword === newPassword) {
                setError("Le nouveau mot de passe doit être différent de l'ancien.");
                return;
            }
        }

        const submitData = {
            nom: nomAffiche,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };

        try {
            let url = serverPHP + '/db/modifyUser.php';

            const response = await fetch(url, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(submitData),
            });

            const data = await response.json();

            if (data.status === 'error') {
                setError(data.message || 'Erreur lors de l\'opération.');
            } else if (data.status === 'success') {
                setUserInfo({
                    ...userInfo,
                    nom: nomAffiche
                });
                onClose();
            } else {
                setError('Réponse inconnue du serveur.');
            }
        } catch (err) {
            setError('Erreur lors de l\'opération. Veuillez réessayer.');
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, handleClickOutside]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className={`modal-content ${shake ? 'shake' : ''}`} ref={modalRef} style={{ width: '500px', height:'375px' }}>
                <div className="options-menu" style={{ gap: '40px' }}>
                    <OptionItem
                        nomMenu="mesinfos"
                        icone={UserIcon}
                        nomAffiche="Mes infos"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    <OptionItem
                        nomMenu="password"
                        icone={PasswordIcon}
                        nomAffiche="Mot de passe"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                </div>

                <div className="modal-content-area" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {selectedTab === 'mesinfos' ? (
                        <>
                            <div className="input-group">
                                <label htmlFor="username" className="login-label" style={{ width: '300px' }}>Nom affiché :</label>
                                <input
                                    type="text"
                                    id="username"
                                    className="login-input"
                                    value={nomAffiche}
                                    onChange={(e) => setNomAffiche(e.target.value)}
                                />
                            </div>
                        </>
                    ) : (selectedTab === 'password') ? (
                        <ModifyPassword
                            oldPassword={oldPassword}
                            setOldPassword={setOldPassword}
                            newPassword={newPassword}
                            setNewPassword={setNewPassword}
                            confirmNewPassword={confirmNewPassword}
                            setConfirmNewPassword={setConfirmNewPassword}
                        />
                    ) : null}
                </div>
                <div className="button-container" style={{ padding: '0 80px' }}>
                    <button className="login-button" onClick={handleSubmit}>Modifier</button>
                    <button className="signup-button" onClick={() => onClose()}>
                        Retour
                    </button>
                </div>
            </div>
            {showModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-login">
                        {error && <p>{error}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyAccountModalEleve;
