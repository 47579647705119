import React, { useMemo, useState } from "react";
import Chatbot from "../chatbot";
import HeaderEleve from "./HeaderEleve";
import SeeModules from "../interface_eleve/seeModules";

const SeeAssistant = ({ cours, userInfo, setUserInfo, ComposantInputBar, groupe_id, suggestions }) => {

    const [showModules, setShowModules] = useState(false)
    const [moduleActif, setModuleActif] = useState(null);
    const [showSources, setShowSources] = useState(false); // il  faudra faire quelque chose de showSources

    const promptNiveauReponse = [
        "Réponds avec des mots très simples : tu t'adresses à un jeune enfant de 7 ans.",
        "Réponds en simplifiant : tu t'adresses à un jeune adolescent de 12 ans.",
        "Réponds de façon élaborée : tu t'adresses à un adolescent de 17 ans.",
        "Réponds de façon aussi précise et élaborée que possible : tu t'adresses à un adulte."
    ];

    const promptLongueurReponses = [
        "Ta réponse doit tenir en une seule phrase, ne fais pas plus long, sous aucun prétexte.",
        "Ta réponse doit faire quelques phrases au maximum. Ne dépasse pas cette longueur.",
        "Tu peux prendre plusieurs paragraphes pour construire ta réponse.",
        "Rédige un cours entier et extensif pour répondre à cette question."
    ];

    const prePrompt = useMemo(() => ({
        avant: "",
        apres: `${promptNiveauReponse[userInfo.niveau_reponses]} ${promptLongueurReponses[userInfo.longueur_reponses]}`
    }), [userInfo.niveau_reponses, userInfo.longueur_reponses]);

    return (
        <>
            <div className="container">
                <div className="center-section" style={{ overflow: "hidden" }}>
                    <HeaderEleve
                        cours={cours}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        setShowModules={setShowModules}
                        showModules={showModules}
                        groupe_id={groupe_id}
                    />
                    <Chatbot
                        assistant={cours ? cours.assistant_id : null}
                        accroche={cours ? cours.accroche : null}
                        ComposantInputBar={ComposantInputBar}
                        showModules={showModules}
                        setShowModules={setShowModules}
                        prePrompt={prePrompt}
                        ModulesComponent={SeeModules}
                        modulesComponentProps={{ cours, userInfo, moduleActif, setModuleActif, setShowModules, showSources, setShowSources }}
                        showSources={showSources}
                        setShowSources={setShowSources}
                        suggestions={suggestions}
                        firstMessage={cours?.sujet}
                    />
                </div>
            </div>
        </>
    );
};

export default SeeAssistant;
