// Message.js

import React, { forwardRef, useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';
import remarkMath from 'remark-math';
import rehypeMathjax from 'rehype-mathjax';
import { visit } from 'unist-util-visit';
import removeMarkdown from 'remove-markdown'; // Import de remove-markdown
import './Message.css';
import LoadingDots from './LoadingDots';
import { ReactComponent as LeftTail } from './images/leftTail.svg';
import { ReactComponent as RightTail } from './images/rightTail.svg';
import { ReactComponent as ListenButton } from './images/speaker_sm.svg';
import { ReactComponent as CopyButton } from './images/copy_sm.svg';
import { ReactComponent as StopButton } from './images/stop_sm.svg';
import { ReactComponent as CheckButton } from './images/check_sm.svg';

const Message = forwardRef(
  (
    {
      msg,
      thinking,
      setShowSources,
      currentSources,
      setCurrentSources,
      setCurrentNote,
      setShowModules,
      isCurrentlySpeaking, // Nouvelle prop
      setCurrentlySpeakingIndex, // Nouvelle prop
      index, // Nouvelle prop
      isProf
    },
    ref
  ) => {
    const [isCopied, setIsCopied] = useState(false); // État pour la copie
    const [isSpeechSynthesisAvailable, setIsSpeechSynthesisAvailable] = useState(false); // Nouvelle stat
    const utteranceRef = useRef(null);

    useEffect(() => {
      // Vérifie si la synthèse vocale est disponible dans le navigateur
      if ('speechSynthesis' in window) {
        setIsSpeechSynthesisAvailable(true);
      }
    }, []);

    const showSources = (X) => {
      setCurrentNote(X);
      setShowModules(false);
      setCurrentSources(msg.sources);
      setShowSources(true);
      console.log(msg);
      console.log('click', X);
    };

    const handleListenClick = () => {
      if (!msg.content) return;

      if (isCurrentlySpeaking) {
        // Si ce message est déjà en synthèse, l'arrêter
        speechSynthesis.cancel();
        setCurrentlySpeakingIndex(null);
        return;
      }

      // Si une autre synthèse est en cours, l'arrêter
      if (speechSynthesis.speaking || speechSynthesis.pending) {
        speechSynthesis.cancel();
      }

      // Nettoyez le texte pour la synthèse vocale
      const plainText = cleanTextForSpeech(msg.content);

      // Créez une nouvelle instance de SpeechSynthesisUtterance avec le texte nettoyé
      const utterance = new SpeechSynthesisUtterance(plainText);
      utterance.lang = 'fr-FR';
      utteranceRef.current = utterance;

      // Gestion de l'état lorsque la synthèse commence et se termine
      utterance.onstart = () => {
        setCurrentlySpeakingIndex(index);
      };
      utterance.onend = () => {
        setCurrentlySpeakingIndex(null);
        utteranceRef.current = null;
      };
      utterance.onerror = () => {
        setCurrentlySpeakingIndex(null);
        utteranceRef.current = null;
      };

      // Lancez la synthèse
      speechSynthesis.speak(utterance);
    };

    const handleCopyClick = () => {
      if (!msg.content) return;

      // Nettoyez le texte pour la copie
      const plainText = cleanTextForCopy(msg.content);

      navigator.clipboard
        .writeText(plainText)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000); // Réinitialiser après 2 secondes
        })
        .catch((err) => {
          console.error('Erreur lors de la copie :', err);
          // Optionnel : gérer l'erreur en affichant un message à l'utilisateur
        });
    };

    /**
     * Nettoie le texte pour la copie en supprimant les expressions entre 【 et 】 et en supprimant le Markdown.
     * @param {string} markdown - Le texte en Markdown à nettoyer.
     * @returns {string} - Le texte nettoyé.
     */
    const cleanTextForCopy = (markdown) => {
      // Supprime les expressions entre 【 et 】
      const withoutExpressions = markdown.replace(/【[^】]*】/g, '');
      // Utilise remove-markdown pour supprimer le Markdown tout en conservant les sauts de ligne
      const plainText = removeMarkdown(withoutExpressions);
      return plainText.trim();
    };

    /**
     * Nettoie le texte pour la synthèse vocale en supprimant le Markdown et les expressions entre 【 et 】.
     * @param {string} markdown - Le texte en Markdown à nettoyer.
     * @returns {string} - Le texte nettoyé pour la synthèse vocale.
     */
    const cleanTextForSpeech = (markdown) => {
      // Supprime les expressions entre 【 et 】
      const withoutExpressions = markdown.replace(/【[^】]*】/g, '');
      // Utilise remove-markdown pour supprimer le Markdown
      const plainText = removeMarkdown(withoutExpressions);
      return plainText.trim();
    };

    function applyLatexToBracketedText(text) {
      if (!text) return '';

      const accentsMap = {
        'à': '\\`a',
        'â': '\\^a',
        'ä': '\\"a',
        'ç': '\\c{c}',
        'è': '\\`e',
        'é': "\\'e",
        'ê': '\\^e',
        'ë': '\\"e',
        'î': '\\^i',
        'ï': '\\"i',
        'ô': '\\^o',
        'ö': '\\"o',
        'ù': '\\`u',
        'û': '\\^u',
        'ü': '\\"u',
        'ÿ': '\\"y',
        'À': '\\`A',
        'Â': '\\^A',
        'Ä': '\\"A',
        'Ç': '\\c{C}',
        'È': '\\`E',
        'É': "\\'E",
        'Ê': '\\^E',
        'Ë': '\\"E',
        'Î': '\\^I',
        'Ï': '\\"I',
        'Ô': '\\^O',
        'Ö': '\\"O',
        'Ù': '\\`U',
        'Û': '\\^U',
        'Ü': '\\"U',
        'Ÿ': '\\"Y',
      };

      const replaceAccentsWithLatex = (text) =>
        text
          .split('')
          .map((char) => accentsMap[char] || char)
          .join('');

      // Regex to match text between brackets
      const regex = /\[([^\]]+)\]/g;
      let result = '';
      let lastIndex = 0;

      let match;
      while ((match = regex.exec(text)) !== null) {
        const [fullMatch, insideBrackets] = match;

        // Append text before the brackets
        result += text.slice(lastIndex, match.index);

        // Apply latex replacement to the text inside the brackets
        result += `[${replaceAccentsWithLatex(insideBrackets)}]`;

        // Update lastIndex
        lastIndex = regex.lastIndex;
      }

      // Append the rest of the text after the last match
      result += text.slice(lastIndex);

      return result;
    }

    const replaceBracketsWithDollarSigns = (text) => {
      if (!text) return '';

      // Replace \[ and \] with $$
      text = text.replace(/\\\[/g, '$$'); // Use $$ for display math
      text = text.replace(/\\\]/g, '$$');

      // Optionally, replace inline math delimiters \( and \)
      text = text.replace(/\\\(/g, '$'); // Use $ for inline math
      text = text.replace(/\\\)/g, '$');

      return text;
    };

    // Custom Remark plugin to handle special sequences
    const remarkCustomSource = () => (tree) => {
      visit(tree, 'text', (node, index, parent) => {
        const { value } = node;
        const regex = /【\d+:(\d+)†([^】]*)】?/g;
        let lastIndex = 0;
        const newNodes = [];

        while (lastIndex < value.length) {
          regex.lastIndex = lastIndex;
          let match = regex.exec(value);

          if (match && match.index === lastIndex) {
            const [fullMatch, X, source] = match;

            newNodes.push({
              type: 'link',
              url: `#${X}`,
              title: null,
              children: [{ type: 'text', value: (parseInt(X) + 1).toString() }],
              data: {
                hProperties: {
                  className: 'source-link',
                },
              },
            });

            lastIndex += fullMatch.length;
          } else {
            // Move one character forward to re-attempt matching in the next iteration
            newNodes.push({ type: 'text', value: value[lastIndex] });
            lastIndex += 1;
          }
        }

        if (newNodes.length > 0) {
          parent.children.splice(index, 1, ...newNodes);
        }
      });
    };

    const isMessageFinished = !msg.content?.trim().endsWith('▮');

    // Process the content
    const processedContent = replaceBracketsWithDollarSigns(
      applyLatexToBracketedText(msg.content)
    );

    return (
      <div className={`message-container big${msg.role} ${!isProf ? 'big' + msg.role + 'ELEVEONLY' : ''}`}>
        <div ref={ref} className={`message msg${msg.role} ${!isProf ? 'messageELEVEONLY msg' + msg.role + 'ELEVEONLY' : ''}`}>
          <div className={`${msg.role} ${!isProf ? msg.role + 'ELEVEONLY' : ''}`}>
            {msg.role === 'assistant' && (
              <>
                <LeftTail height={13} width={8} className="cochegauche" />
                <div className="nomPhilosophe">{msg.name}</div>
              </>
            )}
            {msg.content?.trim() === '' || msg.content == null ? (
              thinking ? (
                <LoadingDots />
              ) : null
            ) : (
              <>
                <ReactMarkdown
                  remarkPlugins={[remarkDirective, remarkMath, remarkCustomSource]}
                  rehypePlugins={[rehypeMathjax]}
                  components={{
                    a: ({ node, children, ...props }) => {
                      if (props.href.startsWith('#')) {
                        // Source link
                        const X = props.href.slice(1);
                        return (
                          <span
                            className="source-link"
                            onClick={() => showSources(X)}
                            style={{
                              opacity: isMessageFinished ? 1 : 0.3,
                              pointerEvents: isMessageFinished ? 'auto' : 'none',
                            }}
                          >
                            {children}
                          </span>
                        );
                      } else {
                        // Normal web link
                        return (
                          <a
                            className="weblink"
                            href={props.href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children}
                          </a>
                        );
                      }
                    },
                  }}
                >
                  {processedContent}
                </ReactMarkdown>
                {/* Boutons de contrôle */}
                {msg.content && isMessageFinished && msg.role === 'assistant' &&  isSpeechSynthesisAvailable && (
                  <div className={`controlesContainer ${!isProf ? 'controlesContainerELEVEONLY' : ''}`}>
                    <div className={`small-button ${!isProf ? 'small-buttonELEVEONLY' : ''}`} onClick={handleListenClick} aria-label="Écouter le message">
                      {isCurrentlySpeaking ? (
                        <StopButton width={20} height={20} />
                      ) : (
                        <ListenButton width={20} height={20} />
                      )}
                    </div>
                    <div className={`small-button ${!isProf ? 'small-buttonELEVEONLY' : ''}`} onClick={handleCopyClick} aria-label="Copier le message">
                      {isCopied ? (
                        <CheckButton width={20} height={20} />
                      ) : (
                        <CopyButton width={20} height={20} />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {msg.role === 'user' && (
              <RightTail height={13} width={8} className="cochedroite" />
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default React.memo(Message);
