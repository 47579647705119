// MyAccountModal.js
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { ReactComponent as InstructionsIcon } from "../pics/instructionsIcon.svg";
import { ReactComponent as AccueilIcon } from "../pics/accueilIcon.svg";
import { ReactComponent as UserIcon } from "../pics/UserIcon.svg";
import { ReactComponent as PasswordIcon } from "../pics/passwordIcon.svg";
import { serverPHP } from '../consts';



import ModifyForm from './ModifyForm';
import ModifyPassword from './ModifyPassword';

// Définition du nouveau composant OptionItem
const OptionItem = ({ nomMenu, icone: IconComponent, nomAffiche, selectedTab, setSelectedTab }) => (
    <div
        className={`option-item ${selectedTab === nomMenu ? 'selected' : ''}`}
        onClick={() => setSelectedTab(nomMenu)}
    >
        <IconComponent alt={nomAffiche} className="tab-icon" />
        <span>{nomAffiche}</span>
        {selectedTab === nomMenu && <div className="option-underline"></div>}
    </div>
);



const MyAccountModal = ({
    isOpen,
    onClose,
    userInfo,
    setUserInfo,
    matieres
}) => {



    const [selectedTab, setSelectedTab] = useState('mesinfos');
    const modalRef = useRef(null);

    const [nomAffiche, setNomAffiche] = useState(userInfo.nom);
    const [defaultInstructions, setDefaultInstructions] = useState(userInfo.instructions_default);
    const [defaultAccroche, setDefaultAccroche] = useState(userInfo.accroche_default);
    const [selectedMatieres, setSelectedMatieres] = useState(userInfo.matieres);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [showModal, setShowModal] = useState(false);


    const [shake, setShake] = useState(false);



    const [error, setError] = useState(null);


    useEffect(() => {
        if (error !== null) {
            triggerShake();
            setShowModal(true);
        }
    }, [error]);



    const triggerShake = () => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
    };


    const closeAndSave = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleClickOutside = useCallback(
        (event) => {
            // Vérifier si le clic a été fait sur l'overlay de l'erreur ou quelque part dans son contenu
            if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.modal-overlay-login')) {
                closeAndSave();
            }
        },
        [modalRef, closeAndSave]
    );

    // Modifier également handleClickOutsideModal pour éviter qu'il ne ferme le modal principal
    const handleClickOutsideModal = (e) => {
        if (e.target === e.currentTarget) {
            setShowModal(false);
            setError(null);
        }
    };

    //Pour validation

    const handleSubmit = async () => {
        // Validation checks

        if (!nomAffiche) {
            setError('Il est nécessaire de choisir un nom à afficher.');
            return;
        }

        if (nomAffiche.length > 40) {
            setError('Le nom ne doit pas dépasser 40 caractères.');
            return;
        }

        if (userInfo.prof === 1 && selectedMatieres.length === 0) {
            setError('Veuillez sélectionner au moins une matière. Sélectionnez \"Autre\" si la vôtre ne se trouve pas dans la liste, et contactez un administrateur pour qu\'elle soit ajoutée à la liste.');
            return;
        }

        if (oldPassword) { // si l'utilisateur a rentré quelque chose dans le champs oldPassword, c'est qu'il veut changer son mot de passe
            if (!newPassword) {
                setError("Si vous voulez changer de mot de passe, il est nécessaire d'en entrer un nouveau.");
                return;
            }
            if (!confirmNewPassword) {
                setError("Veuillez confirmer votre nouveau mot de passe.");
                return;
            }
            if (newPassword !== confirmNewPassword) {
                setError("Le nouveau mot de passe et la confirmation ne correspondent pas.");
                return;
            }
            if (oldPassword === newPassword) {
                setError("Le nouveau mot de passe doit être différent de l'ancien.");
                return;
            }
        }

        // Prepare data for submission
        const submitData = {
            nom: nomAffiche,
            matieres: userInfo.prof === 1 ? selectedMatieres.map(m => m.id) : [],
            instructions_default: defaultInstructions,
            accroche_default: defaultAccroche,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };

        try {

            let url = serverPHP + '/db/modifyUser.php';

            const response = await fetch(url, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(submitData),
            });

            const data = await response.json();

            if (data.status === 'error') {
                setError(data.message || 'Erreur lors de l\'opération.');
            } else if (data.status === 'success') {
                setUserInfo({
                    ...userInfo,
                    nom: nomAffiche,
                    instructions_default: defaultInstructions,
                    accroche_default: defaultAccroche,
                    matieres: selectedMatieres
                });
                onClose();
                //setInfo('Vos informations ont été mises à jour avec succès.');
            } else {
                setError('Réponse inconnue du serveur.');
            }
        } catch (err) {
            setError('Erreur lors de l\'opération. Veuillez réessayer.');
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, handleClickOutside]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className={`modal-content ${shake ? 'shake' : ''}`} ref={modalRef}>
                {/*<h2 style={{ textAlign: 'center', fontSize: '24px', marginTop: '0' }}>Mon compte</h2>*/}
                <div className="options-menu" style={{ gap: '50px' }}>
                    <OptionItem
                        nomMenu="mesinfos"
                        icone={UserIcon}
                        nomAffiche="Mes infos"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    <OptionItem
                        nomMenu="instructions"
                        icone={InstructionsIcon}
                        nomAffiche="Instructions"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    <OptionItem
                        nomMenu="accueil"
                        icone={AccueilIcon}
                        nomAffiche="Accroche"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    <OptionItem
                        nomMenu="password"
                        icone={PasswordIcon}
                        nomAffiche="Mot de passe"
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                </div>

                <div className="modal-content-area" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {selectedTab === 'mesinfos' ? (

                        <ModifyForm
                            userInfo={userInfo}
                            nomAffiche={nomAffiche}
                            setNomAffiche={setNomAffiche}
                            matieres={matieres}
                            selectedMatieres={selectedMatieres}
                            setSelectedMatieres={setSelectedMatieres}
                            setShake={setShake}
                        />

                    ) : (selectedTab === 'instructions') ?
                        <>
                            <textarea
                                value={defaultInstructions || ''}
                                onChange={(e) => setDefaultInstructions(e.target.value)}
                                className="modal-textarea"
                                placeholder="Saisissez les instructions par défaut ici"
                            />
                            <div className="infos">
                                Ces <strong>instructions par défaut</strong> seront attribuées automatiquement à chaque nouveau chatbot. Vous pouvez évidemment attribuer des instructions spécifiques pour chacun de vos chatbots, grâce au menu "<strong>Paramètres</strong>".
                            </div>
                        </>
                        : (selectedTab === 'accueil') ?
                            <>
                                <textarea
                                    value={defaultAccroche || ''}
                                    onChange={(e) => setDefaultAccroche(e.target.value)}
                                    className="modal-textarea"
                                    placeholder="Saisissez le message d'accueil par défaut ici"
                                    style={{ height: '100px' }}
                                />
                                <div className="infos">
                                    Cette <strong>accroche par défaut</strong> est le premier message qui, pour chaque nouveau chatbot, sera affiché à l'utilisateur
                                    quand il commencera une conversation lui. Une fois qu'il est créé, vous pouvez évidemment choisir un message d'accueil personnalisé pour votre chatbot, grâce au menu "<strong>Paramètres</strong>".<br />Vous pouvez utiliser la <strong>syntaxe MarkDown</strong> pour votre message : écrivez *ainsi* pour l'italique, et **ainsi** pour le gras. Vous pouvez laisser ce champ
                                    vide si vous ne voulez pas de message d'accueil.
                                </div>
                            </>

                            : (selectedTab === 'password') ?
                                <ModifyPassword
                                    oldPassword={oldPassword}
                                    setOldPassword={setOldPassword}
                                    newPassword={newPassword}
                                    setNewPassword={setNewPassword}
                                    confirmNewPassword={confirmNewPassword}
                                    setConfirmNewPassword={setConfirmNewPassword}
                                />
                                :
                                <></>
                    }
                </div>
                <div className="button-container">
                    <button className="login-button" onClick={handleSubmit}>Modifier</button>
                    <button className="signup-button" onClick={() => onClose()}>
                        Retour
                    </button>
                </div>
            </div>
            {showModal && (
                <div className="modal-overlay-login" onClick={handleClickOutsideModal}>
                    <div className="modal-content-login">
                        {error && <p>{error}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyAccountModal;
