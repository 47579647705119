import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { serverPHP } from "../consts";
import SetAssistant from "./SetAssistant";
import ListeMenuProfs from "./ListeMenuProf";
import { useNavigate } from 'react-router';

const MainMenuProf = ({ userInfo, setUserInfo, matieres, chatbot_id }) => {
    const [groupes, setGroupes] = useState([]);
    const [assistants, setAssistants] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();
    const [showModules, setShowModules] = useState(false);
    const [jobsInProgress, setJobsInProgress] = useState([]);

    const [showAutomaticMenu, setShowAutomaticMenu] = useState(false);


    // Utilisation de useRef pour stocker jobsInProgress
    const jobsInProgressRef = useRef([]);

    const cours = useMemo(() => assistants.find(a => a.id === chatbot_id), [assistants, chatbot_id]);

    const formatDateForDB = useCallback((dateInput) => {
        const date = dateInput instanceof Date ? dateInput : new Date(dateInput);
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateInput);
            return null;
        }
        const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return offsetDate.toISOString().split("T")[0];
    }, []);

    const updateAssistant = useCallback((newCours) => {
        console.log("On passe par updateAssistant");
        console.log(newCours);

        const updatedCours = typeof newCours === 'function' ? newCours(cours) : newCours;
        setCours(updatedCours);

        const dataToSend = {
            ...updatedCours,
            date: formatDateForDB(updatedCours.date),
        };

        fetch(serverPHP + "/db/updateAssistant.php", {
            method: "POST",
            credentials:'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataToSend),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(data => {
                    console.error("Erreur lors de la mise à jour :", data.error || "Erreur inconnue");
                });
            }
        })
        .catch(error => {
            console.error("Erreur lors de la mise à jour de l'assistant :", error);
        });
    }, [cours, formatDateForDB]);

    // Mise à jour de jobsInProgressRef lorsque jobsInProgress change
    useEffect(() => {
        jobsInProgressRef.current = jobsInProgress;
    }, [jobsInProgress]);

    const checkJobsStatus = useCallback(async () => {
        const currentJobs = jobsInProgressRef.current;
        if (currentJobs.length === 0) return;
    
        const jobIds = currentJobs.map(job => job.jobId);
    
        try {
            const response = await fetch(`${serverPHP}/db/moduleAccessibilite_checkStatus.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    job_ids: jobIds
                })
            });
            const data = await response.json();
    
            if (data) {
                const updatedJobs = [];
                for (const jobId in data) {
                    const jobResponse = data[jobId];
                    const job = currentJobs.find(job => job.jobId === jobId);
    
                    if (jobResponse.success) {
                        const status = jobResponse.status;
                        if (status === 'completed') {
                            setAssistants(prevAssistants =>
                                prevAssistants.map(assistant => {
                                    const updatedAccessibilite = assistant.modules.accessibilite.map(file => {
                                        console.log("jobId",jobId, "job.uniqueId", job.uniqueId, "file", file)
                                        if (file.jobId === jobId) {
                                            return {
                                                ...file,
                                                statut: 2, // Completed
                                                filesize: jobResponse.filesize || null,
                                                filename: jobResponse.filename || file.filename,
                                            };
                                        }
                                        return file;
                                    });
                                    return {
                                        ...assistant,
                                        modules: {
                                            ...assistant.modules,
                                            accessibilite: updatedAccessibilite,
                                        },
                                    };
                                })
                            );
                        } else if (status === 'error') {
                            let fileExists = false;
                            setAssistants(prevAssistants =>
                                prevAssistants.map(assistant => {
                                    const updatedAccessibilite = assistant.modules.accessibilite.map(file => {
                                        if (file.jobId === jobId) {
                                            fileExists = true;
                                            return {
                                                ...file,
                                                statut: 0, // Erreur
                                            };
                                        }
                                        return file;
                                    });
                                    return {
                                        ...assistant,
                                        modules: {
                                            ...assistant.modules,
                                            accessibilite: updatedAccessibilite,
                                        },
                                    };
                                })
                            );
    
                            if (!fileExists) {
                                console.log(`Job ${jobId} ignoré car il ne correspond à aucun fichier existant.`);
                            } else {
                                console.error(`Job ${jobId} mis à jour avec le statut "erreur".`);
                            }
                        } else {
                            // Job is still in progress
                            updatedJobs.push(job);
                        }
                    } else {
                        // success is false, treat it like an error
                        let fileExists = false;
                        setAssistants(prevAssistants =>
                            prevAssistants.map(assistant => {
                                const updatedAccessibilite = assistant.modules.accessibilite.map(file => {
                                    if (file.jobId === jobId) {
                                        fileExists = true;
                                        return {
                                            ...file,
                                            statut: 0, // Erreur
                                        };
                                    }
                                    return file;
                                });
                                return {
                                    ...assistant,
                                    modules: {
                                        ...assistant.modules,
                                        accessibilite: updatedAccessibilite,
                                    },
                                };
                            })
                        );
    
                        if (!fileExists) {
                            console.log(`Job ${jobId} ignoré car il ne correspond à aucun fichier existant (success:false).`);
                        } else {
                            console.error(`Job ${jobId} mis à jour avec le statut "erreur" (success:false).`);
                        }
                    }
                }
                setJobsInProgress(updatedJobs);
            }
        } catch (error) {
            console.error('Erreur lors de la vérification des statuts des jobs:', error);
        }
    }, [setAssistants]);
    
    
    

    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(serverPHP + "/db/getDataProf.php", {
                method: "POST",
                credentials: 'include',
                headers: { "Content-Type": "application/json" }
            });
            const data = await response.json();
            if (data.error) throw new Error(data.error);
    
            const formattedAssistants = data.assistants.map(assistant => ({
                ...assistant,
                date: new Date(assistant.date),
            }));
    
            setAssistants(formattedAssistants);
    
            const formattedGroupes = data.groupes.map(groupe => {
                const dates = groupe.associations_groupe_cours
                    .map(assoc => new Date(assoc.date))
                    .filter(date => !isNaN(date.getTime()));
                const latestDate = dates.length > 0 ? new Date(Math.max(...dates.map(date => date.getTime()))) : null;
    
                return {
                    ...groupe,
                    latestAssociationDate: latestDate,
                    nombreChatbots: groupe.associations_groupe_cours.length,
                    nombreEleves: groupe.inscriptions_eleves_groupes.length,
                };
            });
    
            setGroupes(formattedGroupes);
            setLoaded(true);
    
            // Mettre à jour les jobs en cours
            const jobsFromServer = data.jobsInProgress || [];
    
            // Normaliser les jobs pour utiliser jobId
            const normalizedJobs = jobsFromServer.map(job => ({
                ...job,
                jobId: job.job_id,
                outputFilename: job.output_filename,
                cours_id: job.cours_id, // Ajout de cours_id
            }));
    
            // Mettre à jour les assistants
            setAssistants(prevAssistants => {
                let updatedAssistants = [...prevAssistants];
    
                normalizedJobs.forEach(job => {
                    updatedAssistants = updatedAssistants.map(assistant => {
                        if (assistant.id === job.cours_id) {
                            // Vérifier si le fichier existe déjà dans accessibilite
                            const fileExists = assistant.modules.accessibilite.some(file => file.jobId === job.jobId);
    
                            if (!fileExists) {
                                // Créer un ID unique pour le fichier
                                const uniqueId = `access_${Date.now()}_${Math.random().toString(36).substring(2, 10)}`;
    
                                console.log("création de newFile");
                                // Ajouter le fichier à accessibilite avec statut = 1
                                const newFile = {
                                    id: uniqueId,
                                    jobId: job.jobId,
                                    filename: job.outputFilename + '.html',
                                    output_filename: job.outputFilename + '.html',
                                    filesize: null,
                                    statut: 1,
                                };
    
                                return {
                                    ...assistant,
                                    modules: {
                                        ...assistant.modules,
                                        accessibilite: [...(assistant.modules.accessibilite || []), newFile],
                                    },
                                };
                            }
                        }
                        return assistant;
                    });
                });
    
                return updatedAssistants;
            });
    
            // Mettre à jour jobsInProgress avec les jobs normalisés
            setJobsInProgress(normalizedJobs);
    
            // Vérifier immédiatement le statut des jobs en cours
            if (normalizedJobs.length > 0) {
                checkJobsStatus();
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    }, [checkJobsStatus]);
    

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [refresh, fetchData]);

    const setCours = useCallback((update) => {
        setAssistants(prevAssistants =>
            prevAssistants.map(assistant =>
                assistant.id === chatbot_id ? (typeof update === 'function' ? update(assistant) : { ...assistant, ...update }) : assistant
            )
        );
    }, [chatbot_id]);

    // Utilisation de useRef pour gérer l'intervalle
    const intervalIdRef = useRef(null);

    useEffect(() => {
        if (jobsInProgress.length > 0 && !intervalIdRef.current) {
            checkJobsStatus(); // Vérification immédiate
            intervalIdRef.current = setInterval(() => {
                checkJobsStatus();
            }, 10000); // Toutes les 10 secondes
        } else if (jobsInProgress.length === 0 && intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }

        // Nettoyage lors du démontage du composant
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
        };
    }, [jobsInProgress.length, checkJobsStatus]);

    return (
        <div className="container">
            {(cours == null || cours?.id == null) ? (
                <ListeMenuProfs
                    userInfo={userInfo}
                    setCours={setCours}
                    setUserInfo={setUserInfo}
                    groupes={groupes}
                    setGroupes={setGroupes}
                    assistants={assistants}
                    setRefresh={setRefresh}
                    loaded={loaded}
                    setAssistants={setAssistants}
                    matieres={matieres}
                    showModules={showModules}
                    setShowModules={setShowModules}
                    setShowAutomaticMenu={setShowAutomaticMenu}
                />
            ) : (
                <SetAssistant
                    cours={cours}
                    setCours={setCours}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    updateAssistant={updateAssistant}
                    suggestions={cours.suggestions}
                    setJobsInProgress={setJobsInProgress}
                    showAutomaticMenu={showAutomaticMenu}
                    setShowAutomaticMenu={setShowAutomaticMenu}
                />
            )}
        </div>
    );
};

export default MainMenuProf;
