// ModuleAudio_parametres.js

import React, { useState, useEffect } from 'react';
import './ModuleAudio.css';
import { formatSpeechTime, serverPHP } from '../../consts';
import { ReactComponent as ChevronDroite } from "../../chatbot/images/chevron_droite.svg";
import LoadingCircle from '../LoadingCircle';
import Modal from './Modal'; // Import the Modal component

const ModuleAudio_parametres = ({
  contenuFichier,
  setContenuFichier,
  setFichierFromSources,
  setTitreMenu,
  cours,
  setCours,
  setCurrentAudio,
}) => {

  const AudioPrompts = [
    {
      label: "Garder tel quel",
      prompt: ""
    },
    {
      label: "Podcast",
      prompt: "Je voudrais que tu me reformules ce texte sous la forme d'un podcast, en retranscrivant fidèlement les idées les plus importantes. Le ton doit être enthousiaste, passionné, assez jeune, parfois humoristique, mais sans sacrifier la rigueur des idées. Donne exclusivement les paroles prononcées par le podcasteur, et rien d'autre. Ne donne surtout aucune indication scénique !"
    },
    {
      label: "Cours",
      prompt: "Reformule ce texte sous la forme d'un cours magistral, en en rendant parfaitement claires les différentes étapes : dis si tu définis, si tu problématises, si tu commences une nouvelle partie (auquel cas il faut clarifier l'idée principale), etc. Dans ton développement, évite les formulations du type : \"nous dirons que...\" : il faut présenter les idées au présent."
    },
    {
      label: "Tout rédiger",
      prompt: "Tu pourras trouver dans ce texte des abbréviations, des phrases incomplètes, des titres, etc. Il faut réécrire de sorte que tout soit correctement rédigé."
    },
    {
      label: "Résumé",
      prompt: "Résume ce texte de façon synthétique, mais en prenant le temps de bien restituer les informations les plus importantes. Donne exclusivement ton résumé, sans aucun autre commentaire."
    },
    {
      label: "Traduire",
      prompt: "Traduis ce texte en anglais, aussi fidèlement que possible. Donne exclusivement ta traduction, sans aucun autre commentaire."
    },
    {
      label: "Personnaliser",
      prompt: "(Entrez ici votre instruction personnalisée)"
    }
  ];

  const [prompt, setPrompt] = useState(AudioPrompts[0].prompt);
  const [nomFichier, setNomFichier] = useState("Audio 1");
  const [contenuFichierTransforme, setContenuFichierTransforme] = useState(null);

  const [selectedOption, setSelectedOption] = useState(0);

  const [loadingExecInstructions, setLoadingExecInstructions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTransformOpen, setIsModalTransformOpen] = useState(false);
  const [isTransformedTextUsed, setIsTransformedTextUsed] = useState(false);
  const [isTransformedTextAvailable, setIsTransformedTextAvailable] = useState(false);
  const [isTooLong, setIsTooLong] = useState(false);

  const LongueurMax = 4096;

  useEffect(() => {
    setIsTransformedTextUsed(isTransformedTextAvailable && selectedOption !== 0);
  }, [isTransformedTextAvailable, selectedOption]);

  useEffect(() => {
    setIsTransformedTextAvailable(contenuFichierTransforme && !loadingExecInstructions);
  }, [contenuFichierTransforme, loadingExecInstructions]);

  useEffect(() => {
    const length = isTransformedTextUsed ? contenuFichierTransforme.length : contenuFichier.length;
    setAudioDuration(formatSpeechTime(length));
    setIsTooLong(length > LongueurMax);
  }, [isTransformedTextUsed, contenuFichierTransforme, contenuFichier]);

  useEffect(() => {
    if (selectedOption === 0) setPrompt("");
  }, [selectedOption]);

  useEffect(() => {
    setTitreMenu("2. Paramètres");
  }, [setTitreMenu]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = (updatedContent) => {
    setContenuFichier(updatedContent);
    setIsModalOpen(false);
  };

  const openModalTransforme = () => setIsModalTransformOpen(true);
  const closeModalTransforme = (updatedContent) => {
    setContenuFichierTransforme(updatedContent);
    setIsModalTransformOpen(false);
  };

  const handleGenerateAudio = () => {
    if (isTooLong) return;

    if (!nomFichier.trim()) {
      setNomFichier("Audio 1");
    }

    setLoading(true);
    fetch(`${serverPHP}/moduleAudio_createAudio.php`, {
      method: 'POST',
      credentials:'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        filename: nomFichier,
        prompt: isTransformedTextUsed ? contenuFichierTransforme : contenuFichier,
        coursId: cours.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.filename && data.filesize) {
          // Ajoute le nouvel audio avec les détails requis
          setCours(prevCours => ({
            ...prevCours,
            modules: {
              ...prevCours.modules,
              audio: [
                ...(prevCours.modules.audio || []),
                {
                  filename: data.filename,
                  filesize: data.filesize
                }
              ]
            }
          }));
          setCurrentAudio({
            filename: data.filename,
            filesize: data.filesize
          });
          setContenuFichier(null);
          setFichierFromSources(null);
        } else {
          console.error('Invalid response data:', data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  const handleTransform = () => {
    setLoadingExecInstructions(true);

    fetch(`${serverPHP}/moduleAudio_handleCreation.php`, {
      method: 'POST',
      credentials:'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        prompt: prompt
        + "\nAttention, ce texte est destiné à être lu. Il faut donc que tout soit rédigé en toutes lettres ; par exemple, il faut que \"3\" soit remplacé par \"trois\", que \"Ve s. av. J.-C.\" soit remplacé par \"cinquième siècle avant notre ère\", etc.",
        contenuFichier: contenuFichier,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setContenuFichierTransforme(data.content);
        setLoadingExecInstructions(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoadingExecInstructions(false);
      });
  };

  const handleNomFichierBlur = () => {
    if (!nomFichier.trim()) {
      setNomFichier("Audio 1");
    }
  };

  const handlePromptSelection = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    setSelectedOption(selectedIndex);
    setPrompt(AudioPrompts[selectedIndex].prompt);
  };

  return (
    <>
      <div className="moduleContainer" style={{ position: 'relative', height: selectedOption === 0 ? '443px' : '645px' }}>
        {loading ? (
          <>
            <LoadingCircle taille={150} />
            <div className="generationQCMEnCours">
              L'audio est en train d'être généré.
              <br /><span style={{ fontSize: '14px' }}>Cette opération peut prendre une ou deux minutes.</span>
            </div>
          </>
        ) : (
          !loadingExecInstructions ? (
            <>
              <div className="moduleTitre">Choisissez les <strong>paramètres</strong></div>
              <div className="login-form small" style={{ marginLeft: 0 }}>
                <div className="input-group">
                  <label className="login-label parametresQCM">Nom de l'audio :</label>
                  <input
                    type="text"
                    value={nomFichier}
                    onChange={(e) => setNomFichier(e.target.value)}
                    onBlur={handleNomFichierBlur}
                    className="login-input"
                    style={{ width: '150px' }}
                  />
                </div>
                <div className="input-group">
                  <label className="login-label parametresQCM">Texte chargé :</label>
                  <button onClick={openModal} className="login-button boutonQCM" style={{ margin: 0, maxWidth: '150px' }}>Modifier</button>
                </div>
                <div className="styleInstructionsAudio">
                  <div className="input-group">
                    <label className="login-label parametresQCM">Transformation du texte :</label>
                    <select
                      value={selectedOption}
                      onChange={handlePromptSelection}
                      style={{ width: '100%', maxWidth: '150px' }}
                    >
                      {AudioPrompts.map((item, index) => (
                        <option key={index} value={index}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedOption !== 0 && (
                    <>
                      <textarea
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        className="modal-textarea textarea"
                        style={{ marginBottom: '15px' }}
                      />
                      <div className="divBoutons">
                        <button onClick={handleTransform} className="login-button boutonQCM" style={{ margin: 0, maxWidth: '150px' }}>Transformer</button>
                        <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
                          <ChevronDroite className="chevronTransforme" />
                        </div>
                        <button
                          onClick={isTransformedTextAvailable ? openModalTransforme : null}
                          className={`login-button boutonQCM ${!isTransformedTextAvailable ? 'disabledButton' : ''}`}
                          disabled={!isTransformedTextAvailable}
                          style={{ margin: 0, maxWidth: '150px' }}
                        >
                          {loadingExecInstructions ? 'En cours...' : 'Voir le résultat'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="divEnBas">
                <button
                  className={`login-button boutonGenererAudio ${isTooLong ? "disabled" : ""}`}
                  onClick={handleGenerateAudio}
                >
                  Générer l'audio
                </button>
                <div className="note" style={{ color: isTooLong ? "red" : 'inherit' }}>
                  <span style={{ textDecoration: 'underline' }}>Note :</span>&nbsp;
                  {isTooLong ? (
                    <>le {isTransformedTextUsed ? <strong>texte transformé</strong> : <strong>texte chargé</strong>} fait {isTransformedTextUsed ? contenuFichierTransforme.length : contenuFichier.length} caractères, et dépasse donc la limite de <strong>4096 caractères</strong>. Veuillez le raccourcir en le tronquant ou en le transformant.</>
                  ) : (
                    <>étant donné la taille du&nbsp;
                      {isTransformedTextUsed ? <strong>texte transformé</strong> : <strong>texte chargé</strong>}, l'audio généré durera approximativement <strong>{audioDuration}</strong>.
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <LoadingCircle taille={150} />
              <div className="generationQCMEnCours">
                Transformation du texte en cours...
                <br /><span style={{ fontSize: '14px' }}>Selon la taille de votre texte et vos paramètres, cette opération peut être longue</span>
              </div>
            </>
          )
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        initialContent={contenuFichier}
      />
      <Modal
        isOpen={isModalTransformOpen}
        onClose={closeModalTransforme}
        initialContent={contenuFichierTransforme}
      />
    </>
  );
};

export default ModuleAudio_parametres;
