import React from 'react';
import { ReactComponent as PicsPlus } from "../../pics/plus.svg";
import { serverPHP } from "../../consts";
import FileList from './FileList';
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";


import './Modules.css';



const ModulePartage = ({ cours, setCours }) => {
    const handleCheckboxChange = (index, selectAll = null) => {
        const updatedFiles = cours.files.map((file, i) => {
            if (selectAll !== null) {
                return { ...file, is_shared: selectAll ?? false };
            }
            if (i === index) {
                return { ...file, is_shared: !file.is_shared };
            }
            return file;
        });
    
        // Mettre à jour files dans cours
        setCours(prevCours => ({
            ...prevCours,
            files: updatedFiles
        }));
    
        // Créer le tableau des fichiers partagés
        const sharedFiles = updatedFiles
            .filter(file => file.is_shared)
            .map(file => file.filename);
    
        // Envoyer la requête POST avec les fichiers partagés
        fetch(`${serverPHP}/modules/partage/modifySharedFiles.php`, {
            method: "POST",
            credentials:'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                cours_id: cours.id,
                updatedFiles: sharedFiles
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour des fichiers partagés");
            }
            return response.json();
        })
        .then(data => {
            console.log("Fichiers partagés mis à jour avec succès:", data);
        })
        .catch(error => {
            console.error("Erreur:", error);
        });
    };

    const handleAddFile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("file", file);
        formData.append("assistant_id", cours.id);

        try {
            const response = await fetch(`${serverPHP}/modules/partage/addAdditionalFile.php`, {
                method: "POST",
                credentials:'include',
                body: formData,
            });

            if (!response.ok) {
                console.error("Erreur lors de l'ajout du fichier");
                return;
            }

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                const updatedFiles = await response.json();
                setCours(prevCours => ({
                    ...prevCours,
                    modules: {
                        ...prevCours.modules,
                        partage: updatedFiles
                    }
                }));
            } else {
                console.error("La réponse n'est pas en JSON. Type de contenu reçu :", contentType);
            }
        } catch (error) {
            console.error("Erreur de requête :", error);
        }
    };

    const handleDeleteFile = async (filename) => {
        try {
            const response = await fetch(`${serverPHP}/modules/partage/delAdditionalFile.php`, {
                method: "POST",
                credentials:'include',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    filename: filename,
                    assistant_id: cours.id
                })
            });

            if (!response.ok) {
                console.error("Erreur lors de la suppression du fichier");
                return;
            }

            // Mettre à jour l'état `cours` en supprimant le fichier
            setCours(prevCours => ({
                ...prevCours,
                modules: {
                    ...prevCours.modules,
                    partage: prevCours.modules.partage.filter(file => file.filename !== filename)
                }
            }));

            console.log("Fichier supprimé avec succès:", filename);
        } catch (error) {
            console.error("Erreur de requête :", error);
        }
    };

    return (
        <div className="moduleMain">
            <div className="moduleContainer">
                <div className="moduleTitre">Partager des <strong>fichiers sources</strong></div>
                <FileList
                    listeFichiers={cours.files.filter((d) => (d.statut == 2))}
                    isSource={true}
                    onCheckboxChange={handleCheckboxChange}
                    image={<></>}
                    hoverAll={true}
                />
            </div>
            <div className="moduleContainer">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="moduleTitre">Partager des <strong>fichiers additionnels</strong></div>
                    <div>
                        <label htmlFor="file-upload" className="add-file-btn" style={{ display: 'flex', cursor: 'pointer' }}>
                            <PicsPlus height={20} width={20} alt="Ajouter" />
                        </label>
                        <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleAddFile}
                        />
                    </div>
                </div>
                <FileList
                    listeFichiers={cours.modules.partage || []}
                    isSource={false}
                    image={<PicsDelete/>}
                    onDeleteFile={handleDeleteFile}
                    opacity={0.3}
                    hoverOnlyOnButton={true}
                />
            </div>
        </div>
    );
};

export default ModulePartage;