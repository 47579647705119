import { ReactComponent as FileShare } from "../pics/fileshare.svg"; // Icon for module
import { ReactComponent as QCM } from "../pics/qcm.svg";
import { ReactComponent as SumUp } from "../pics/sumup.svg";
import { ReactComponent as Speaker } from "../pics/speaker.svg";
import { ReactComponent as MindMap } from "../pics/mindmap.svg";
import { ReactComponent as Accessibilite } from "../pics/accessibilite.svg";

export const serverPHP = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.6" || window.location.hostname === "192.168.1.40" || window.location.hostname === "edubot.test")
? 'https://edubot.test:443'
: 'https://edubot.vmirebeau.fr/serveur';
export const serverMain = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.6"  || window.location.hostname === "192.168.1.40" || window.location.hostname === "edubot.test")
? 'https://edubot.test:3000'
: 'https://edubot.vmirebeau.fr';
export const debug_mode = window.location.hostname === 'localhost'
? true
: false;

export const langues = [
    "français",
    "anglais",
    "espagnol",
    "allemand",
    "mandarin",
    "portugais",
    "italien",
    "polonais",
    "arabe",
    "berbère",
    "russe",
    "néerlandais",
    "roumain",
    "lingala",
    "yiddish",
    "soninké",
    "hindi",
    "bengali",
    "japonais",
    "bulgare",
    "croate",
    "danois",
    "estonien",
    "finnois",
    "grec",
    "hongrois",
    "irlandais",
    "letton",
    "lituanien",
    "maltais",
    "slovaque",
    "slovène",
    "suédois",
    "tchèque",
    "turc",
    "tamoul",
    "cantonnais",
    "vietnamien",
    "pendjabi",
    "ourdou",
    "indonésien",
    "serbo-croate",
    "kurmandji",
    "arménien",
    "peul",
    "pidgin",
    "marathi",
    "télougou",
    "créole",
    "alsacien",
    "occitan",
    "breton",
    "haïtien",
    "afrikaans",
    "hébreu",
    "persan",
    "dari",
    "pachto"
];

export const formatFileSize = (sizeInBytes) => {
    if (!sizeInBytes) return { size: 'Inconnue', unit: '' };
    let size = sizeInBytes;
    let unit = 'octets';
    if (sizeInBytes >= 1024 && sizeInBytes < 1024 * 1024) {
      size = sizeInBytes / 1024;
      unit = 'Ko';
    } else if (sizeInBytes >= 1024 * 1024) {
      size = sizeInBytes / (1024 * 1024);
      unit = 'Mo';
    }
    size = Math.round(size * 10) / 10; // Round to one decimal place
    return { size, unit };
  };

  export const formatSpeechTime = (charCount) => {
    if (!charCount) return 'Temps inconnu';

    // Coefficients issus de l'analyse des données
    const ratePerChar = 0.0608; // Slope from regression
    const intercept = -0.4895; // Intercept from regression

    // Calcul du temps en secondes
    let timeInSeconds = charCount * ratePerChar + intercept;
    if (timeInSeconds < 0) timeInSeconds = 0; // Éviter les valeurs négatives

    // Calcul des heures, minutes et secondes
    const hours = Math.floor(timeInSeconds / 3600);
    timeInSeconds %= 3600;
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.round(timeInSeconds % 60);

    // Construire la chaîne de résultat
    let result = '';
    if (hours > 0) result += `${hours} heure${hours > 1 ? 's' : ''}`;
    if (minutes > 0) {
        result += (result ? ' et ' : '') + `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    if (seconds > 0 || result === '') {
        result += (result ? ' et ' : '') + `${seconds} seconde${seconds > 1 ? 's' : ''}`;
    }

    return result || 'moins d\'une seconde';
};




export const modules_full = (cours) => {

    // Variables pour afficher le module "Partage de fichiers"
    const nb_sources = (cours.files || []).filter((file) => file.is_shared).length;
    const nb_additionnels = (cours.modules?.partage || []).length;
    const total_shared_files = nb_sources + nb_additionnels;

    const nb_qcm = (cours.modules?.qcm || []).length;

    const nb_cartementale = (cours.modules?.cartementale || []).length;

    const nb_audio = (cours.modules?.audio || []).length;

    const nb_accessibilite = (cours.modules?.accessibilite || []).length;

    const nb_accessibilite_ready = (cours.modules?.accessibilite.filter((f) => f.statut == 2) || []).length;

    
return [
  {
    id: "file-sharing",
    isVisible: total_shared_files > 0,
    icon: <FileShare/>,
    title: "Partage de fichiers",
    statut: `${total_shared_files} fichier${total_shared_files > 1 ? 's' : ''} partagé${total_shared_files > 1 ? 's' : ''}`,
    description:"Mettez à la disposition de vos élèves les fichiers utilisés comme sources, ou d'autres encore.",
    description_eleve:'Votre enseignant·e a partagé avec vous certains fichiers, que vous pouvez télécharger'
  },
  {
    id: "qcm",
    isVisible: nb_qcm > 0,
    icon: <QCM />,
    title: "QCM",
    statut: `${nb_qcm} questions`,
    description:"A partir de vos sources, générez automatiquement des QCM et intégrez-les à votre chatbot.",
    description_eleve:"Entraînez-vous sur ces questions à choix multiples pour tester votre connaissance du cours"
  },
  {
    id: "mindmap",
    isVisible: nb_cartementale > 0,
    icon: <MindMap />,
    title: "Carte mentale",
    statut: `${nb_cartementale} ${nb_cartementale > 1 ? "cartes mentales" : "carte mentale"}`,
    description:"Générez automatiquement une carte mentale à partir d'un texte.",
    description_eleve:"Grâce aux cartes mentales, révisez rapidement les informations les plus importantes"
  },
  {
    id: "speaker",
    isVisible: nb_audio > 0,
    icon: <Speaker />,
    title: "Audios",
    statut: `${nb_audio} ${nb_audio > 1 ? "audios" : "audio"}`,
    description:"Convertissez vos textes en audio pour que vos élèves puissent les écouter.",
    description_eleve:"Des enregistrements au format MP3 ont été mis à votre disposition"
  },
  {
    id: "accessibilite",
    isVisible: nb_accessibilite > 0,
    icon: <Accessibilite />,
    title: "Accessibilité",
    statut: `${nb_accessibilite_ready} ${nb_accessibilite_ready > 1 ? "documents" : "document"}`,
    description:"Mettez automatiquement vos documents sous une forme qui respecte les critères de l'accessibilité.",
    description_eleve:"Des documents clairs et facilement lisibles."
  }
]
}

export const getFilenameWithoutExtension = (filename) => {
  if (!filename) return '';
  
  // Trouver l'index du dernier point
  const lastDotIndex = filename.lastIndexOf('.');
  
  // Si aucun point n'est trouvé, retourner le nom avec les underscores remplacés
  if (lastDotIndex === -1) {
      return filename.replace(/_/g, ' ');
  }
  
  // Extraire le nom sans l'extension et remplacer les underscores par des espaces
  const nameWithoutExtension = filename.substring(0, lastDotIndex);
  return nameWithoutExtension.replace(/_/g, ' ');
};