// AddSourceModal.js
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './AddSourceModal.css';
import { serverPHP } from '../consts';
import { ReactComponent as PicsFile } from "../pics/file.svg";
import { ReactComponent as PicsWeb } from "../pics/web.svg";
import { ReactComponent as PicsCopy } from "../pics/copy.svg";
import { ReactComponent as PicsImport_URL } from "../pics/import_URL.svg";
import { formatFileSize } from '../consts';

import { ReactComponent as PicsDelete_File } from "../pics/delete_file.svg";
import { processFile, checkFileNameValidity, MAX_FILE_SIZE, acceptedExtensions, conditionallyAcceptedExtensions, mimeTypeToExtension } from './fileUtils';


const AddSourceModal = ({ isOpen, onClose, onAddSource, cours, wide = false, showNotes = true, noPadding=false }) => {
  const [selectedOption, setSelectedOption] = useState('file');
  const [error, setError] = useState(null);
  const [duplicateNameError, setDuplicateNameError] = useState(null);
  const [duplicateNoteNameError, setDuplicateNoteNameError] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [urlInput, setUrlInput] = useState('');
  const [nomNote, setNomNote] = useState('');
  const [textInput, setTextInput] = useState('');
  const [fileInfo, setFileInfo] = useState(null);
  const [isValidExtension, setIsValidExtension] = useState(true);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const modalRef = useRef(null);
  const fileInputRef = useRef(null);

  const resetModalState = useCallback(() => {
    setFileData(null);
    setUrlInput('');
    setTextInput('');
    setNomNote('');
    setFileInfo(null);
    setError(null);
    setIsDragOver(false);
    setIsHover(false);
    setIsValidExtension(true);
    setDuplicateNameError(null);
    setDuplicateNoteNameError(null);
    
    // Réinitialise la valeur de l'input fichier
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, []);

  const handleOptionClick = useCallback((option) => {
    setSelectedOption(option);
    resetModalState();
  }, [resetModalState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
        resetModalState();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose, resetModalState]);

  const handleFileChange = useCallback(async (event) => {
    event.stopPropagation(); // Prevent propagation

    setError(null);
    const file = event.target.files?.[0];
    if (file) {
      const result = await processFile(file, cours.files, wide, noPadding);
      if (result.error) {
        setError(result.error);
        setFileData(null);
        setFileInfo(null);
        setIsValidExtension(true);
      } else {
        setFileData(result.fileData);
        setFileInfo(result.fileInfo);
        setIsValidExtension(result.isValidExtension);
        // Check file name validity
        checkFileNameValidity(
          result.fileInfo.name,
          result.fileInfo.extension,
          cours.files,
          setDuplicateNameError,
          setIsValidExtension,
          wide,
          noPadding
        );
      }
    }
  }, [cours.files, wide]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  }, []);

  const onDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  }, []);

  const onDrop = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    setError(null);
    const file = event.dataTransfer.files[0];
    if (file) {
      const result = await processFile(file, cours.files, wide, noPadding);
      if (result.error) {
        setError(result.error);
        setFileData(null);
        setFileInfo(null);
        setIsValidExtension(true);
      } else {
        setFileData(result.fileData);
        setFileInfo(result.fileInfo);
        setIsValidExtension(result.isValidExtension);
        // Check file name validity
        checkFileNameValidity(
          result.fileInfo.name,
          result.fileInfo.extension,
          cours.files,
          setDuplicateNameError,
          setIsValidExtension,
          wide,
          noPadding
        );
      }
    }
  }, [cours.files, wide]);
  

  const handleNomNoteChange = useCallback((newName) => {
    setNomNote(newName);
    if (!newName.trim()) {
      setDuplicateNoteNameError("Le nom de la note ne peut pas être vide.");
      return;
    }

    const noteFileName = newName.trim() + '.txt';
    const isDuplicate = cours.files.some((file) => file.filename === noteFileName);
    setDuplicateNoteNameError(isDuplicate ? "Une note de même nom existe déjà." : null);
  }, [cours.files]);

  const handleImportURL = useCallback(async () => {
    if (!urlInput) {
      setError("Veuillez entrer une URL.");
      return;
    }

    try {
      const response = await fetch(serverPHP + `/get_file_info.php?url=${encodeURIComponent(urlInput)}`, {
        credentials:'include'
      });
      const data = await response.json();

      if (response.ok) {
        const { contentLength, lastModified, contentType } = data;
        if (contentLength > MAX_FILE_SIZE) {
          setError('La taille du fichier dépasse la limite de 20 Mo.');
          setFileInfo(null);
          setIsValidExtension(true);
          return;
        }

        let extension = mimeTypeToExtension[contentType?.split(';')[0].trim()] || '';
        if (!extension) {
          const urlExtension = urlInput.split('.').pop().toLowerCase();
          extension = acceptedExtensions.includes(urlExtension) || conditionallyAcceptedExtensions.includes(urlExtension) ? urlExtension : 'inconnue';
        }

        const isAccepted = acceptedExtensions.includes(extension) || conditionallyAcceptedExtensions.includes(extension);
        setIsValidExtension(isAccepted);

        const formattedSize = formatFileSize(contentLength);
        let fileName = decodeURIComponent(urlInput.split('/').pop());
        if (!fileName || fileName.length > 30) {
          const domain = new URL(urlInput).hostname.replace('www.', '');
          fileName = `Import_${domain}`;
        }

        if (!fileName.endsWith(`.${extension}`)) {
          fileName += `.${extension}`;
        }

        setFileInfo({
          name: fileName,
          size: formattedSize.size,
          unit: formattedSize.unit,
          lastModifiedDate: lastModified ? new Date(lastModified).toLocaleString() : 'Inconnue',
          extension,
        });

        checkFileNameValidity(
          fileName,
          extension,
          cours.files,
          setDuplicateNameError,
          setIsValidExtension,
          wide,
          noPadding
        );
      } else {
        throw new Error(data.error || "Erreur lors de la récupération des informations du fichier.");
      }
    } catch (error) {
      console.error(error);
      setError("Impossible de récupérer les informations du fichier.");
    }
  }, [urlInput, cours.files, wide]);

  const handleAddSource = useCallback(() => {
    if (selectedOption === 'file') {
      if (!fileData) {
        setError('Veuillez sélectionner un fichier.');
        return;
      }
      if (!isValidExtension) {
        setError("L'extension du fichier n'est pas acceptée.");
        return;
      }
      if (duplicateNameError || !fileInfo.name.trim()) {
        setError(duplicateNameError || "Le nom du fichier ne peut pas être vide.");
        return;
      }
    }
    if (selectedOption === 'url') {
      if (!urlInput) {
        setError('Veuillez entrer une URL.');
        return;
      }
      if (!fileInfo) {
        setError("Cliquez sur le bouton d'importation pour récupérer les informations du fichier.");
        return;
      }
      if (!isValidExtension) {
        setError("L'extension du fichier n'est pas acceptée.");
        return;
      }
      if (duplicateNameError || !fileInfo.name.trim()) {
        setError(duplicateNameError || "Le nom du fichier ne peut pas être vide.");
        return;
      }
    }
    if (selectedOption === 'text') {
      if (!nomNote.trim() && !wide) {
        setError('Veuillez entrer un nom pour la note.');
        return;
      }
      if (!textInput.trim()) {
        setError('Veuillez coller le texte.');
        return;
      }
      if (duplicateNoteNameError) {
        setError(duplicateNoteNameError);
        return;
      }
    }

    onAddSource({
      type: selectedOption,
      data: selectedOption === 'file' ? { file: fileData, filename: fileInfo.name }
        : selectedOption === 'url' ? { url: urlInput, filename: fileInfo.name }
        : { text: textInput, filename: nomNote.trim() + '.txt' },
      nomNote,
    });

    onClose();
    resetModalState();
  }, [selectedOption, fileData, fileInfo, isValidExtension, duplicateNameError, urlInput, textInput, nomNote, onAddSource, onClose, resetModalState, duplicateNoteNameError, wide]);

  const isAddButtonDisabled = useMemo(() => {
    if (selectedOption === 'file') {
      return !fileData || !fileInfo || !isValidExtension || error || duplicateNameError || !fileInfo.name.trim();
    }
    if (selectedOption === 'url') {
      return !fileInfo || !isValidExtension || error || duplicateNameError || !fileInfo.name.trim();
    }
    if (selectedOption === 'text') {
      return !textInput.trim() || (!nomNote.trim() && !wide) || error || duplicateNoteNameError;
    }
    return true;
  }, [selectedOption, fileData, fileInfo, isValidExtension, error, duplicateNameError, textInput, nomNote, duplicateNoteNameError, wide]);

  return (
    <div className="modal-content" ref={modalRef} style={{ maxWidth: wide ? 'none' : '600px', boxShadow: wide ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)', paddingBottom : noPadding ? 0 : 'auto' }}>
      {/* Options Menu */}
      <div className="options-menu">
        <div className={`option-item ${selectedOption === 'file' ? 'selected' : ''}`} onClick={() => handleOptionClick('file')}>
          <PicsFile alt="Importer un fichier" />
          <span>Importer un fichier</span>
          {selectedOption === 'file' && <div className="option-underline"></div>}
        </div>
        <div className={`option-item ${selectedOption === 'url' ? 'selected' : ''}`} onClick={() => handleOptionClick('url')}>
          <PicsWeb alt="À partir d'une URL" />
          <span>À partir d'une URL</span>
          {selectedOption === 'url' && <div className="option-underline"></div>}
        </div>
        {showNotes &&
        <div className={`option-item ${selectedOption === 'text' ? 'selected' : ''}`} onClick={() => handleOptionClick('text')}>
          <PicsCopy alt="Coller un texte" />
          <span>Coller un texte</span>
          {selectedOption === 'text' && <div className="option-underline"></div>}
        </div>
        }
      </div>
      {/* Option Content */}
      <div className="option-content">
        {selectedOption === 'file' && (
          <div className="file-upload-section">
            {!fileData || !fileInfo ? (
              <div
                className={`file-drop-zone ${isDragOver ? 'drag-over' : ''}`}
                onClick={() => fileInputRef.current?.click()}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              >
                <div className="instruction">
                  <div className={isHover ? 'hoveredImport' : ''} style={{ marginBottom: '10px' }}>Cliquez ici</div>
                  <div>ou&nbsp;<span className={isDragOver ? 'hoveredImport' : ''}>déposez votre fichier</span></div>
                  <div style={{ position: 'absolute', fontSize: '11px', bottom: '10px', padding: '0 20px' }}>
                    Formats supportés : {`${acceptedExtensions.join(', ')}, ${conditionallyAcceptedExtensions.join(', ')}`}.
                  </div>
                </div>
              </div>
            ) : (
              <FileInfoDisplay
                fileInfo={fileInfo}
                isValidExtension={isValidExtension}
                resetFileInfo={resetModalState}
                acceptedExtensions={acceptedExtensions}
                conditionallyAcceptedExtensions={conditionallyAcceptedExtensions}
                onFileNameChange={(newName) =>
                  checkFileNameValidity(
                    newName,
                    newName.split('.').pop().toLowerCase(),
                    cours.files,
                    setDuplicateNameError,
                    setIsValidExtension,
                    wide,
                    noPadding
                  )
                }
                duplicateNameError={duplicateNameError}
                wide={wide}
              />
            )}
            <input ref={fileInputRef} id="file-input" type="file" onChange={handleFileChange} className="hidden-file-input" />
          </div>
        )}
        {selectedOption === 'url' && (
          <div className="url-input-section">
            {!fileInfo ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  placeholder="Entrez l'URL ici"
                  value={urlInput}
                  onChange={(e) => {
                    setUrlInput(e.target.value);
                    setFileInfo(null);
                    setError(null);
                  }}
                  className="url-input"
                />
                <PicsImport_URL
                  height={40}
                  width={40}
                  className={`boutonImportURL ${urlInput === '' ? 'inactif' : ''}`}
                  onClick={handleImportURL}
                  style={{ cursor: urlInput === '' ? 'not-allowed' : 'pointer' }}
                />
              </div>
            ) : (
              <FileInfoDisplay
                fileInfo={fileInfo}
                isValidExtension={isValidExtension}
                resetFileInfo={() => {
                  setFileInfo(null);
                  setUrlInput('');
                  setIsValidExtension(true);
                  setError(null);
                  setDuplicateNameError(null);
                }}
                acceptedExtensions={acceptedExtensions}
                conditionallyAcceptedExtensions={conditionallyAcceptedExtensions}
                onFileNameChange={(newName) =>
                  checkFileNameValidity(
                    newName,
                    newName.split('.').pop().toLowerCase(),
                    cours.files,
                    setDuplicateNameError,
                    setIsValidExtension,
                    wide,
                    noPadding
                  )
                }
                duplicateNameError={duplicateNameError}
                wide={wide}
              />
            )}
          </div>
        )}
        {selectedOption === 'text' && (
          <div className="text-input-section">
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {(!wide || noPadding) && (
                <div className="divNomNote">
                  <div style={{ marginRight: '20px' }}>Nom de la note :</div>
                  <div style={{ flex: 1, flexShrink: 0, display: 'flex' }}>
                    <input
                      type="text"
                      placeholder="Entrez le nom"
                      value={nomNote}
                      onChange={(e) => {
                        handleNomNoteChange(e.target.value);
                        setError(null);
                      }}
                      className={`nomNote-input ${duplicateNoteNameError ? 'input-error' : ''}`}
                    />
                  </div>
                </div>
              )}
              <div style={{ flex: 1 }}>
                <textarea
                  placeholder="Collez votre texte ici"
                  value={textInput}
                  onChange={(e) => {
                    setTextInput(e.target.value);
                    setError(null);
                  }}
                  className="pasteTextInput"
                  style={{height : (!wide || noPadding) ? '170px' : '220px'}}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {error && <p className="error">{error}</p>}
      <button
        onClick={handleAddSource}
        className={`add-source-button ${(isAddButtonDisabled && !wide) ? 'disabled-button' : ''}`}
        disabled={isAddButtonDisabled && !wide}
      >
        {wide ? noPadding ? "Importer votre cours" : "Importer le fichier" : "Ajouter aux sources"}
      </button>
    </div>
  );
};

const FileInfoDisplay = ({ fileInfo, isValidExtension, resetFileInfo, acceptedExtensions, conditionallyAcceptedExtensions, onFileNameChange, duplicateNameError, wide }) => (
  <div className={`file-info ${!isValidExtension ? 'invalid-extension' : ''}`}>
    <div className="deleteIconDiv">
      <PicsDelete_File alt="Supprimer les informations" height={100} width={100} className="deleteIconBig" onClick={resetFileInfo} />
    </div>
    <div className="container-nom-fichier">
      {!wide ? (
        <p className="nom-fichier">
          <strong>Nom du fichier :</strong>{' '}
          <input
            type="text"
            value={fileInfo.name}
            onChange={(e) => onFileNameChange(e.target.value)}
            className={`file-name-input ${duplicateNameError || !isValidExtension ? 'input-error' : ''}`}
            style={{ flex: 1 }}
          />
        </p>
      ) : (
        <p className="nom-fichier">
          <strong>Nom du fichier :</strong>{' '}
          {fileInfo.name}
        </p>
      )}
      <p>
        <strong>Taille :</strong> {fileInfo.size} {fileInfo.unit}
      </p>
      <p>
        <strong>Dernière modification :</strong> {fileInfo.lastModifiedDate}
      </p>
      {!isValidExtension && (
        <p className="extension-error">
          L'extension <strong>{fileInfo.extension}</strong> n'est pas acceptée.
          <br />
          Les formats supportés sont les suivants : {!wide ? `${acceptedExtensions.join(', ')}, ${conditionallyAcceptedExtensions.join(', ')}` : conditionallyAcceptedExtensions.join(', ')}.
        </p>
      )}
      {conditionallyAcceptedExtensions.includes(fileInfo.extension) && (
        <p className="conversion-note">
          Ce fichier sera converti en texte avant utilisation.
        </p>
      )}
      {duplicateNameError && (
        <p className="duplicate-name-error">{duplicateNameError}</p>
      )}
    </div>
  </div>
);

export default AddSourceModal;
