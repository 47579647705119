import React, { useEffect, useState, useRef } from 'react';
import './Sources.css';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';
import DropdownMenu from './DropDownMenu/index';
import { ReactComponent as Croix } from "./images/croix.svg";
import { ReactComponent as Copy } from "./images/copy.svg";
import LoadingCircle from '../composants/LoadingCircle';

import { serverPHP } from '../consts';

const Sources = ({ source, setShowSources, currentNote, setCurrentNote }) => {
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const buttonRef = useRef(null);
  const [menuStyle, setMenuStyle] = useState({});
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuStyle({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 200,
        width: rect.width + 200,
      });
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setOpenMenu(false);
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setMenuStyle({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX - 200,
          width: rect.width + 200,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateMenuPosition = () => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();
        setMenuStyle({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX - 200,
          width: rect.width + 200,
        });
      }
    };

    updateMenuPosition();

    window.addEventListener('resize', updateMenuPosition);

    return () => {
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, [openMenu, buttonRef]);

  useEffect(() => {
    if (openMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMenu]);

  useEffect(() => {
    if (source && source.thread_id && source.run_id) {
      const fetchSteps = async () => {
        setLoading(true);
        console.log(source);
        try {
          const response = await fetch(serverPHP + `/proxy_sources.php?thread_id=${source.thread_id}&run_id=${source.run_id}`, {
                credentials:'include'
              }
          );
          if (!response.ok) {
            throw new Error('Erreur dans la récupération des étapes.');
          }
          const data = await response.json();
          setSteps(data.data);
        } catch (error) {
          console.error('Erreur dans la récupération des étapes :', error);
        } finally {
          setLoading(false);
        }
      };

      fetchSteps();
    }
  }, [source]);

  const results = steps?.flatMap((step) =>
    step.step_details.tool_calls?.flatMap((toolCall) =>
      toolCall.file_search.results
    ) || []
  );

  const htmlToPlainText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const renderTextWithLineBreaks = (html) => {
    const plainText = htmlToPlainText(html);
    return plainText.split(/\n/).map((line, index) => {
      if (line.trim() === '') {
        return null; // Ne rien rendre pour les lignes vides ou contenant seulement des espaces
      }
      return <p key={index}>{line}</p>;
    });
  };
  

  const createParagraphsFromText = (html) => {
    const plainText = htmlToPlainText(html);
    const lines = plainText.split(/\n/);
  
    return lines
      .filter(line => line.trim() !== '') // Exclure les lignes vides ou contenant seulement des espaces
      .map(line => new Paragraph({
        children: [new TextRun({ text: line })],
        alignment: AlignmentType.LEFT,
        spacing: { after: 200 },
      }));
  };
  

  const exportSourcesToDocx = (sources, filename) => {
    const doc = new Document({
      sections: [
        {
          children: sources.flatMap((result, index) => [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Source ' + (index + 1),
                  bold: true,
                  size: 28,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 400,
              },
            }),
            ...result.content.flatMap((content) => [
              ...createParagraphsFromText(content.text),
              new Paragraph({
                children: [new TextRun({
                  text: result.file_name.slice(0, -4).replace(/_/g, ' '),
                  italics: true,
                })],
                alignment: AlignmentType.RIGHT,
                spacing: { after: 200 },
              })
            ]),
          ]),
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${filename}.docx`);
    });
  };

  const exportSource = () => {
    if (!results[currentNote]) return;
    exportSourcesToDocx([results[currentNote]], `source ${currentNote + 1}`);
  };

  const exportAllSources = () => {
    exportSourcesToDocx(results, 'toutes les sources');
  };

  /*if (loading) {
    return (
      <LoadingCircle taille={100} />
    );
  }*/

  return (
    <>
      <div className="titreEtCroix" style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span data-icon="x" className="fermerCroix" onClick={() => setShowSources(false)} style={{ display: 'flex' }}>
              <Croix height={24} width={24} />
            </span>

            <div className="titre">Sources de la réponse</div>
          </div>
          <div>
            {(results?.length > 0 && !loading) && (
              <Copy
                style={{ height: '24px', width: '24px', cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMenu(!openMenu);
                }}
                ref={buttonRef}
              />
            )}
          </div>
        </div>
      </div>
      <div className="allInfos boxInfos" style={{ alignItems: 'flex-start', backgroundColor: '#f4f4f4', boxShadow: 'none' }}></div>
      <div>
        {(loading) ?
        <LoadingCircle taille={100} />
        : <>
        {results?.length === 0 ? (
          <div className="no-sources-message">
            <div style={{ color: 'black' }}>Aucune source disponible pour cette réponse.</div>
            <div style={{ fontSize: '14px', marginTop: '10px' }}><span style={{ textDecoration: 'underline' }}>Attention :</span> dans la mesure où ce message a été généré sans appui textuel, il est plus probable qu'il n'exprime pas fidèlement la pensée de l'auteur.</div>
          </div>
        ) : (
          <>
            <div className="buttons-container">
              {results?.map((_, index) => (
                <button
                  key={index}
                  className={`source-button ${Number(currentNote) === index ? 'active' : ''}`}
                  onClick={() => setCurrentNote(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            {results && results[currentNote] && (
              <div className="source-content">
                {results[currentNote].content?.map((content, contentIndex) => (
                  <div key={contentIndex} className="source">
                    <div className="text-content">
                      {renderTextWithLineBreaks(content.text)}
                    </div>
                    <div
                      style={{ textAlign: 'right', fontStyle: 'italic', userSelect: 'text' }}
                    >
                      {results[currentNote].file_name.slice(0, -4).replace(/_/g, ' ')}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        </>
}
      </div>
      <DropdownMenu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        buttonRef={buttonRef}
        menuItems={[
          { label: 'Exporter la source', onClick: exportSource },
          { label: 'Exporter toutes les sources', onClick: exportAllSources },
        ]}
        openDirection="left"
      />
    </>
  );
};

export default Sources;
