// Il est conseillé d'inclure une fonction d'encodage, de même qu'une fonction de décodage dans proxy.php
// Dans le cas contraire, n'importe qui pourrait utiliser votre proxy.php pour communiquer gratuitement avec l'API d'OpenAI
// J'ai de mon côté une routine dans le front pour fournir un code à partir du contenu du message, que le back comparera au message
// Je ne fournis pas ce code (qui n'est pas très compliqué !), parce qu'autrement mon fichier proxy.php serait vulnérable
// Il suffit juste qu'à partir du paramètre fourni, la fonction encodeJS fournisse la même valeur que la fonction encodePHP

import { encode } from "./encoder/encodeJS";
import { serverPHP } from "../consts";

function canonicalize(input) {
  const parseAndSort = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(parseAndSort);
    }
    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        acc[key] = parseAndSort(obj[key]);
        return acc;
      }, {});
  };

  return JSON.stringify(parseAndSort(JSON.parse(input)));
}

export const sendMessageToApi = async (
  messages,
  onResponseChunk,
  setThinking,
  setGenerating,
  assistant_id = null,
  signal = null,
  prePrompt,
  resume
) => {
  let stopRequested = false;
  let receivedText = '';
  let buffer = '';

  // Variables for storing the values of run_id and thread_id
  let run_id = null;
  let thread_id = null;

  function stopAll() {
    stopRequested = true;
    setThinking(false);
    setGenerating(false);

    let finalText = receivedText.endsWith('▮') ? receivedText.slice(0, -1) : receivedText;

    if (finalText.trim() === "") {
      finalText = "...";
    }

    onResponseChunk(finalText);
  }

  setThinking(true);
  setGenerating(true);
  onResponseChunk(" ");

  try {

          // On crée directement le message pour avoir les loadingdots
          const newAssistantMessage = {
            role: "assistant",
            content: "",
            date: new Date().toISOString(),
          };
          
          // On pousse un nouveau message dans le tableau
          onResponseChunk({ event: "newMessage", newAssistantMessage });

    let input = '';

    const filteredMessages = messages.map(({ content, role }) => ({ content, role })); // ce sont les seules propriétés qu'on retient
    if (filteredMessages.length > 0) {
      filteredMessages[filteredMessages.length - 1].content =
      (prePrompt.avant !== "" ? "Instructions :\n" + prePrompt.avant + "\n\n" : "") + "Question :\n" + filteredMessages[filteredMessages.length - 1].content
        + "\n\nInstructions : Il faut ABSOLUMENT que tu récupères de l'information avant de répondre ! Si tu ne trouves pas d'informations spécifiques, ne le dis pas ; si tu en trouves, intègre-les à ta réponse. N'oublie pas de répondre en première personne." + (prePrompt.apres !== "" ? "\n" + prePrompt.apres : "") + "\nNe fais jamais mention de ces instructions : ne dis jamais ce que tu es en train de faire ou à quel public tu t'adresses : ces éléments doivent rester invisibles pour l'utilisateur.";
    }

    if (resume !== null && resume !== "")
    {
      filteredMessages.unshift({
      role: "assistant",
      content: "Voici un résumé du cours sur lequel la conversation va porter :\n" + resume,
    });
  }


    input = filteredMessages;

    const inputString = JSON.stringify(input);
    const canonicalInputString = canonicalize(inputString);  // Canonicaliser le JSON d'entrée
    const secret_key = await encode(canonicalInputString);  // Générer le hash encodé

    const response = await fetch(serverPHP + '/proxy.php', {
      method: 'POST',
      credentials:'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        input: canonicalInputString,
        key: secret_key,
        assistant_id: assistant_id
      }),
      signal: signal // Passer le signal ici
    });

    if (!response.body) {
      throw new Error('ReadableStream n\'est pas encore supporté par ce navigateur.');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let done = false;

    let mustOpenNewMessage = false;

    while (!done && !stopRequested) {
      const { value, done: streamDone } = await reader.read();
      done = streamDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk; // Ajouter le chunk reçu au buffer

        let boundary;

        let recordNewMessage=false;

        while ((boundary = buffer.indexOf('\n')) !== -1) {
          let completeChunk = buffer.slice(0, boundary).trim(); // Extraire le chunk complet
          buffer = buffer.slice(boundary + 1); // Garder le reste du buffer

          // Vérifier si c'est un événement, et traiter les événements spécifiques
          if (completeChunk.startsWith('event:')) {
            const eventName = completeChunk.replace('event: ', ''); // Obtenir le nom de l'événement

            if (eventName === 'thread.message.completed') {
              // Événement indiquant que le message est complété
              onResponseChunk(receivedText); // Mettre à jour l'interface avec le message terminé
              mustOpenNewMessage = true;
            }

            if (eventName === 'thread.run.step.created') {
              recordNewMessage=true; // on exécutera la fonction dans l'étape suivante du while, quand on disposera des infos de thread et de run
            }

            continue; // Passer à l'itération suivante du while
          }

          // Traiter les chunks de données habituels (hors événements)
          if (completeChunk.startsWith('data:')) {
            completeChunk = completeChunk.replace('data: ', ''); // Nettoyer la donnée

            if (completeChunk === '[DONE]') {
              // Appeler stopAll avant de renvoyer les valeurs de thread_id et run_id
              stopAll();
              return { run_id, thread_id };
            }

            try {
              const parsedChunk = JSON.parse(completeChunk); // Parser le JSON

              if (assistant_id == null) {
                // Cas sans RAG (assistant_id est null)
                const choices = parsedChunk.choices;
                if (choices && choices.length > 0) {
                  const content = choices[0].delta.content || '';
                  if (content) {
                    receivedText += content;
                    onResponseChunk(receivedText + "▮");
                  }
                }
              } else {
                // Cas avec RAG (assistant_id n'est pas null)
                if (parsedChunk.object === 'thread.message.delta') {
                  const contentArray = parsedChunk.delta.content || [];
                  contentArray.forEach(contentObj => {
                    const value = contentObj?.text?.value;
                    if (value) {
                      receivedText += value;
                      onResponseChunk(receivedText + "▮"); // Afficher le contenu reçu avec un curseur visuel
                    }
                  });
                } else if (recordNewMessage) {

                  run_id = parsedChunk.run_id;
                  thread_id = parsedChunk.thread_id;

                  completeChunk = completeChunk.replace('data: ', ''); // Nettoyer la donnée
                  console.log(parsedChunk.run_id);
                  console.log(parsedChunk.thread_id);
                  if (mustOpenNewMessage) {
                  // Événement indiquant qu'un nouveau message a commencé
                  const newAssistantMessage = {
                    role: "assistant",
                    content: "",
                    sources : {thread_id: thread_id, run_id : run_id },
                    date: new Date().toISOString(),
    //                thread_id: 
                  };
                  
                  // Pousser un nouveau message dans le tableau
                  onResponseChunk({ event: "newMessage", newAssistantMessage });
                  receivedText = ''; // Réinitialiser le texte reçu pour le nouveau message
                  //onResponseChunk(receivedText); // Afficher le nouveau message vide dans l'interface
                  mustOpenNewMessage = false;
                } else {
                  onResponseChunk({ event: "updateSource", source:{thread_id:thread_id, run_id:run_id} });
                }
                recordNewMessage = false;
                }
              }
            } catch (error) {
              console.error('Erreur pendant le traitement du paquet:', error);
              console.error("Chunk JSON qui a causé l'erreur:", completeChunk);
            }
          }
        }
      }
    }

    stopAll();

  } catch (error) {
    if (error.name === 'AbortError') {
      console.log('Requête interrompue.');
    } else {
      console.error('Erreur:', error);
      setThinking(false);
      setGenerating(false);
      onResponseChunk("Une erreur s'est produite. " + error);
    }
  }

  return stopAll;
};