// MessageList.js

import React, { useEffect, useRef, useCallback, useState } from 'react';
import Message from './Message';

function MessageList({
  messages, // l'ensemble des messages du philosophe à afficher
  thinking, // est-ce qu'on est en train de réfléchir ? (= initialisation + générationZ)
  setFirstAssistantTop,
  reponsesEnCours, // est-ce qu'on a cliqué sur le bouton d'envoi et qu'une génération est en cours ?
  setShowSources,
  currentSources,
  setCurrentSources,
  setCurrentNote,
  setShowModules,
  isProf
}) {

  const refs = useRef([]); // Crée un tableau de refs pour chaque message-container
  const messageRefs = useRef([]); // Crée un tableau de refs pour chaque div "message"

  const [currentlySpeakingIndex, setCurrentlySpeakingIndex] = useState(null); // State pour suivre le message en cours de synthèse

  // Fonction pour trouver le bon message assistant
  const findRelevantAssistantMessage = useCallback(() => {
    for (let i = messages.length - 1; i >= 0; i--) {
      const msg = messages[i];
      if (msg.role !== 'assistant') {
        if (i + 1 < messages.length && messageRefs.current[i + 1]) {
          return messageRefs.current[i + 1]; // Retourne la ref du premier message assistant après ce message
        }
        break;
      }
    }
    return refs.current[0] || null;
  }, [messages]);

  // Fonction pour récupérer la position du message assistant trouvé et inclure le margin-top de "message"
  const getFirstAssistantTop = useCallback(() => {
    const relevantMessageRef = findRelevantAssistantMessage();
    if (relevantMessageRef) {
      const messageOffsetTop = relevantMessageRef.offsetTop; // Utilise offsetTop pour récupérer la position relative au conteneur parent
      //console.log("getFirstAssistantTop");
      setFirstAssistantTop(messageOffsetTop); // Met à jour la distance avec la marge incluse
    }
  }, [findRelevantAssistantMessage, setFirstAssistantTop]);

  useEffect(() => {
    if (reponsesEnCours) {
      getFirstAssistantTop(); // Utiliser useLayoutEffect garantit que le DOM est prêt
    }
  }, [reponsesEnCours, getFirstAssistantTop]);

  return (
    <>
      {Array.isArray(messages)
        ? messages.map((msg, index) => (
            <div
              key={index}
              ref={(el) => (refs.current[index] = el)} // Assigne chaque message-container à une ref
              className="message-container"
            >
              <Message
                ref={(el) => (messageRefs.current[index] = el)} // Assigne chaque "message" à une ref
                msg={msg}
                thinking={thinking}
                setShowSources={setShowSources}
                currentSources={currentSources}
                setCurrentSources={setCurrentSources}
                setCurrentNote={setCurrentNote}
                setShowModules={setShowModules}
                isCurrentlySpeaking={currentlySpeakingIndex === index} // Indique si ce message est en cours de synthèse
                setCurrentlySpeakingIndex={setCurrentlySpeakingIndex} // Fonction pour mettre à jour l'état centralisé
                index={index} // Index du message
                isProf={isProf}
              />
            </div>
          ))
        : null}
    </>
  );
}

export default MessageList;
