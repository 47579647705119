import { useState, useEffect } from 'react';
import { ReactComponent as PicsImagefile } from "../../pics/imagefile.svg";
import './Modules.css';
import Module_selectFile from './Module_selectFile';
import { ReactComponent as PicsDelete } from "../../pics/delete.svg";
import { serverPHP } from '../../consts/index.js';
import ModuleMindmap_parametres from './ModuleMindmap_parametres.js';
import ModuleMindmap_ShowMindmap from './ModuleMindmap_ShowMindmap.js';

const ModuleMindmap = ({ cours, setCours, setTitreMenu, reset, setReset }) => {
    const [contenuFichier, setContenuFichier] = useState(null);
    const [fichierFromSources, setFichierFromSources] = useState(null);
    const [currentMindmap, setCurrentMindmap] = useState(null);

    useEffect(() => {
        if (reset) {
            setContenuFichier(null);
            setReset(false);
            setFichierFromSources(null);
        }
    }, [reset]);

    const handleDelete = async (file) => {
        // Update the state to remove the selected file
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                cartementale: prevCours.modules.cartementale.filter((item) => item.mindmap_id !== file.mindmap_id)
            }
        }));

        // Send the delete request to the server
        try {
            await fetch(`${serverPHP}/db/moduleMindmap_delCarte.php`, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    assistant_id: cours.id,
                    cartementale_id: file.mindmap_id
                })
            });
        } catch (error) {
            console.error('Error deleting mindmap:', error);
        }
    };

    // Fonction pour supprimer toutes les cartes mentales
    const handleDeleteAll = async () => {
        setCours((prevCours) => ({
            ...prevCours,
            modules: {
                ...prevCours.modules,
                cartementale: []
            }
        }));

        try {
            await fetch(`${serverPHP}/db/moduleMindmap_delAllCartes.php`, {
                method: 'POST',
                credentials:'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    assistant_id: cours.id
                })
            });

        } catch (error) {
            console.error('Error deleting all mindmaps:', error);
        }
    };
      
      // In the component, add a button that triggers this function
      <button onClick={handleDeleteAll} className="delete-all-button">
        Tout supprimer
      </button>
      

    return (
        <div className="moduleMain">
            {contenuFichier === null ?
                <>
                    <Module_selectFile
                        cours={cours}
                        reset={reset}
                        setReset={setReset}
                        setContenuFichier={setContenuFichier}
                        setTitreMenu={setTitreMenu}
                        navigationSelectFile={fichierFromSources}
                        setNavigationSelectFile={setFichierFromSources}
                        titreSelection={<><strong>Générer</strong> des cartes mentales</>}
                    />
                    {(fichierFromSources === null) &&
                        <div className="moduleContainer">
                            <div className="moduleTitre">Cartes mentales <strong>ajoutées</strong></div>
                            <div className="signup-choice" style={{ margin: '0 0 20px 0', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div className="containerFichiersPartage">
                                        {cours.modules.cartementale.map((file, index) => (
                                            <div
                                                key={index}
                                                className="partageItem partageHover"
                                                style={{ justifyContent: "space-between", cursor: 'pointer' }}
                                                onClick={() => setCurrentMindmap(file)}
                                            >
                                                <div>
                                                    <div className="actionButton">
                                                        <PicsImagefile />
                                                    </div>
                                                    <span>{file.nomFichier}</span>
                                                </div>
                                                <div
                                                    className="deleteButtonSVG"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(file);
                                                    }}
                                                >
                                                    <PicsDelete />
                                                </div>
                                            </div>
                                        ))}

                                        { cours.modules.cartementale.length === 0 &&
                                            <div style={{width: '100%'}}><div className="containerFichiersPartage"></div>
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>
                                                Vous n'avez encore généré aucune carte mentale.</p></div>
                                        }
                                    </div>
                                </div>
                                {cours.modules.cartementale.length > 0 &&
                                    <button
                                        className="login-button"
                                        style={{ width: '200px', margin: 'auto', marginTop: '-20px' }}
                                        onClick={handleDeleteAll}
                                    >
                                        Tout supprimer
                                    </button>
                                }
                            </div>
                        </div>
                    }
                    {currentMindmap &&
    <ModuleMindmap_ShowMindmap
        currentMindmap={currentMindmap}
        setCurrentMindmap={setCurrentMindmap}
        assistant_id={cours.id}
        mindmap_id={currentMindmap.mindmap_id}
        updateSource={(newContent) => {
            // Update the content of currentMindmap
            setCurrentMindmap(prevMindmap => ({
                ...prevMindmap,
                content: newContent
            }));

            // Update the content in cours.modules.cartementale
            setCours(prevCours => {
                const updatedCartementale = prevCours.modules.cartementale.map(mindmap => {
                    if (mindmap.mindmap_id === currentMindmap.mindmap_id) {
                        return {
                            ...mindmap,
                            content: newContent
                        };
                    } else {
                        return mindmap;
                    }
                });

                return {
                    ...prevCours,
                    modules: {
                        ...prevCours.modules,
                        cartementale: updatedCartementale
                    }
                };
            });
        }}
    />
}
                </>
                :
                <ModuleMindmap_parametres
                    contenuFichier={contenuFichier}
                    setContenuFichier={setContenuFichier}
                    setTitreMenu={setTitreMenu}
                    cours={cours}
                    setCours={setCours}
                    setCurrentMindmap={setCurrentMindmap}
                    setFichierFromSources={setFichierFromSources}
                />
            }
        </div>
    );
};

export default ModuleMindmap;
